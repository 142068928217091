//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 
import * as SortFilterType from '../actiontypes/SortFilter'


const initialState = {};

const filterStore = (state = initialState, action) => {
   switch (action.type) {
       case SortFilterType.SORT_BY_ALPHABET:
           //sort alphabetically
           return state;
       case SortFilterType.SORT_BY_ENERGY:
           //sort by price
           return state;
       case SortFilterType.SORT_BY_REVENUE:
           //filter by price
           return state;
       case SortFilterType.SORT_BY_SESSIONS:
           //load data
           return state;
        case SortFilterType.SORT_BY_DURATION:
            //load data
            return state;
        case SortFilterType.FILTER_BY_VALUE:
            //clone the state
            let newState = Object.assign({}, state);
            //the value passed from our component
            let value = action.payload.value;
            let filteredValues = state.products.filter(product => {
                //return any product whose name or designer contains the input box string
                return product.name.toLowerCase().includes(value) ||
                        product.designer.toLowerCase().includes(value)
            });
            let appliedFilters = state.appliedFilters;
            //if the value from the input box is not empty
            if(value) {
                //check if the filter already exists in the tracking array
                let index = appliedFilters.indexOf(SortFilterType.FILTER_BY_VALUE);
                if (index===-1)
                //if it doesn't exist add it
                appliedFilters.push(SortFilterType.FILTER_BY_VALUE);
                //change the filterd product to reflect the change
                newState.filteredProducts = filteredValues;
            } else {
                //if the value is empty, we can assume everything has been erase
                let index = appliedFilters.indexOf(SortFilterType.FILTER_BY_VALUE);
                //remove current filter
                appliedFilters.splice(index, 1);
                if(appliedFilters.length === 0) {
                    //reset the products to normal
                    newState.filteredProducts = newState.products
                }
            }
            return newState;
        default:
           return state;
   }
};

export function FilterReducer(state={
    analyticsDisplayIdList: [], // Empty array indicates display all, array elements indicate list of ids to be included 
     listItemDisplayIdList: [],
     filterTypeId: 0,
     dashboardFilterIdList: [],
     dashboardFilterTypeId: 0,
     dashboardTagSelectValue:"",
     dashboardLocationSelectValue:""   
  }, action){
    // eslint-disable-next-line
    if(action.type != undefined) {
      switch(action.type){
         case SortFilterType.LOAD_FILTER_LIST_ITEMS:  // Selecting which displayed list items should be in view 
        {
           return Object.assign({},
              state,
              {
                listItemDisplayIdList: action.ids,
                analyticsDisplayIdList: [],  // when setting new filter should clear analytics filter
                filterTypeId: 0
              }          
          );
        } 
        case SortFilterType.LOAD_FILTER_LIST_ITEMS_WITH_TYPE:  // Rename after demo to LOAD_DASHBOARD_FILTER 
        {
          // filter type:  1 =  Locations, 2 = Location Tag, 3 = Station Tag, 4= All Locations 
          let isTagTypeValue = false;
          if(action.filterTypeId == 2 || action.filterTypeId == 3) // Location type filter
          {
            isTagTypeValue = true;
          }

           return Object.assign({},
              state,
              {
                dashboardFilterIdList: action.ids,
                dashboardFilterTypeId: action.filterTypeId,
                dashboardTagSelectValue:  isTagTypeValue ? action.ids[0] : "",
                dashboardLocationSelectValue: isTagTypeValue ? "": action.ids[0] 
              }          
          );
        } 
        case SortFilterType.CLEAR_DASHBOARD_FILTERS:
          return Object.assign({},
            state,
            {
              dashboardFilterIdList: [],
              dashboardFilterTypeId: action.filterTypeId  
            });  
        case SortFilterType.TOGGLE_ANALYTICS_ITEM:
          let index = state.analyticsDisplayIdList.indexOf(action.id);
          return Object.assign({},
              state,
              {
                analyticsDisplayIdList: index>=0 ? [
                  ...state.analyticsDisplayIdList.slice(0, index),
                  ...state.analyticsDisplayIdList.slice(index + 1)
                  ]  : state.analyticsDisplayIdList.concat(action.id),
                  filterTypeId: action.filterTypeId
              }          
          );
        case SortFilterType.CLEAR_ALL_FILTERS:  // TODO:  Rename to clear all list filters or similar
          return Object.assign({},
            state,
            {
                analyticsDisplayIdList: [] ,
                listItemDisplayIdList: [],
                filterTypeId:0 
            });                  
        default:
            return state;
      }
    }
  }
export default filterStore;