export const UPDATE_COMPANY_ANALYTICS = 'UPDATE_COMPANY_ANALYTICS';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_ACCT_INFO = "GET_ACCT_INFO";
export const UPDATE_ACCT_INFO = "UPDATE_ACCT_INFO";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_ASSIGNED_LOCATION_USER_LIST = "GET_ASSIGNED_LOCATION_USER_LIST";
export const GET_ASSIGNED_LOCATION_DRIVER_LIST = "GET_ASSIGNED_LOCATION_DRIVER_LIST";
export const ASSIGNED_USER_LIST = "ASSIGNED_USER_LIST";
export const DELETE_ASSIGNED_USER_LIST = "DELETE_ASSIGNED_USER_LIST";
export const USER_LIST = "USER_LIST";