//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import groupBy from 'lodash/groupBy';
import moment from 'moment';

import * as DriverTypes from "../actiontypes/Drivers";

export function DriversAnalyticsReducer(state = {
  driversTotals: 0, /* analytics: [], */ driverDetails: [], lastUpdatedAnalytics: null, driverHomeChargerProps: {}, driversList: [], lastUpdatedDrivers : null,
}, action) {
  if (action.type != undefined) {
    switch (action.type) {
      case DriverTypes.UPDATE_DRIVERS_TOTALS:
        return Object.assign({}, state, {
          driversTotals: action.driverTotals
        });
      case DriverTypes.UPDATE_DRIVERS_ANALYTICS:
        if (action.driverAnalytics && action.driverAnalytics.length > 0) 
        {
          if(action.periodType === "day")
          {
            //Update date to locale date value before storing.
            for(var i = 0; i < action.driverAnalytics.length; ++i)
            {
              action.driverAnalytics[i].date = moment.utc(action.driverAnalytics[i].date).local().format("YYYY-MM-DD HH:mm");
            }
          }
          else if (action.periodType !== "day") 
          {
            //Update date to locale date value before storing.
            for (var i = 0; i < action.driverAnalytics.length; ++i) {
              action.driverAnalytics[i].date = moment.utc(action.driverAnalytics[i].date).local().format("YYYY-MM-DD");
            }
          }

          let groupedStat = groupBy(action.driverAnalytics, x => x.userId);
          var updatedDrivers = [];
          if(state.driverDetails.length == 0 )
          {
            for (var value in groupedStat) 
            {
              updatedDrivers.push({ "ID": value, "analytics": groupedStat[value] });
            }

            return Object.assign({}, state, {
              lastUpdatedAnalytics: Date.now(),
              driverDetails: updatedDrivers
            });
          }
          else
          {                
              for (var value in groupedStat) 
              {
                  let index = state.driverDetails.findIndex((stat) => { return value == stat.ID; }) 
                  if (index >= 0)
                  {
                    updatedDrivers.push(Object.assign({}, state.driverDetails[index], 
                      { "ID": value, "analytics": groupedStat[value]}
                    ));
                  }
                  else 
                  {
                    updatedDrivers.push({ "ID": value, "analytics": groupedStat[value]});
                  }
              }
                         
            return Object.assign({}, state, {
              lastUpdatedAnalytics: Date.now(),
              driverDetails: updatedDrivers
            });
          }
        }
        else
        {
          return Object.assign({}, state, {
            lastUpdatedAnalytics: Date.now(),
            driverDetails: []
          });
        }
        
      case DriverTypes.UPDATE_DRIVERS_DETAILS:
        if (action.data) {
          var updatedDrivers = [];
          if (state.driverDetails.length > 0) {
            for (var i = 0; i < state.driverDetails.length; ++i) {
              if (state.driverDetails[i].ID == action.data.driver) {
                updatedDrivers.push(Object.assign({}, state.driverDetails[i], {
                  "hoursSinceLastCharge": action.data.details.hoursSinceLastCharge,
                  "address1": action.data.details.address1,
                  "address2": action.data.details.address2,
                  "customerenrollmentdate": action.data.details.customerenrollmentdate,
                  "email": action.data.details.email,
                  "phone": action.data.details.phone,
                  "userId": action.data.details.userId,
                  "companyName": action.data.details.companyName,
                  "balance": action.data.details.balance
                }));
              }
              else {
                updatedDrivers.push(Object.assign({}, state.driverDetails[i]));
              }
            }
          }
          else
          {
            updatedDrivers.push({ "ID": action.data.driver,   
                                "hoursSinceLastCharge": action.data.details.hoursSinceLastCharge,   
                                "address1": action.data.details.address1,
                                "address2": action.data.details.address2,                          
                                "customerenrollmentdate": action.data.details.customerenrollmentdate,
                                "email": action.data.details.email,
                                "phone": action.data.details.phone,
                                "userId": action.data.details.userId,
                                "companyName": action.data.details.companyName,
                                "balance": action.data.details.balance
                              });
          }

          return Object.assign({}, state, {
            driverDetails: updatedDrivers
          });

        }
        else
          return state;
        
      case DriverTypes.GET_DRIVERS_HOME_CHARGER_PROPS:
        if (action.driverProperties) 
        {
          return Object.assign({}, state, {
            driverHomeChargerProps: action.driverProperties
          });
        }
        else
          return state;
        
      case DriverTypes.GET_DRIVERS_LIST:
        if(action.driversList)
        {
          return Object.assign({}, state, {
            driversList: action.driversList, 
            lastUpdatedDrivers: Date.now()
          });
        }
        else 
        {
          return Object.assign({}, state, {
            driversList: [], 
            lastUpdatedDrivers: Date.now()
          });
        }
        
        case DriverTypes.ADD_DRIVER:
          if(action.userInfo)
          {
            return Object.assign({}, state, {
                driversList:  state.driversList.concat(action.userInfo)
            });
          }
          else 
            return state;
          
        case DriverTypes.UPDATE_DRIVER:
          if(action.userInfo)
          {
            var index = state.driversList.findIndex((user) => { return user.id == action.userInfo.id; });
            if (index >= 0)
            {
                return {...state, driversList: [
                ...state.driversList.slice(0, index),
                {
                    ...state.driversList[index],
                    ...action.userInfo
                },
                ...state.driversList.slice(index + 1)
                ]};
            }
            else
                return state;
          }
            else 
              return state;
            
        case DriverTypes.GET_OPEN_TOKENS_LIST:
          if(action.openTokenList)
          {
             return Object.assign({}, state, {
            openTokenList: action.openTokenList
          });
          }
        else 
          return state;
        
        case DriverTypes.DELETE_OPEN_TOKEN:
        if(action.delete_open_token)
        {
          const newState = Object.assign([], state);
          var index = state.openTokenList.findIndex((token) => { return token.ID == action.delete_open_token; });
            if (index >= 0)
            {              
                return {...state,
                openTokenList: [
                  ...state.openTokenList.slice(0,index),
                  ...state.openTokenList.slice(index+1)
                ]
                };
            }
            else
                return state;
        }
        else
            return state;
        
      default: {
        return state;
      }
    }
  }
}