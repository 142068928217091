//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import axios from "axios";

export const FetchLocations = (siteId, authToken, dispatch) => {
  axios({
    method: "get",
    url: "Companies.svc/companies/" + siteId + "/locationsMinStations",
    headers: {
      Authorization: authToken,
    },
  }).then((response) => {
    dispatch(response.data.Collection);
  });
};

export const FetchLocationAnalytics = (siteId, authToken, start, end, dispatch) => {
  axios({
      method:'get',
      url:  "Companies.svc/companies/" + siteId + "/locations/analytics?periodType=" + "day" + "&start=" + start + "&end=" + end,
      headers: {
        'Authorization': authToken
      }
  })
  .then(response => {

    dispatch(response.data);
  })
}

export const FetchLocationDetails = (authToken, locationId, start, end, dispatch) => {
  axios({
      method:'get',
      url:  "Companies.svc/locations/" + locationId + "/details?start=" + start + "&end=" + end,
      headers: {
        'Authorization': authToken
      }
  })
  .then(response => {
    dispatch({details: response.data, location: locationId });
  })
}

export const FetchLocationChargingSchedules = (authToken, siteId, locationId, dispatch) =>
{
  axios({
          method:'get',
          url:  "Companies.svc/companies/"+siteId+"/chargingschedule/?locId="+ locationId,
          headers: {
            'Authorization': authToken
          }
      })
      .then((response) => {     
        if(response.data)
        {
          dispatch(response.data);
        }
   })
}





