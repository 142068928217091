//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";


let countdownInterval;
let timeout;

const SessionTimeout = ({isAuthenticated}) => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
      clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        try {
          clearInterval(countdownInterval);
      } catch (e) {
          console.error(e);
      }
    };

    const clearSession = () => {
      clearSessionInterval();
      clearSessionTimeout();
    }

    const handleLogout = async (isTimedOut = false) => {
      try {
        setTimeoutModalOpen(false);
        clearSession();
        // localStorage.clear();
        sessionStorage.clear();
        return  window.location.href = '/';
      } catch (e) {
        console.error(e);
      }
    };

    const handleContinue = () => {
      setTimeoutModalOpen(false);
      clearSession();
    };

    const onActive = () => {
      if (!timeoutModalOpen) {
        clearSessionInterval();
        clearSessionTimeout();
      }
  };


  //On idle the the dialog modal will appear and the "countDown" time of 30 seconds will start counting down with onIdle function
  const onIdle = () => {
    const delay = 1000 * 1;
      if (isAuthenticated && !timeoutModalOpen) {
        timeout = setTimeout(() => {
          let countDown = 30;
          setTimeoutModalOpen(true);
          setTimeoutCountdown(countDown);
          countdownInterval = setInterval(() => {
            if (countDown > 0) {
                setTimeoutCountdown(--countDown);
            } else {
              handleLogout(true);
            }
          }, 1000);
        }, delay);
      }
    };

  //Set the timeout in minutes
  const timeOutInMinutes = 60;

  return (
    <>
    <IdleTimer
      ref={idleTimer}
      onActive={onActive}
      onIdle={onIdle}
      //Timeout set the time interval before warning appears in seconds
      timeout={1000*60*timeOutInMinutes}
    />

    <SessionTimeoutDialog
      countdown={timeoutCountdown}
      onContinue={handleContinue}
      onLogout={() => handleLogout(false)}
      open={timeoutModalOpen}
    />
    </>
  );
}
export default SessionTimeout;