//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

const isOpConnectValidRole = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "TechnicalSupport_OpConnect")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isOpConnectAdminTechEnterpriseValidRole = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "TechnicalSupport_OpConnect" ||
        userRole === "TechnicalSupport_Enterprise" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isOpConnectAndEnterPriseValidRole = (userRole) => {
    if (
      (userRole &&
        (userRole === "Administrator_OpConnect" ||
          userRole === "Management_Opconnect" ||
          userRole === "TechnicalSupport_OpConnect")) ||
      userRole === "User_Enterprise" ||
      userRole === "TechnicalSupport_Enterprise" ||
      userRole === "Management_Enterprise" ||
      userRole === "Administrator_Enterprise"
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isAdminMgr = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isAdministrator = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isManager = (userRole) => {
    if (
      userRole &&
      (userRole === "Management_Opconnect" ||
        userRole === "Management_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isEnterPriseAndOpconnectNonTechnicalRole = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "Administrator_Enterprise" ||
        userRole === "Management_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isAdminTechRole = (userRole) => {
    if (
      (userRole &&
        (userRole === "Administrator_OpConnect" ||
          userRole === "TechnicalSupport_OpConnect")) ||
      userRole === "TechnicalSupport_Enterprise" ||
      userRole === "Administrator_Enterprise"
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isOpConnectEnterpriseRemoteStartApprovedRole = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "TechnicalSupport_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "TechnicalSupport_Enterprise" ||
        userRole === "Administrator_Enterprise" ||
        userRole === "SupportCallOperator")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isOpConnectEnterpriseDriverDiscountEditRole = (userRole) => {
    if (
      userRole &&
      (userRole === "SiteOwner" ||
        userRole === "User_Enterprise" ||
        userRole === "Administrator_OpConnect" ||
        userRole === "TechnicalSupport_OpConnect" ||
        userRole === "TechnicalSupport_Enterprise" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isOpConnectEnterpriseOpTechRole = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "TechnicalSupport_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "Management_Enterprise" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSupportCallOperator = (userRole) => {
    if (
      userRole &&
      (userRole === "SupportCallOperator" )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isAddEditLocUserApprovedRole = (userRole) => {
    if (
      userRole &&
      (userRole === "TechnicalSupport_Enterprise" ||
      userRole === "Management_Enterprise" ||
      userRole === "Administrator_Enterprise" )
    ) {
      return true;
    } else {
      return false;
    }
  }


  const isMFARequiredUser = (userRole) => {
    if (
      userRole &&
      (userRole === "Administrator_OpConnect" ||
        userRole === "TechnicalSupport_OpConnect" ||
        userRole === "Management_Opconnect" ||
        userRole === "Management_Enterprise" ||
        userRole === "SiteOwner" ||
        userRole === "SiteOwnerUser" ||
        userRole === "User_Enterprise" ||
        userRole === "TechnicalSupport_Enterprise" ||
        userRole === "Administrator_Enterprise")
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  // UserRoleID	UserRole	Description
  
  // 6	SiteOwner	Describes an owner
  
  // 8	SiteOwnerUser	Describes an owner with restrictions
  // 9	User_Enterprise	Enterprise level user role
  // 10	TechnicalSupport_Enterprise	Enterprise level user role
  // 11	Management_Enterprise	Enterprise level user role
  // 12	Administrator_Enterprise	Enterprise level user role
  // 13	TechnicalSupport_OpConnect	OpConnect user role
  // 14	Management_Opconnect	OpConnect user role
  // 15	Administrator_OpConnect	OpConnect user role
  // 16 SupportCallOperator  External Support Call Operator
  
  const userRoleForDisplay = (userRoleId) => {
    switch (userRoleId) {
      case 3: {
        return "Driver";
      }
      case 6: {
        return "Site Owner";
      }
      case 8: {
        return "Site Owner";
      }
      case 9: {
        return "User";
      }
      case 10: {
        return "Tech Support";
      }
      case 11: {
        return "Management";
      }
      case 12: {
        return "Administrator";
      }
      case 13: {
        return "Tech Support";
      }
      case 14: {
        return "Management";
      }
      case 15: {
        return "Administrator";
      }
      case 16: {
        return "SupportCallOperator"
      }
      default: {
        return "Unknown";
      }
    }
  };

  // Limited access to specific customer and user role for first release.
  const isHomeChargerAccessAllowed = (userRole, siteId) => {
    if (userRole && userRole === "Administrator_Enterprise" && 
    (siteId === 90 || siteId === 181))
    {
      return true;
    } else {
      return false;
    }
  };
  
  module.exports = {
    isOpConnectValidRole,
    isOpConnectAndEnterPriseValidRole,
    isOpConnectAdminTechEnterpriseValidRole,
    isAdminMgr,
    isEnterPriseAndOpconnectNonTechnicalRole,
    isOpConnectEnterpriseDriverDiscountEditRole,
    isOpConnectEnterpriseRemoteStartApprovedRole,
    userRoleForDisplay,
    isAdminTechRole,
    isManager,
    isOpConnectEnterpriseOpTechRole,
    isAdministrator,
    isSupportCallOperator,
    isAddEditLocUserApprovedRole,
    isMFARequiredUser,
    isHomeChargerAccessAllowed
  };