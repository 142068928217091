//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import * as TicketTypes from "../actiontypes/CallSupportTicket";

const initialState = {
    callSupportList: []
};

export function CallSupportTicketReducer(state=initialState, action) {
    if(action.type != undefined) {
        switch (action.type) {
            //list tickets 
            case TicketTypes.LOAD_CALLSUPPORTTICKETLIST:
                if(action.ticketList)
                {
                    return {
                        ...state,
                        callSupportList:action.ticketList 
                    };
                }
                else 
                    return state;
            case TicketTypes.ADD_CALLSUPPORT_TICKET:

                return {
                    ...state,
                    callSupportList:state.callSupportList.concat(action.add_ticket),
                };
            case TicketTypes.FILTER_BY_ISSUETYPE:
                let value = action.ticketList.value;
                const filteredValues = state.callSupportList.filter(ticket => {
                    return ticket.issueTypeId
                });
                //filter by issueTypeId
                return {
                    ...state,
                    callSupportList: filteredValues,
                }
            case TicketTypes.UPDATE_CALLSUPPORT_TICKET:
                const index = state.callSupportTicket.findIndex((dets) => {
                    return dets.id === action.update_ticket.id;
                });
                if (index >= 0){
                    return {
                        ...state,
                        callSupportList : [
                            ...state.ticketList.slice(0, index),
                            {
                                ...state.ticketList[index],
                                opConnectNotes:action.update_ticket.opConnectNotes
                            },
                            ...state.ticketList.slice(index + 1),
                        ],
                    };
                }else return state;
            default:
                return state;
        }
    }
};
