//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2024 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap';
import KasiButton from '../KasiButton/KasiButton';
import FormatTextWithLineBreaks from './FormatStringsWithLineBreaks';

// This is the Kasi Success modal, it also supports legacy MyOpConnect
// Intended to display success messages to the user
//----------------------------------------------[ Example ] ----------------------------------------------

// import { KasiModalSuccess } from '../../../shared/Kasi/KasiModal'
// <KasiModalSuccess 
//     show={this.state.showSuccessModal}
//     kasibeta={this.props.kasibeta}
//     body={"Your remote start request was successful."}
//     onHide={this.handleCloseAll}
//     close={this.handleCloseAll}
// />

//----------------------------------------------[ Code ] ----------------------------------------------
const KasiModalSuccess = ({
    kasibeta=false,             // Pass kasiBeta in for Kasi style
    header="Success",           // Pass in a Header
    body=                       // Pass in a Body to replace default message (Can handle \n line breaks)
    `Your request was successful`,  
    footer="",                  // Pass in a Footer (Loading doesnt use one)
    children,                   // Allows JSX children to be passed for the body
    className="",               // Custom className
    size="",                    // Custom Bootstrap Modal size
    centered=true,              // Pass in to center the modal (Loading defaults on)
    show=false,                 // Pass in a State to display Modal
    onHide=()=>{},              // Pass in a function to handle on hide
    close=()=>{},               // Pass in a function to handle closing the modal
    confirmLabel="Confirm",     // Pass in a label for the confirm button
}) => {
    return (
        <Modal
            show={show}
            size={size}
            aria-labelledby="contained-modal-title"
            centered={centered}
            className={`${kasibeta ? "KASIBootstrapModalGreen" : ""} ${className}`}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children ? children : FormatTextWithLineBreaks(body)}
                <br /><br />
            </Modal.Body>
            {footer 
            ?   // A footer was passed in
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            :   // No Footer to display
                <Modal.Footer>
                    {kasibeta
                        ? <KasiButton label={confirmLabel} onClick={close} buttonStyle="green" type="button" />
                        : <Button onClick={close}>{confirmLabel}</Button>
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}
    
export default KasiModalSuccess;