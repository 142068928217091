//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2024 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React from 'react';
import styles from './KasiSpinnerNav.module.scss';
import { Spinner } from "react-bootstrap";

//This is the standard centered full page navigation spinner used for Kasi
//It's currently based off of the React-Bootstrap spinner, but a custom spinner can be implemented here.

//import React, { Suspense } from "react";
//import KasiSpinnerNav from '../components/shared/Kasi/KasiSpinnerNav/KasiSpinnerNav';
//<Suspense fallback={<KasiSpinnerNav spinner={} hide={} />}>
const KasiSpinnerNav = ({ spinner, hide }) => {
  const renderSpinner = () => {
    switch (spinner) {
      case 'op':  //This is the OpConnect style spinner
        return (
          <div className={styles.centerSpinnerDiv}>
            <Spinner animation="border" variant="primary" className={`${styles.default} ${hide ? styles.hide : ""}`} />
          </div>
        );
      case 'light': // This is a subtle, less intrusive spinner
        return (
          <div className={styles.centerSpinnerDiv}>
            <Spinner animation="border" variant="secondary" className={`${styles.light} ${hide ? styles.hide : ""}`} />
          </div>
        );
      case 'float': // This floating subtle, less intrusive spinner intended to be used for components that are not full page
      return (
        <Spinner animation="border" variant="secondary" className={`${styles.float} ${hide ? styles.hide : ""}`} />
      );
      default: //This is the Kasi style spinner. TODO: Kasi style spinner needs tweaking to match the design
        return (
          <div className={styles.centerSpinnerDiv}>
            <Spinner animation="border" variant="primary" className={`${styles.kasi} ${hide ? styles.hide : ""}`} />
          </div>
        );
    }
  };

  return renderSpinner();
};

export default KasiSpinnerNav;