//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { Component, lazy, Suspense } from "react";
import Login from "../pages/LoginPage";
import AppProvider from "../../redux/container/AppProvider";
// import ForgotPassword from "../ForgotPassword";
// import CancelPasswordChange from "../CancelPasswordChange";
// import ChangePassword from "../ChangePassword";
// import FinishRegistration from "../pages/FinishRegistrationPage";
import {loadState} from '../../helpers/localStorage';
// import MfaPageProvider from "../../redux/container/LogInLogOut/MfaPageProvider";
// import MfaSettingWizardProvider from "../../redux/container/LogInLogOut/MfaSettingWizardProvider";
// import RecoveryQuestions from "../RecoveryQuestions";
import {isMFARequiredUser} from "../utilities/ValidateUserRoles";

const ForgotPassword = lazy(() => import ("../ForgotPassword"));
const CancelPasswordChange = lazy(() => import ("../CancelPasswordChange"));
const ChangePassword = lazy(() => import ("../ChangePassword"));
const FinishRegistration = lazy(() => import ("../pages/FinishRegistrationPage"));
const MfaPageProvider = lazy(() => import ("../../redux/container/LogInLogOut/MfaPageProvider"));
const MfaSettingWizardProvider = lazy(() => import ("../../redux/container/LogInLogOut/MfaSettingWizardProvider"));
const RecoveryQuestions = lazy(() => import ("../pages/MyAccount/SecuritySettings/RecoveryQuestions"));


export default class ValidateLogin extends Component {

  componentDidMount()
  {
    if(!this.props.authToken)
    {
      // attempt to load state
      var sessionValue = loadState();
      if (sessionValue)
      {
        this.props.loadLogin(sessionValue);
      }
    }
  }

  render() {
    if (
      this.props.location.pathname.includes("changepassword") ||
      this.props.location.pathname.includes("cancelpasswordchange") ||
      this.props.location.pathname.includes("recoveryQuestions") ||
      this.props.location.pathname.includes("forgotpassword")
    ) {
      if (this.props.location.pathname.includes("changepassword")) {
        return(
          <Suspense fallback={<></>}>
            <ChangePassword {...this.props} />;
          </Suspense>
        ) 
      } else if (
        this.props.location.pathname.includes("cancelpasswordchange")
      ) {
        return(
          <Suspense fallback={<></>}>
            <CancelPasswordChange {...this.props} />;
          </Suspense>
        ); 
      } else if (
        this.props.location.pathname.includes("recoveryQuestions")
      ) {
        return(
          <Suspense fallback={<></>}>
            <RecoveryQuestions verify={this.props.onVerifyClick} {...this.props} />;
          </Suspense>
        );
      } else {
        return(
          <Suspense fallback={<></>}>
            <ForgotPassword />
          </Suspense>  
        );
      }
    } else if (this.props.location.pathname.includes("finishregistration")) {
      return(
        <Suspense fallback={<></>}>
          <FinishRegistration {...this.props} />;
        </Suspense>  
      );
    } else if (this.props.siteId === null || this.props.authToken === null || ( !this.props.loginDateTime  )) {
      return (
        // check url here to see if login or finish registration or forgot password should be displayed here.
        
        <Suspense>
          <Login login={this.props.onLoginClick} {...this.props} />
        </Suspense>  
      );
      
    } else if (!this.props.location.pathname.includes("logout") && this.props.requireMfa && this.props.siteId !== null && this.props.authToken !== null 
      && isMFARequiredUser(this.props.userRole) && this.props.mfaOptionId != null) {
        return (
          <Suspense fallback={<></>}>
            <MfaPageProvider verify={this.props.onVerifyClick} {...this.props} />
          </Suspense>
        );
    } else if (!this.props.location.pathname.includes("logout") && this.props.requireMfa && this.props.siteId !== null && this.props.authToken !== null 
      && isMFARequiredUser(this.props.userRole) &&  this.props.mfaOptionId == null) {
        return (
          <Suspense fallback={<></>}>
            <MfaSettingWizardProvider verify={this.props.onVerifyClick} {...this.props} />
          </Suspense>
        );
    } else {
      return <AppProvider {...this.props} />;
    }
  }
}
