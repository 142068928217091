//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import * as DashboardActions from "../actiontypes/dashboard";

const initialState = {
    eventTotals: {},
    objectTotals: {},
    lastUpdatedEventTotals: 0,
    lastUpdatedObjectTotals: 0
  };
  
  export function DashboardReducer(state = initialState, action) {
    // eslint-disable-next-line
    if (action.type != undefined) {
        switch (action.type) {
            case DashboardActions.UPDATE_FLEET_DASHBOARD_EVENT_TOTALS:
                return Object.assign({}, state, {
                    eventTotals: action.data,
                    lastUpdatedEventTotals: Date.now()
                });
            case DashboardActions.UPDATE_FLEET_DASHBOARD_OBJ_TOTALS:
                return Object.assign({}, state, {
                    objectTotals: action.data,
                    lastUpdatedObjectTotals: Date.now()
                });
            case DashboardActions.UPDATE_REG_DASHBOARD_EVENT_TOTALS:
                return Object.assign({}, state, {
                    eventTotals: action.data,
                    lastUpdatedEventTotals: Date.now()
                });
            case DashboardActions.UPDATE_REG_DASHBOARD_OBJ_TOTALS:
                return Object.assign({}, state, {
                    objectTotals: action.data,
                    lastUpdatedObjectTotals: Date.now()
                });
            default:
                return state;
        }
    }
  }