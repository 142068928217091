//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import { connect } from "react-redux";
import App from "../../components/App";
import { updateLocations } from "../actions/locations";
import { clearAllFilters } from "../actions/SortFilter";

const mapStateToProps = state => {
  return {
    locations: state.location.locations,
    siteId: state.session.siteId,
    authToken: state.session.authToken,
    userId: state.session.userId,
    companyType: state.session.companyType,
    companyLogo: state.session.companyLogo,
    companyLogoType: state.session.companyLogoType
  }
}
const mapDispatchToProps = dispatch => {
  return {setLocations: (locations) => {
        dispatch(updateLocations(locations));
      },
      clearAllFilters: (event) => {
        dispatch(clearAllFilters(event));
      }
  }
}

const AppProvider = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppProvider;
