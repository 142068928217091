//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as LoginActions from "../actiontypes/login";

export const Login = (
  siteId,
  authToken,
  userRole,
  userId,
  companyType,
  companyLogo,
  companyLogoType,
  requireMfa,
  mfaEnabled,
  mfaOptionId,
  smsPhone,
  countryCode,
) => {
  return {
    type: LoginActions.LOGIN_SUCCESS,
    siteId, // company id
    authToken,
    userRole,
    userId,
    companyType,
    companyLogo,
    companyLogoType,
    requireMfa,
    mfaEnabled,
    mfaOptionId,
    smsPhone,
    countryCode,
  };
};

export const LoadLogin = (storedState) => {
  return {
    type: LoginActions.LOAD_STORED_STATE,
    storedState: storedState,
  };
};

export const UpdateSessionProps = (showDriverValue, showTokenValue) => {
  return {
    type: LoginActions.UPDATE_STORED_SESSION_VIEW_PROPS,
    showDriverValue,
    showTokenValue,
  };
};

export const ToggleFleetModeProp = (fleetModeProp) => {
  return {
    type: LoginActions.TOGGLE_STORED_FLEET_MODE_PROPS,
    fleetModeProp
  };
};

export const Verify = (
  requireMfa
) => {
  return {
    type: LoginActions.CODE_VERIFY_SUCCESS,
    requireMfa
  };
};
