//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import {AES, enc} from 'crypto-js'

export const saveState = (state) => {
  try {
    const sessionObj = JSON.stringify(state);
    var encSession = AES.encrypt(sessionObj, process.env.REACT_APP_KEY).toString()
    // localStorage.setItem('session', encSession)
    sessionStorage.setItem('session', encSession)

  }catch (err) {
        console.log("Saving state to local storage encountered problem: " + err);
  } 
}

export const loadState = () => {
  try {
    // const encSession = localStorage.getItem('session');
    const encSession = sessionStorage.getItem('session');
    if(!encSession){
      return undefined
    }
    const decrypted = AES.decrypt(encSession, process.env.REACT_APP_KEY);
    const decSession = decrypted.toString(enc.Utf8);
    /* sessionStorage.setItem('decryptedSession', decSession) */
  }catch (err) {
    console.log("Loading state to local storage encounterd problems" + err)
    return undefined;
  }
}

