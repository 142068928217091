//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import KasiSpinnerNav from '../shared/Kasi/KasiSpinnerNav/KasiSpinnerNav';

/* sid = Station ID 
   drvid = Driver ID
   supportid = Support Ticket ID
   suid = Site Owner ID
   uid = user ID
   did = edit ID
   tagid = Tag ID
   shid = SiteHost ID Changed to CompanyUser on Feature 2187
   cid = Compamy ID
   rateid = Rate Structure ID
   edituser:uid = Edit User ID
   csid = Call Schedule ID
   cst = Call Support Ticket ID*/

const Dashboard = lazy(() => import("../../redux/container/DashboardPageProvider"));
const Kasi = lazy(() => import("../../redux/container/DashboardViewProviderKasi"));
const Companies = lazy(() => import("../../redux/container/Company/CompanyPageProvider"));
const CompaniesAdd = lazy(() => import("../../redux/container/Company/CreateCompanyProvider"));
const CompaniesEdit = lazy(() => import("../../redux/container/Company/EditCompanyProvider"));

const Drivers = lazy(() => import("../../redux/container/Drivers/DriverPageProvider"));
const DriverDetails = lazy(() => import("../../redux/container/Drivers/DriverDetailsPageProvider"));

const DriversAdmin = lazy(() => import("../../redux/container/Drivers/DriversAdminListProvider"));
const DriversAdminCreate = lazy(() => import("../../redux/container/Drivers/CreateDriverProvider"));
const DriversAdminEdit = lazy(() => import("../../redux/container/Drivers/EditDriverProvider"));


// const Adminstration = lazy(() => import("../pages/AdministrationPage"));

const Depots = lazy(() => import("../../redux/container/Locations/LocationPageProvider"));

const DepotDetails = lazy(() => import("../../redux/container/Locations/LocationDetailsPageProvider"));
const PrivateSchedule = lazy(() => import("../../redux/container/Locations/PrivateScheduleProvider"));
const ChargingSchedule = lazy(() => import("../../redux/container/Locations/LocationChargingScheduleProvider"));

const Tags = lazy(() => import("../../redux/container/Tags/TagPageProvider"));

const Stations = lazy(() => import("../../redux/container/Stations/StationPageProvider"));
const StationDetails = lazy(() => import("../../redux/container/Stations/StationDetailsPageProvider"));
const StationAdd = lazy(() => import("../../redux/container/Stations/StationAddProvider"));
const StationEdit = lazy(() => import("../../redux/container/Stations/EditStationProvider"));

const ConfigurationAddEditClone = lazy(() => import("../../redux/container/Configurations/ConfigurationAddEditCloneProvider"));
const ConfigurationViewPushedValues = lazy(() => import("../../redux/container/Configurations/ConfigurationViewPushedValuesProvider"));
const ConfigurationsManage = lazy(() => import("../../redux/container/Configurations/ConfigurationsManageProvider"));
const ConfigurationsInactive = lazy(() => import("../../redux/container/Configurations/ConfigurationsInactiveProvider"));
const ConfigurationsBuilt = lazy(() => import("../../redux/container/Configurations/ConfigurationsBuiltProvider"));
const BeagleBonesInactive = lazy(() => import("../../redux/container/Configurations/BeagleBonesInactiveProvider"));
const BeagleBonesBuilt = lazy(() => import("../../redux/container/Configurations/BeagleBonesBuiltProvider"));

const EditMfaSetting = lazy(() => import ("../pages/MyAccount/SecuritySettings/EditMfaSettingPage"));

const Reports = lazy(() => import("../../redux/container/Reports/ReportManagerProvider"));
const UtilityRates = lazy(() => import("../pages/UtilityRatesPage"));

const MyAccount = lazy(() => import ("../../redux/container/MyAccount/MyAccountPageProvider"));
const EditMyAccount = lazy(() => import ("../../redux/container/MyAccount/EditMyAccountProvider"));
const ManagementPortalUsers = lazy(() => import ("../../redux/container/User/ManagementPortalUsersPageProvider"));
const CreateManagementPortalUsersUser = lazy(() => import ("../../redux/container/User/CreateManagementPortalUserProvider"));
const EditManagementPortalUsersUser = lazy(() => import ("../../redux/container/User/EditManagementPortalUserProvider"));


const RateStructure = lazy(() => import ("../../redux/container/RateStructure/RatePlanPageProvider"));
const CreateRatePlan = lazy(() => import ("../../redux/container/RateStructure/CreateRateStructureProvider"));
const RateStructureEdit = lazy(() => import ("../../redux/container/RateStructure/EditRatePlanProvider"));

const Help = lazy(() => "/components/pages/LogoutPage");

const Support = lazy(() => import('../../redux/container/SupportIssues/SupportIssuePageProvider'));
const SupportIssueDetails = lazy(() => import("../../redux/container/SupportIssues/SupportIssueDetailProvider"));
const SupportIssueTicketCreation = lazy(() => import("../../redux/container/SupportIssues/CreateSupportTicketProvider"));

const CallSupportTicketOnboarding = lazy(() => import("../../redux/container/CallSupport/OperatorCallSupportProvider"));
const TransferCallList = lazy(() => import("../pages/CallListPage"));
const CallSupportTicketAccount = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketAccount_AddProvider"));
const CallSupportTicketApp = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketApp_AddProvider"));
const CallSupportTicketRefund = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketRefund_AddProvider"));
const CallSupportTicketSales = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketSales_AddProvider"));
const CallSupportTicketStation = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketStation_AddProvider"));
const CallSupportTicketRemoteStart = lazy(() => import("../../redux/container/CallSupport/CallSupportTicketRemoteStart_AddProvider"));

const CallSupportUpateTicket = lazy(() => import("../../redux/container/CallSupport/CallSupportListProvider"));

const Sessions = lazy(() => import('../../redux/container/Sessions/SessionListProvider'));
const OpenSessions = lazy(() => import('../../redux/container/Sessions/OpenSessionListProvider'));
const OpenSessionsConnected = lazy(() => import('../../redux/container/Sessions/OpenSessionListProvider'));

const Error404 = lazy(() => import("../shared/error-pages/Error404"));
const Error500 = lazy(() => import("../shared/error-pages/Error500"));

const Contact = lazy(() => import("../pages/Contact"));
const TermsAndCondition = lazy(() => import("../pages/TermsConditions"));

const Login = lazy(() => import("../../redux/container/LoginContainer"));
const Logout = lazy(() => import("../../redux/container/LogoutContainer"));
const Mfa = lazy(() => import("../../redux/container/LogInLogOut/MfaPageProvider"));
// const ForgotPassword = lazy(() => import("../pages/ForgotPasswordPage")); //Loaded outside of routes
const Register = lazy(() => import("../pages/RegisterPage"));

const FleetVehicles = lazy(() => import('../../redux/container/FleetPortal/FleetVehiclesPageProvider')); 
const FleetVehicleAdd = lazy(() => import("../../redux/container/FleetPortal/AddFleetVehicleProvider"));
const FleetVehicleDetails = lazy(() => import("../../redux/container/FleetPortal/FleetVehicleDetailsProvider"));

const FleetVehiclesReport = lazy(() => import('../../redux/container/FleetPortal/FleetVehiclesReportPageProvider'));
const EVDetails = lazy(() => import("../../redux/container/FleetPortal/FleetVehicleReportDetailsPageProvider")); 
const FleetTokens = lazy(() => import('../../redux/container/FleetPortal/FleetTokensPageProvider')); 
const CompanyGroups = lazy(() => import('../../redux/container/FleetPortal/CompanyGroupsPageProvider')); 

const CreateFleetRateStructure = lazy(() => import('../../redux/container/RateStructure/CreateFleetRateStructureProvider'));
const EditFleetRateStructure = lazy(() => import('../../redux/container/RateStructure/EditFleetRateStructureProvider'));
const FleetRateStructurePage = lazy(() => import('../../redux/container/RateStructure/FleetRateStructurePageProvider'));

export default class AppRoutes extends Component {
  render() {

    return (
      <Suspense fallback={<KasiSpinnerNav />}>
          { this.props.kasibeta 
          //--------------------------------------------------------------------------------------KASI BETA ROUTES--------------------------------------------------------------------------------------
          //Note: The only one with a different routed component is the Dashboard/Kasi, everything else has a KasiBeta prop passed though it for conditional styling
          //This could have been combined with the original MyOpConnect routes, but they are kept seperate to track progress and incase anything changes with the routes.
          ? <Switch>
              <Route exact path="/companies" component={Companies} />
              <Route exact path="/companies/create" component={CompaniesAdd} />
              <Route exact strict path="/companies/:cid" component={CompaniesEdit} />

              <Route exact path="/drivers" component={Drivers} />
              <Route exact path="/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/drivers/:drvid/sessions" component={Sessions} /> 

              <Route exact path="/evdriveraccount" component={DriversAdmin} />
              <Route exact path="/evdriveraccount:alphaId" component={DriversAdmin} />
              <Route exact path="/evdriveraccount/create" component={DriversAdminCreate} /> 
              <Route exact path="/evdriveraccount/editdriver:did" component={DriversAdminEdit} />

              {/* <Route exact path="/administration" component={Adminstration} /> */}
              <Route exact path="/myaccount" render={(routeProps) => ( <MyAccount {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/locations" component={Depots} />
              <Route exact path="/locations/:lid" component={DepotDetails} />
              <Route exact path="/locations/:lid/privateschedule" component={PrivateSchedule} /> 
              <Route exact path="/locations/:lid/chargingschedule" component={ChargingSchedule} />
              
              <Route exact path="/locations/:lid/stations" render={(routeProps) => ( <Stations {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/locations/:lid/stations/addstation" render={(routeProps) => ( <StationAdd {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/locations/:lid/stations/:sid" render={(routeProps) => ( <StationDetails {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/locations/:lid/stations/:sid(\d+)/editstation" render={(routeProps) => ( <StationEdit {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/locations/:lid/drivers" component={Drivers} />
              <Route exact path="/locations/:lid/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/locations/:lid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/drivers" component={Drivers} />          
              <Route exact path="/locations/:lid/stations/:sid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/drivers/:drvid" component={DriverDetails} />
              <Route exact strict path="/locations/:lid/stations/:sid/support" component={Support} />
              <Route exact strict path="/locations/:lid/stations/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact strict path="/locations/:lid/support" component={Support} />
              <Route exact strict path="/locations/:lid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path="/locations/:lid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/sessions" component={Sessions} />

              <Route exact path="/stations" render={(routeProps) => ( <Stations {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/addstation" render={(routeProps) => ( <StationAdd {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/stations/addconfiguration" render={(routeProps) => ( <ConfigurationAddEditClone {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/:sid/editconfiguration" render={(routeProps) => ( <ConfigurationAddEditClone {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/:sid/cloneconfiguration" render={(routeProps) => ( <ConfigurationAddEditClone {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/:sid/viewpushedvalues" render={(routeProps) => ( <ConfigurationViewPushedValues {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/manageconfigurations" render={(routeProps) => ( <ConfigurationsManage {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/inactiveconfigurations" render={(routeProps) => ( <ConfigurationsInactive {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/builtconfigurations" render={(routeProps) => ( <ConfigurationsBuilt {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/inactivebeaglebones" render={(routeProps) => ( <BeagleBonesInactive {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/stations/builtbeaglebones" render={(routeProps) => ( <BeagleBonesBuilt {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/stations/:sid/sessions" component={Sessions} />
              <Route exact path="/stations/:sid/drivers" component={Drivers} />
              <Route exact path="/stations/:sid/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/stations/:sid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact strict path="/stations/:sid/support" component={Support} />
              <Route exact strict path="/stations/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path="/stations/:sid/editstation" render={(routeProps) => ( <StationEdit {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/stations/:sid" render={(routeProps) => ( <StationDetails {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/tags" component={Tags} />
              <Route exact path="/tags/sessions-tagid-:tagid" component={Sessions} />																			  													 

              <Route exact path="/reports" render={(routeProps) => ( <Reports {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/utilityrates" component={UtilityRates} />

              <Route exact path="/ratestructures" component={RateStructure} />
              <Route exact path="/ratestructures/createrateplan" component={CreateRatePlan} />
              <Route exact path="/ratestructures/:rateid" component={RateStructureEdit} />

              <Route exact path="/support" component={Support} />
              <Route exact path='/support/createTicket' component={SupportIssueTicketCreation} />	
              <Route exact path='/support/:supportid' component={SupportIssueDetails} />


              <Route exact path='/OperatorCallSupport' component={CallSupportTicketOnboarding} />
              <Route exact path='/OperatorCallSupport/Call_List' component={TransferCallList} />
              <Route exact path='/OperatorCallSupport/Account' component={CallSupportTicketAccount} />
              <Route exact path='/OperatorCallSupport/App' component={CallSupportTicketApp} />
              <Route exact path='/OperatorCallSupport/Refund' component={CallSupportTicketRefund} />
              <Route exact path='/OperatorCallSupport/Sales' component={CallSupportTicketSales} />
              <Route exact path='/OperatorCallSupport/Station' component={CallSupportTicketStation} />
              <Route exact path='/OperatorCallSupport/Remote_Start' component={CallSupportTicketRemoteStart} />

              <Route exact path="/CallSupport" component={CallSupportUpateTicket}/>

              <Route exact path='/sessions' component={Sessions} />
              <Route exact strict path="/opensessions" component={OpenSessions} />		
                                      
              <Route exact path="/managementportalusers" render={(routeProps) => ( <ManagementPortalUsers {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/managementportalusers/createmanagementportaluser" render={(routeProps) => ( <CreateManagementPortalUsersUser {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path='/managementportalusers/editmanagementportaluser:uid' render={(routeProps) => ( <EditManagementPortalUsersUser {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/users/editMyAccount:uid" render={(routeProps) => ( <EditMyAccount {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path='/users/editMfaSetting:uid' component={EditMfaSetting} />

    {/*----------------------START: Fleet mode specific paths -------------------------*/}
              <Route exact path="/depots" component={Depots} />     
              <Route exact path="/depots/:lid/privateschedule" component={PrivateSchedule} /> 
              <Route exact path="/depots/:lid/chargingschedule" component={ChargingSchedule} /> 
              <Route exact path="/depots/:lid/chargers" render={(routeProps) => ( <Stations {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/depots/:lid/chargers/addcharger" render={(routeProps) => ( <StationAdd {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/depots/:lid/chargers/:sid/sessions" component={Sessions} />   
              <Route exact path="/depots/:lid/chargers/:sid/editcharger" render={(routeProps) => ( <StationEdit {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact path="/depots/:lid/chargers/:sid" render={(routeProps) => ( <StationDetails {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/depots/:lid/sessions" component={Sessions} />
              <Route exact strict path="/depots/:lid/chargers/:sid/support" component={Support} />
              <Route exact strict path="/depots/:lid/chargers/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact strict path="/depots/:lid/support" component={Support} />
              <Route exact strict path="/depots/:lid/support/:supportid" component={SupportIssueDetails} />

              <Route exact path="/depots/:lid" component={DepotDetails} /> 
              <Route exact strict path="/chargers/:sid/support" component={Support} />
              <Route exact strict path="/chargers/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path="/chargers/:sid/editCharger" render={(routeProps) => ( <StationEdit {...routeProps} kasibeta={this.props.kasibeta} /> )} />


              <Route exact path="/chargers" render={(routeProps) => ( <Stations {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/chargers/addcharger" render={(routeProps) => ( <StationAdd {...routeProps} kasibeta={this.props.kasibeta} /> )} />
              <Route exact path="/chargers/:sid/sessions" component={Sessions} />
              <Route exact path="/chargers/:sid" render={(routeProps) => ( <StationDetails {...routeProps} kasibeta={this.props.kasibeta} /> )} />

              <Route exact strict path="/evscharging" component={OpenSessions} />
              <Route exact strict path="/evsconnected" component={OpenSessionsConnected} />

              <Route exact path="/fleetvehicles" component={FleetVehicles} />
              <Route exact path="/evs" component={FleetVehiclesReport} />
              <Route exact path="/evs/:evid" component={EVDetails} /> 
              <Route exact path="/evs/:evid/sessions" component={Sessions} />
              <Route exact path='/fleetvehicles/addvehicle' component={FleetVehicleAdd} />
              <Route exact path='/fleetvehicles/:vehicleId' component={FleetVehicleDetails} />

              <Route exact path="/fleettokens" component={FleetTokens} />
              <Route exact path="/companygroups" component={CompanyGroups} />


              <Route exact path="/coststructures" component={FleetRateStructurePage} />
              <Route exact path="/coststructures/addfleetrate" component={CreateFleetRateStructure} />
              <Route exact path="/coststructures/:rateid(\d+)" component={EditFleetRateStructure} />
    {/*-----------------------END: Fleet mode specific paths -----------------------------*/}

              <Route path="/error404" component={Error404} />
              <Route path="/error500" component={Error500} />

              <Route path="/help" component={Help} />

              <Route path="/contact" component={Contact} />
              <Route path="/termsCondition" component={TermsAndCondition} />

              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/mfa" component={Mfa} />
              {/* <Route path="/forgotpassword" component={ForgotPassword} /> // Component outside of routes*/} 
              <Route path="/register" component={Register} />
              <Route path="/" render={(routeProps) => ( <Kasi {...routeProps} {...this.props} /> )} />
              <Redirect to="/"  />
            </Switch>
          //----------------------------------------------------------------------------------ORIGINAL MYOPCONNECT ROUTES----------------------------------------------------------------------------------
          : <Switch>
              <Route exact path="/companies" component={Companies} />
              <Route exact path="/companies/create" component={CompaniesAdd} />
              <Route exact strict path="/companies/:cid" component={CompaniesEdit} />

              <Route exact path="/drivers" component={Drivers} />
              <Route exact path="/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/drivers/:drvid/sessions" component={Sessions} /> 

              <Route exact path="/evdriveraccount" component={DriversAdmin} />
              <Route exact path="/evdriveraccount:alphaId" component={DriversAdmin} />
              <Route exact path="/evdriveraccount/create" component={DriversAdminCreate} /> 
              <Route exact path="/evdriveraccount/editdriver:did" component={DriversAdminEdit} />

              {/* <Route exact path="/administration" component={Adminstration} /> */}
              <Route exact path="/myaccount" component={MyAccount} />

              <Route exact path="/locations" component={Depots} />
              <Route exact path="/locations/:lid" component={DepotDetails} />
              <Route exact path="/locations/:lid/privateschedule" component={PrivateSchedule} /> 
              <Route exact path="/locations/:lid/chargingschedule" component={ChargingSchedule} />
              <Route exact path="/locations/:lid/stations" component={Stations} /> 
              <Route exact path='/locations/:lid/stations/addstation' component={StationAdd} />
              <Route exact path="/locations/:lid/stations/:sid" component={StationDetails} />
              <Route exact path="/locations/:lid/stations/:sid(\d+)/editstation" component={StationEdit} />
              <Route exact path="/locations/:lid/drivers" component={Drivers} />
              <Route exact path="/locations/:lid/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/locations/:lid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/drivers" component={Drivers} />          
              <Route exact path="/locations/:lid/stations/:sid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/drivers/:drvid" component={DriverDetails} />
              <Route exact strict path="/locations/:lid/stations/:sid/support" component={Support} />
              <Route exact strict path="/locations/:lid/stations/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact strict path="/locations/:lid/support" component={Support} />
              <Route exact strict path="/locations/:lid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path="/locations/:lid/sessions" component={Sessions} />
              <Route exact path="/locations/:lid/stations/:sid/sessions" component={Sessions} />

              <Route exact path="/stations" component={Stations} />
              <Route exact path='/stations/addstation' component={StationAdd} />

              <Route exact path='/stations/addconfiguration' component={ConfigurationAddEditClone} />
              <Route exact path='/stations/:sid/editconfiguration' component={ConfigurationAddEditClone} />
              <Route exact path='/stations/:sid/cloneconfiguration' component={ConfigurationAddEditClone} />
              <Route exact path='/stations/:sid/viewpushedvalues' component={ConfigurationViewPushedValues} />
              <Route exact path='/stations/manageconfigurations' component={ConfigurationsManage} />
              <Route exact path='/stations/inactiveconfigurations' component={ConfigurationsInactive} />
              <Route exact path='/stations/builtconfigurations' component={ConfigurationsBuilt} />
              <Route exact path='/stations/inactivebeaglebones' component={BeagleBonesInactive} />
              <Route exact path='/stations/builtbeaglebones' component={BeagleBonesBuilt} />

              <Route exact path="/stations/:sid/sessions" component={Sessions} />
              <Route exact path="/stations/:sid/drivers" component={Drivers} />
              <Route exact path="/stations/:sid/drivers/:drvid" component={DriverDetails} />
              <Route exact path="/stations/:sid/drivers/:drvid/sessions" component={Sessions} />
              <Route exact strict path="/stations/:sid/support" component={Support} />
              <Route exact strict path="/stations/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path='/stations/:sid/editstation' component={StationEdit} />
              <Route exact path="/stations/:sid" component={StationDetails} />

              <Route exact path="/tags" component={Tags} />
              <Route exact path="/tags/sessions-tagid-:tagid" component={Sessions} />																			  													 

              <Route exact path="/reports" component={Reports} />
              <Route exact path="/utilityrates" component={UtilityRates} />

              <Route exact path="/ratestructures" component={RateStructure} />
              <Route exact path="/ratestructures/createrateplan" component={CreateRatePlan} />
              <Route exact path="/ratestructures/:rateid" component={RateStructureEdit} />

              <Route exact path="/support" component={Support} />
              <Route exact path='/support/createTicket' component={SupportIssueTicketCreation} />	
              <Route exact path='/support/:supportid' component={SupportIssueDetails} />


              <Route exact path='/OperatorCallSupport' component={CallSupportTicketOnboarding} />
              <Route exact path='/OperatorCallSupport/Call_List' component={TransferCallList} />
              <Route exact path='/OperatorCallSupport/Account' component={CallSupportTicketAccount} />
              <Route exact path='/OperatorCallSupport/App' component={CallSupportTicketApp} />
              <Route exact path='/OperatorCallSupport/Refund' component={CallSupportTicketRefund} />
              <Route exact path='/OperatorCallSupport/Sales' component={CallSupportTicketSales} />
              <Route exact path='/OperatorCallSupport/Station' component={CallSupportTicketStation} />
              <Route exact path='/OperatorCallSupport/Remote_Start' component={CallSupportTicketRemoteStart} />

          <Route exact path="/CallSupport" component={CallSupportUpateTicket}/>

              <Route exact path='/sessions' component={Sessions} />
              <Route exact strict path="/opensessions" component={OpenSessions} />		
                                      
              <Route exact path="/managementportalusers" from component={ManagementPortalUsers} />
              <Route exact path="/managementportalusers/createmanagementportaluser" component={CreateManagementPortalUsersUser} />
              <Route exact path='/managementportalusers/editmanagementportaluser:uid'  component={EditManagementPortalUsersUser} />
              <Route exact path='/users/editMyAccount:uid'  component={EditMyAccount} />
              <Route exact path='/users/editMfaSetting:uid' component={EditMfaSetting} />

    {/*----------------------START: Fleet mode specific paths -------------------------*/}
              <Route exact path="/depots" component={Depots} />     
              <Route exact path="/depots/:lid/privateschedule" component={PrivateSchedule} /> 
              <Route exact path="/depots/:lid/chargingschedule" component={ChargingSchedule} /> 
              <Route exact path="/depots/:lid/chargers" component={Stations} />        
              <Route exact path='/depots/:lid/chargers/addcharger' component={StationAdd} />
              <Route exact path="/depots/:lid/chargers/:sid/sessions" component={Sessions} />   
              <Route exact path="/depots/:lid/chargers/:sid/editcharger" component={StationEdit} /> 
              <Route exact path="/depots/:lid/chargers/:sid" component={StationDetails} />
              <Route exact path="/depots/:lid/sessions" component={Sessions} />
              <Route exact strict path="/depots/:lid/chargers/:sid/support" component={Support} />
              <Route exact strict path="/depots/:lid/chargers/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact strict path="/depots/:lid/support" component={Support} />
              <Route exact strict path="/depots/:lid/support/:supportid" component={SupportIssueDetails} />

              <Route exact path="/depots/:lid" component={DepotDetails} /> 
              <Route exact strict path="/chargers/:sid/support" component={Support} />
              <Route exact strict path="/chargers/:sid/support/:supportid" component={SupportIssueDetails} />
              <Route exact path="/chargers/:sid/editCharger" component={StationEdit} />

              <Route exact path="/chargers" component={Stations} />
              <Route exact path='/chargers/addcharger' component={StationAdd} />          
              <Route exact path="/chargers/:sid/sessions" component={Sessions} />
              <Route exact path="/chargers/:sid" component={StationDetails} />

              <Route exact strict path="/evscharging" component={OpenSessions} />
              <Route exact strict path="/evsconnected" component={OpenSessions} />

              <Route exact path="/fleetvehicles" component={FleetVehicles} />
              <Route exact path="/evs" component={FleetVehiclesReport} />
              <Route exact path="/evs/:evid" component={EVDetails} /> 
              <Route exact path="/evs/:evid/sessions" component={Sessions} />
              <Route exact path='/fleetvehicles/addvehicle' component={FleetVehicleAdd} />
              <Route exact path='/fleetvehicles/:vehicleId' component={FleetVehicleDetails} />

              <Route exact path="/fleettokens" component={FleetTokens} />
              <Route exact path="/companygroups" component={CompanyGroups} />


              <Route exact path="/coststructures" component={FleetRateStructurePage} />
              <Route exact path="/coststructures/addfleetrate" component={CreateFleetRateStructure} />
              <Route exact path="/coststructures/:rateid(\d+)" component={EditFleetRateStructure} />
    {/*-----------------------END: Fleet mode specific paths -----------------------------*/}

              <Route path="/error404" component={Error404} />
              <Route path="/error500" component={Error500} />

              <Route path="/help" component={Help} />

              <Route path="/contact" component={Contact} />
              <Route path="/termsCondition" component={TermsAndCondition} />

              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/mfa" component={Mfa} />
              {/* <Route path="/forgotpassword" component={ForgotPassword} /> // Component outside of routes*/} 
              <Route path="/register" component={Register} />
              <Route path="/" component={Dashboard} />
              <Redirect to="/"  />
            </Switch>
        }
      </Suspense>
    );
  }
}
