//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import { configureStore} from '@reduxjs/toolkit';

import rootReducer from '../redux/reducers/rootReducer';
import {saveState} from './localStorage';
import throttle from 'lodash/throttle';

//const sessionValue = loadState();

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    //preloadState: { session: sessionValue},
    saveState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }),
});

store.subscribe(throttle(() => {
    saveState(store.getState().session);
}, 1000 * 10));

export default store;