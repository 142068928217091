//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 


const parseJSONCompany = (data) =>
{
  let company = {};
  if (data)
  {
    company = {
      "active": data.active,
      "addressline1": data.addressline1,
      "addressline2": data.addressline2,
      "authorizingfee_creditcard": data.authorizingfee_creditcard,
      "authorizingfee_member": data.authorizingfee_member,
      "city": data.city,
      "companydbaname": data.companydbaname,
      "companyid": data.companyid,
      "companylegalname": data.companylegalname,
      "companylicensetermtype": {
          "companylicensetermtype": data.companylicensetermtype ? data.companylicensetermtype.companylicensetermtype : null,
          "companylicensetermtypeid":  data.companylicensetermtype ? data.companylicensetermtype.companylicensetermtypeid : null,
      },
      "companytype": {
          "companytype": data.companytype ? data.companytype.companytype : null,
          "companytypeid":  data.companytype ? data.companytype.companytypeid : null,
      },
      "country": data.country,
      "creditcardmarkupamount": data.creditcardmarkupamount,
      "creditcardmarkupunittype": {
          "companycreditcardmarkupunittype": data.creditcardmarkupunittype ? data.creditcardmarkupunittype.companycreditcardmarkupunittype : null,
          "companycreditcardmarkupunittypeid": data.creditcardmarkupunittype ? data.creditcardmarkupunittype.companycreditcardmarkupunittypeid : null,
      },
      "currency": {
          "code": data.currency ? data.currency.code : null,
          "country": data.currency ? data.currency.country : null,
          "currencyid": data.currency ? data.currency.currencyid : null,
          "currencyname": data.currency ? data.currency.currencyname : null,
          "symbol": data.currency ? data.currency.symbol : null
      },
      "kwhrate": data.kwhrate,
      "licenseagreementdate": data.licenseagreementdate,
      "licensetermvalue": data.licensetermvalue,
      "locations": data.locations,
      "logo": data.logo,
      "networkfee": data.networkfee,
      "networkfeefrequency": {
          "companynetworkfeefrequency": data.networkfeefrequency ? data.networkfeefrequency.companynetworkfeefrequency : null,
          "companynetworkfeefrequencyid": data.networkfeefrequency ? data.networkfeefrequency.companynetworkfeefrequencyid : null
      },
      "postalcode": data.postalcode,
      "primarypointofcontact_user": {
            "id": data.primarypointofcontact_user ? data.primarypointofcontact_user.id : null,
            "cellProviderId": data.primarypointofcontact_user ? data.primarypointofcontact_user.cellProviderId : null,
            "email": data.primarypointofcontact_user ? data.primarypointofcontact_user.email : null,
            "firstName": data.primarypointofcontact_user ? data.primarypointofcontact_user.firstName : null,
            "lastName": data.primarypointofcontact_user ? data.primarypointofcontact_user.lastName : null,
            "smsPhone": data.primarypointofcontact_user ? data.primarypointofcontact_user.smsPhone : null
        },
      "processingfee_creditcard": data.processingfee_creditcard,
      "processingfee_member": data.processingfee_member,
      "province": data.province,
      "reimbursementfrequency": {
          "companyreimbursementfrequency": data.reimbursementfrequency ? data.reimbursementfrequency.companyreimbursementfrequency : null ,
          "companyreimbursementfrequencyid": data.reimbursementfrequency ? data.reimbursementfrequency.companyreimbursementfrequencyid : null
      },
      "reimbursementtype": {
          "companyreimbursementtype": data.reimbursementtype ? data.reimbursementtype.companyreimbursementtype : null,

          "companyreimbursementtypeid": data.reimbursementtype ? data.reimbursementtype.companyreimbursementtypeid : null
      },
      "state": data.state,
      "subscriptiontype": {
          "companysubscriptiontype": data.subscriptiontype ? data.subscriptiontype.companysubscriptiontype : null,
          "companysubscriptiontypeid": data.subscriptiontype ? data.subscriptiontype.companysubscriptiontypeid : null
      },													 
		"separateBilling": data.separateBilling,  
      "logoType": data.logoType,
      "logoWidth": data.logoWidth,
      "logoHeight": data.logoHeight,
      "smtpServer": data.smtpServer,
      "smtpServerPort": data.smtpServerPort,
      "smtpServerPassword": data.smtpServerPassword,
      "notificationReturnEmail": data.notificationReturnEmail,
      "companyURL": data.companyURL,
      "supportEmail": data.supportEmail,
      "managingCompanyId": data.managingCompanyId
    };
  }
  return company;
}

module.exports = {parseJSONCompany};
