//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2024 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';

// This is the Kasi Pending modal, it also supports legacy MyOpConnect
// Intended to be used while the user is waiting for something to complete (Usually server requests)
//----------------------------------------------[ Example ] ----------------------------------------------

// import { KasiModalPending } from '../../../shared/Kasi/KasiModal'
// <KasiModalPending
//     show={this.state.showLoadingModal}
//     kasibeta={this.props.kasibeta}
//     header="Please Wait"
//     body="Request is being sent..."
// />

//----------------------------------------------[ Code ] ----------------------------------------------

const KasiModalPending = ({
    kasibeta=false,             // Pass kasiBeta in for Kasi style
    header="Please Wait",       // Pass in a Header
    body=                       // Pass in a Body to replace default message (Can handle \n line breaks)
    `Request is being sent...`,  
    footer="",                  // Pass in a Footer (Loading doesnt use one)
    children,                   // Allows JSX children to be passed for the body
    className="",               // Custom className
    size="",                    // Custom Bootstrap Modal size
    centered=true,              // Pass in to center the modal (Loading defaults on)
    show=false,                 // Pass in a State to display Modal
    onHide=()=>{},              // Pass in a function to handle on hide
}) => {
    return (
        <Modal
            show={show}
            size={size}
            aria-labelledby="contained-modal-title"
            centered={centered}
            className={`${kasibeta ? "KASIBootstrapModalLoading" : ""} ${className}`}
            onHide={()=>{ onHide() }}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title">{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children ? children : body}
                <br /><br /><br />
                <Row className="centerItems">
                        <div className='sweet-loading'>
                            <BeatLoader
                                color={kasibeta ? '#00FF00': '#0095d6'}
                                size="30px"
                                margin="5px"
                                loading={show}
                            />
                        </div>
                    </Row>
            </Modal.Body>
            {footer 
            ?   // A footer was passed in
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            :   // No Footer to display
                <></>
            }
        </Modal>
    )
}
    
export default KasiModalPending;