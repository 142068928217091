//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

export const UPDATE_DRIVERS_TOTALS = 'UPDATE_DRIVERS_TOTALS';
export const UPDATE_DRIVERS_ANALYTICS = 'UPDATE_DRIVERS_ANALYTICS';
export const UPDATE_DRIVERS_DETAILS =  'UPDATE_DRIVERS_DETAILS';
export const GET_DRIVERS_HOME_CHARGER_PROPS = 'GET_DRIVERS_HOME_CHARGER_PROPS';
export const GET_DRIVERS_LIST = 'GET_DRIVERS_LIST';
export const ADD_DRIVER = 'ADD_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const GET_OPEN_TOKENS_LIST = 'GET_OPEN_TOKENS_LIST';
export const DELETE_OPEN_TOKEN = 'DELETE_OPEN_TOKEN';