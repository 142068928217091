//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import groupBy from 'lodash/groupBy';
import moment from "moment";

import { parseJSONCompany } from "../../components/utilities/CompanyParser";
import * as CompanyTypes from "../actiontypes/Company";



export function CompanyAnalyticsReducer(
  state = {
    companyDetails: [],
    analytics: [],
    lastUpdatedAnalytics: null,
    lastUpdatedCompanyUsers: null,
  },
  action
) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case CompanyTypes.UPDATE_COMPANY_ANALYTICS:
        if (action.companyAnalytics && action.companyAnalytics.length > 0)
        {

          if(action.periodType === "day")
          {
            //Update date to locale date value before storing.
            for(var i = 0; i < action.companyAnalytics.length; ++i)
            {
              action.companyAnalytics[i].date = moment.utc(action.companyAnalytics[i].date).local().format("YYYY-MM-DD HH:mm");
            }
          }
          else if (action.periodType !== "day") 
          {
            //Update date to locale date value before storing.
            for (var i = 0; i < action.companyAnalytics.length; ++i) {
              action.companyAnalytics[i].date = moment.utc(action.companyAnalytics[i].date).local().format("YYYY-MM-DD");
            }
          }

          let groupedStat = groupBy(action.companyAnalytics, x => x.id);
          var updatedCompany = [];
          if(state.companyDetails.length === 0 )
          {
            for (var value in groupedStat)
            {
              var kWhTotal = groupedStat[value].reduce((kWhTotal,sa)=>{
                return kWhTotal += Number(sa.totals.kWh);
              },0);

              var sessionsTotal = groupedStat[value].reduce((sessionsTotal,sa)=>{
                return sessionsTotal += Number(sa.totals.chargingSessions);
              },0);

              var revenueTotal = groupedStat[value].reduce((revenueTotal,sa)=>{
                return revenueTotal += Number(sa.totals.revenue);
              },0);

              var durationTotal = groupedStat[value].reduce((durationTotal, sa) =>{
                if(sa.totals.duration && sa.totals.duration.length > 0)
                  {
                    return durationTotal += Number(moment.duration(sa.totals.duration).asHours());
                  }
                  else
                    return 0;
              },0);

              updatedCompany.push({"companyid": value, "kWh":kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.00,
              "sessions":sessionsTotal,"revenue":revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.00 ,
              "duration": durationTotal ? parseFloat(durationTotal.toFixed(2)) : 0.0, "analytics": groupedStat[value] });
            }

            return Object.assign({}, state, {
              analytics: action.companyAnalytics,
              lastUpdatedAnalytics: Date.now(),
              companyDetails: updatedCompany
            });
          }
          else
          {
              updatedCompany = state.companyDetails;
              for (var value in groupedStat)
              {

                var kWhTotal = groupedStat[value].reduce((kWhTotal,sa)=>{
                  return kWhTotal += Number(sa.totals.kWh);
                },0);

                var sessionsTotal = groupedStat[value].reduce((sessionsTotal,sa)=>{
                  return sessionsTotal += Number(sa.totals.chargingSessions);
                },0);

                var revenueTotal = groupedStat[value].reduce((revenueTotal,sa)=>{
                  return revenueTotal += Number(sa.totals.revenue);
                },0);

                var durationTotal = groupedStat[value].reduce((durationTotal, sa) =>{
                  if(sa.totals.duration && sa.totals.duration.length > 0)
                    {
                      return durationTotal += Number(moment.duration(sa.totals.duration).asHours());
                    }
                    else
                      return 0;
                },0);

                  let index = updatedCompany.findIndex((comp) => { return value == comp.companyid; })
                  if (index >= 0)
                  {
                    let currCompanyList = [
                      ...updatedCompany.slice(0, index),
                      {...updatedCompany[index],  
                        "kWh":kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.00,
                           "sessions":sessionsTotal,"revenue":revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.00 ,
                           "duration": durationTotal ? parseFloat(durationTotal.toFixed(2)) : 0.0,  "analytics": groupedStat[value]},
                      ...updatedCompany.slice(index + 1)
                      ];
                    updatedCompany = currCompanyList;
                  }
                  else
                  {
                    updatedCompany.push({"kWh":kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.00,
                    "sessions":sessionsTotal,"revenue":revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.00 ,
                    "duration": durationTotal ? parseFloat(durationTotal.toFixed(2)) : 0.0, "companyid": value, "analytics": groupedStat[value]});
                  }
              }

            return Object.assign({}, state, {
              analytics: action.companyAnalytics,
              lastUpdatedAnalytics: Date.now(),
              companyDetails: updatedCompany
            });
          }
        }
        else
        {
          let updatedCompany = state.companyDetails.map((comp, index)=>{
            return {...comp, "companyid": comp.companyid, "kWh": 0.00,
            "sessions": 0.00 ,
            "duration":  0.0, "analytics": [] }
          });
          return Object.assign({}, state, {
            analytics: [],
            lastUpdatedAnalytics: Date.now(),
            companyDetails: updatedCompany
          });
        }
        break;
      case CompanyTypes.UPDATE_COMPANY_DETAILS:
        if (action.data) {
          var updatedCompany = [];
          if (state.companyDetails.length > 0) {
            for (var i = 0; i < action.data.length; ++i) {
              let company = parseJSONCompany(action.data[i]);
              let index = state.companyDetails.findIndex((comp) => { return company.companyid == comp.companyid; })
              if (index >= 0)
              {
                updatedCompany.push(Object.assign({}, state.companyDetails[index], company));
              }
              else
              {
                updatedCompany.push(Object.assign({},{"kWh": 0.00, "sessions": 0, "revenue": 0.00, "duration": 0.0}, company));
              }

            }
          }
          else
          {

            for (var i = 0; i < action.data.length; ++i)
            {
              let company = parseJSONCompany(action.data[i]);
              updatedCompany.push(company);
            }
          }

          return Object.assign({}, state, {
            companyDetails: updatedCompany
          });

        }
        else
          return state;
        break;
        case CompanyTypes.UPDATE_COMPANY:
          if (action.update_company) {
              var index = state.companyDetails.findIndex((dets) => { return dets.companyid == action.update_company.company.companyid; });
              if (index >= 0)
              {
                  return {...state, companyDetails: [
                  ...state.companyDetails.slice(0, index),
                  {...state.companyDetails[index],
                    "active": action.update_company.company.active,
                    "addressline1": action.update_company.company.addressline1,
                    "addressline2": action.update_company.company.addressline2,
                    "authorizingfee_creditcard": action.update_company.company.authorizingfee_creditcard,
                    "authorizingfee_member": action.update_company.company.authorizingfee_member,
                    "city": action.update_company.company.city,
                    "companydbaname": action.update_company.company.companydbaname,
                    "companyid": action.update_company.company.companyid,
                    "companylegalname": action.update_company.company.companylegalname,
                    "companylicensetermtype": {
                        "companylicensetermtype": action.update_company.company.companylicensetermtype ? action.update_company.company.companylicensetermtype.companylicensetermtype : null,
                        "companylicensetermtypeid":  action.update_company.company.companylicensetermtype ? action.update_company.company.companylicensetermtype.companylicensetermtypeid : null,
                    },
                    "companytype": {
                        "companytype": action.update_company.company.companytype ? action.update_company.company.companytype.companytype : null,
                        "companytypeid":  action.update_company.company.companytype ? action.update_company.company.companytype.companytypeid : null,
                    },
                    "country": action.update_company.company.country,
                    "creditcardmarkupamount": action.update_company.company.creditcardmarkupamount,
                    "creditcardmarkupunittype": {
                        "companycreditcardmarkupunittype": action.update_company.company.creditcardmarkupunittype ? action.update_company.company.creditcardmarkupunittype.companycreditcardmarkupunittype : null,
                        "companycreditcardmarkupunittypeid": action.update_company.company.creditcardmarkupunittype ? action.update_company.company.creditcardmarkupunittype.companycreditcardmarkupunittypeid : null,
                    },
                    "currency": {
                        "code": action.update_company.company.currency ? action.update_company.company.currency.code : null,
                        "country": action.update_company.company.currency ? action.update_company.company.currency.country : null,
                        "currencyid": action.update_company.company.currency ? action.update_company.company.currency.currencyid : null,
                        "currencyname": action.update_company.company.currency ? action.update_company.company.currency.currencyname : null,
                        "symbol": action.update_company.company.currency ? action.update_company.company.currency.symbol : null
                    },
                    "kwhrate": action.update_company.company.kwhrate,
                    "licenseagreementdate": action.update_company.company.licenseagreementdate,
                    "licensetermvalue": action.update_company.company.licensetermvalue,
                    "locations": action.update_company.company.locations,
                    "logo": action.update_company.company.logo,
                    "networkfee": action.update_company.company.networkfee,
                    "networkfeefrequency": {
                        "companynetworkfeefrequency": action.update_company.company.networkfeefrequency ? action.update_company.company.networkfeefrequency.companynetworkfeefrequency : null,
                        "companynetworkfeefrequencyid": action.update_company.company.networkfeefrequency ? action.update_company.company.networkfeefrequency.companynetworkfeefrequencyid : null
                    },
                    "postalcode": action.update_company.company.postalcode,
                    "primarypointofcontact_user": {
                          "id": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.id : null,
                          "cellProviderId": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.cellProviderId : null,
                          "email": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.email : null,
                          "firstName": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.firstName : null,
                          "lastName": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.lastName : null,
                          "smsPhone": action.update_company.company.primarypointofcontact_user ? action.update_company.company.primarypointofcontact_user.smsPhone : null
                      },
                    "processingfee_creditcard": action.update_company.company.processingfee_creditcard,
                    "processingfee_member": action.update_company.company.processingfee_member,
                    "province": action.update_company.company.province,
                    "reimbursementfrequency": {
                        "companyreimbursementfrequency": action.update_company.company.reimbursementfrequency ? action.update_company.company.reimbursementfrequency.companyreimbursementfrequency : null,
                        "companyreimbursementfrequencyid": action.update_company.company.reimbursementfrequency ? action.update_company.company.reimbursementfrequency.companyreimbursementfrequencyid : null
                    },
                    "reimbursementtype": {
                        "companyreimbursementtype": action.update_company.company.reimbursementtype ? action.update_company.company.reimbursementtype.companyreimbursementtype : null,
                        "companyreimbursementtypeid": action.update_company.company.reimbursementtype ? action.update_company.company.reimbursementtype.companyreimbursementtypeid : null
                    },
                    "state": action.update_company.company.state,
                    "subscriptiontype": {
                        "companysubscriptiontype": action.update_company.company.subscriptiontype ? action.update_company.company.subscriptiontype.companysubscriptiontype : null,
                        "companysubscriptiontypeid": action.update_company.company.subscriptiontype ? action.update_company.company.subscriptiontype.companysubscriptiontypeid : null
                    },
					          "separateBilling": action.update_company.company.separateBilling,																
                    "logoType": action.update_company.company.logoType,
                    "logoWidth": action.update_company.company.logoWidth,
                    "logoHeight": action.update_company.company.logoHeight,
                    "smtpServer": action.update_company.company.smtpServer,
                    "smtpServerPort": action.update_company.company.smtpServerPort,
                    "smtpServerPassword": action.update_company.company.smtpServerPassword,
                    "notificationReturnEmail": action.update_company.company.notificationReturnEmail,
                    "companyURL": action.update_company.company.companyURL,
                    "supportEmail": action.update_company.company.supportEmail,
                    "managingCompanyId": action.update_company.company.managingCompanyId
                  },
                  ...state.companyDetails.slice(index + 1)
                  ]};
              }
          }else
          return state;
          break;
          case CompanyTypes.ADD_COMPANY:
            if (action.add_company) {
              return  Object.assign({}, state, {
                companyDetails : state.companyDetails.concat({
                  "active": action.add_company.company.active,
                  "addressline1": action.add_company.company.addressline1,
                  "addressline2": action.add_company.company.addressline2,
                  "authorizingfee_creditcard": action.add_company.company.authorizingfee_creditcard,
                  "authorizingfee_member": action.add_company.company.authorizingfee_member,
                  "city": action.add_company.company.city,
                  "companydbaname": action.add_company.company.companydbaname,
                  "companyid": action.add_company.company.companyid,
                  "companylegalname": action.add_company.company.companylegalname,
                  "companylicensetermtype": {
                      "companylicensetermtype": action.add_company.company.companylicensetermtype ? action.add_company.company.companylicensetermtype.companylicensetermtype : null,
                      "companylicensetermtypeid":  action.add_company.company.companylicensetermtype ? action.add_company.company.companylicensetermtype.companylicensetermtypeid : null,
                  },
                  "companytype": {
                      "companytype": action.add_company.company.companytype ? action.add_company.company.companytype.companytype : null,
                      "companytypeid":  action.add_company.company.companytype ? action.add_company.company.companytype.companytypeid : null,
                  },
                  "country": action.add_company.company.country,
                  "creditcardmarkupamount": action.add_company.company.creditcardmarkupamount,
                  "creditcardmarkupunittype": {
                      "companycreditcardmarkupunittype": action.add_company.company.creditcardmarkupunittype ? action.add_company.company.creditcardmarkupunittype.companycreditcardmarkupunittype : null,
                      "companycreditcardmarkupunittypeid": action.add_company.company.creditcardmarkupunittype ? action.add_company.company.creditcardmarkupunittype.companycreditcardmarkupunittypeid : null,
                  },
                  "currency": {
                      "code": action.add_company.company.currency ? action.add_company.company.currency.code : null,
                      "country": action.add_company.company.currency ? action.add_company.company.currency.country : null,
                      "currencyid": action.add_company.company.currency ? action.add_company.company.currency.currencyid : null,
                      "currencyname": action.add_company.company.currency ? action.add_company.company.currency.currencyname : null,
                      "symbol": action.add_company.company.currency ? action.add_company.company.currency.symbol : null
                  },
                  "kwhrate": action.add_company.company.kwhrate,
                  "licenseagreementdate": action.add_company.company.licenseagreementdate,
                  "licensetermvalue": action.add_company.company.licensetermvalue,
                  "locations": action.add_company.company.locations,
                  "logo": action.add_company.company.logo,
                  "networkfee": action.add_company.company.networkfee,
                  "networkfeefrequency": {
                      "companynetworkfeefrequency": action.add_company.company.networkfeefrequency ? action.add_company.company.networkfeefrequency.companynetworkfeefrequency : null,
                      "companynetworkfeefrequencyid": action.add_company.company.networkfeefrequency ? action.add_company.company.networkfeefrequency.companynetworkfeefrequencyid : null
                  },
                  "postalcode": action.add_company.company.postalcode,
                  "primarypointofcontact_user": {
                        "id": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.id : null,
                        "cellProviderId": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.cellProviderId : null,
                        "email": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.email : null,
                        "firstName": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.firstName : null,
                        "lastName": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.lastName : null,
                        "smsPhone": action.add_company.company.primarypointofcontact_user ? action.add_company.company.primarypointofcontact_user.smsPhone : null
                    },
                  "processingfee_creditcard": action.add_company.company.processingfee_creditcard,
                  "processingfee_member": action.add_company.company.processingfee_member,
                  "province": action.add_company.company.province,
                  "reimbursementfrequency": {
                      "companyreimbursementfrequency": action.add_company.company.reimbursementfrequency ? action.add_company.company.reimbursementfrequency.companyreimbursementfrequency: null,
                      "companyreimbursementfrequencyid": action.add_company.company.reimbursementfrequency? action.add_company.company.reimbursementfrequency.companyreimbursementfrequencyid : null
                  },
                  "reimbursementtype": {
                      "companyreimbursementtype": action.add_company.company.reimbursementtype ? action.add_company.company.reimbursementtype.companyreimbursementtype : null,
                      "companyreimbursementtypeid": action.add_company.company.reimbursementtype ? action.add_company.company.reimbursementtype.companyreimbursementtypeid : null
                  },
                  "state": action.add_company.company.state,
                  "subscriptiontype": {
                      "companysubscriptiontype": action.add_company.company.subscriptiontype ? action.add_company.company.subscriptiontype.companysubscriptiontype : null,
                      "companysubscriptiontypeid": action.add_company.company.subscriptiontype ? action.add_company.company.subscriptiontype.companysubscriptiontypeid : null
                  },
                  "logoType": action.add_company.company.logoType,
                  "logoWidth": action.add_company.company.logoWidth,
                  "logoHeight": action.add_company.company.logoHeight,
                  "smtpServer": action.add_company.company.smtpServer,
                  "smtpServerPort": action.add_company.company.smtpServerPort,
                  "smtpServerPassword": action.add_company.company.smtpServerPassword,
                  "notificationReturnEmail": action.add_company.company.notificationReturnEmail,
                  "companyURL": action.add_company.company.companyURL,
                  "supportEmail": action.add_company.company.supportEmail,
                  "managingCompanyId": action.add_company.company.managingCompanyId                  
                        }) });
            }else
            return state;
            break;
            case CompanyTypes.USER_LIST:  
              if (action.user_list) {
                return Object.assign({}, state, {
                  userList: action.user_list,
                  lastUpdatedCompanyUsers: Date.now()
                });
              }else
                return state;
                break;
      default: {
        return state;
      }
    }
  }
}
