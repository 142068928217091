//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as Tags from "../actiontypes/Tags";

export function TagReducer(state = { tag_list: [], defaultChargingProfiles: []  }, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Tags.TAG_LIST:
        if (action.tag_list.length >= state.tag_list.length) {
          return {
            tag_list: action.tag_list,
          };
        } else {
          return state;
        }

      case Tags.ADD_TAG:
        if (action.add_tag) {

          if(action.add_tag.EnergyManaged && action.add_tag.TagTypeId == 2 && action.add_tag.TagList && action.add_tag.TagList.length > 0)
          {
            
            for(let i = 0; i < action.add_tag.TagList.length; i++)
            {
              state.defaultChargingProfiles.push(action.add_tag.TagList[i]);
            }
          }
          
          return {
            ...state,
            tag_list: state.tag_list.concat({
              ...action.add_tag}),
            defaultChargingProfiles: state.defaultChargingProfiles
            };
        } else return state;
      case Tags.DELETE_TAG:
        if (action.delete_tag) {
          var index = state.tag_list.findIndex((tags) => {
            return tags.TagId == action.delete_tag;
          });
          if (index >= 0) {
            if(state.tag_list[index].EnergyManaged && state.tag_list[index].TagTypeId == 2 && state.tag_list[index].TagList && state.tag_list[index].TagList.length > 0)
            {
              for(let i = 0; i < state.tag_list[index].TagList.length; i++)
              {
                let idx = state.defaultChargingProfiles.indexOf(state.tag_list[index].TagList[i])
                if(idx > -1)
                  state.defaultChargingProfiles.splice(idx, 1);
              }
            }
            return  {
              ...state,
              tag_list: [...state.tag_list.slice(0, index),
              ...state.tag_list.slice(index+1)],
              defaultChargingProfiles: state.defaultChargingProfiles
            };
          } else return state;
        } else return state;

      case Tags.UPDATE_TAG:
        if (action.update_tag) {
          var index = state.tag_list.findIndex((tags) => { return tags.TagId == action.update_tag.TagId; });

          if (index >= 0) {
            //Manipulate defaultChargingProfiles
            if(action.update_tag.EnergyManaged && action.update_tag.TagTypeId == 2)
            {
              var is_same = (state.tag_list[index].TagList.length == action.update_tag.TagList.length) && state.tag_list[index].TagList.every(function(element, index) {
                return element === action.update_tag.TagList[index]; 
              }); 

              if(is_same) //when adding sub stations in energy managed tag
              {
                for(let i = 0; i < action.update_tag.TagList.length; i++)
                {
                  if(state.defaultChargingProfiles.indexOf(action.update_tag.TagList[i]) < 0) //When adding stations
                    state.defaultChargingProfiles.push(action.update_tag.TagList[i]);
                }
              }
              else //when deleting sub stations in energy managed tag
              {
                if(state.tag_list[index].TagList && state.tag_list[index].TagList.length > 0)
                {
                  for(let i = 0; i < state.tag_list[index].TagList.length; i++)
                  {
                    if(action.update_tag.TagList.indexOf( state.tag_list[index].TagList[i]) < 0) //When deleting stations
                    {
                      let idx = state.defaultChargingProfiles.indexOf(state.tag_list[index].TagList[i]);
                      if(idx > -1)
                        state.defaultChargingProfiles.splice(idx, 1);
                    }
                  }
                }
              }
            }
            return {
              ...state, 
              tag_list: [
                ...state.tag_list.slice(0, index),
                {
                  ...state.tag_list[index],
                  ...action.update_tag
                },
                ...state.tag_list.slice(index + 1)
              ],
              defaultChargingProfiles: state.defaultChargingProfiles
            }
          }
          else {
            return state;
          }
        }
      case Tags.DefaultChargingProfiles:
        if (action.defaultChargingProfiles)
        {
          return Object.assign({}, state, {
          defaultChargingProfiles: action.defaultChargingProfiles,
          });
        }
        else {
          return state;
        }
        
        
        break;
      default:
        return state;
    }
  }
}
