export const RATE_STRUCTURE_LIST = 'RATE_STRUCTURE_LIST';
//export const RATE_STRUCTURE_COUNT = 'RATE_STRUCTURE_COUNT';
export const ADD_RATE_STRUCTURE = 'ADD_RATE_STRUCTURE';
export const UPDATE_RATE_STRUCTURE = 'UPDATE_RATE_STRUCTURE';
//export const RATE_STRUCTURE_LOAD = 'RATE_STRUCTURE_LOAD';
export const USAGE_DATA = 'USAGE_DATA';
export const USAGE_DATA_LIST = 'USAGE_DATA_LIST';
export const GET_RATE_PLAN = 'GET_RATE_PLAN';

export const FLEET_RATE_STRUCTURE_LIST = 'FLEET_RATE_STRUCTURE_LIST';
export const FLEET_ADD_RATE_STRUCTURE = 'FLEET_ADD_RATE_STRUCTURE';
export const FLEET_UPDATE_RATE_STRUCTURE = 'FLEET_UPDATE_RATE_STRUCTURE';