//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import { combineReducers } from "redux";
import { WebSessionReducer } from "./WebSession";
import { LocationsReducer, LocationsAnalyticsReducer } from "./Locations";
import { StationsAnalyticsReducer } from "./Stations";
import { SupportIssueReducer } from "./supportissues";
import { EnergyGraphReducer } from "./EnergyGraphs";
import { FleetVehiclesReducer, FleetVehiclesAnalyticsReducer } from "./FleetVehicles";
import { FleetTokensReducer } from "./FleetTokens";
import { CompanyGroupsReducer } from "./CompanyGroups";
import {
  SessionGraphReducer,
  SessionListReducer,
  OpenSessionReducer,
} from "./Session";
import {DateRangeReducer} from "./daterange"
import { DriversAnalyticsReducer } from "./driver";
import { RateStructureReducer } from "./RateStructure";
import { CompanyAnalyticsReducer } from "./company";
import { AccountReducer } from "./Account";
import { UsageReportReducer } from "./UsageReportReducer";
import { TagReducer } from "./Tags";
import { FilterReducer} from "./SortFilter";
import { DashboardReducer } from './Dashboard';
import { FleetChargerStatusReducer } from './FleetChargerStatus';
import {CallSupportTicketReducer} from "./CallSupportTicket";
import { reducer as formReducer } from 'redux-form';

let rootReducer = combineReducers({
  session: WebSessionReducer,
  location: LocationsReducer,
  dateRange: DateRangeReducer,
  locationAnalytics: LocationsAnalyticsReducer,
  support: SupportIssueReducer,
  energyGraph: EnergyGraphReducer,
  sessionGraph: SessionGraphReducer,
  sessionList: SessionListReducer,
  stationAnalytics: StationsAnalyticsReducer,
  driverAnalytics: DriversAnalyticsReducer,
  rateStructuresList: RateStructureReducer,
  companyAnalytics: CompanyAnalyticsReducer,
  accountInfo: AccountReducer,
  UsageReport: UsageReportReducer,
  tags: TagReducer,
  openSessionList: OpenSessionReducer,
  activeFilters: FilterReducer,
  dashboard: DashboardReducer,
  fleetChargerStatus: FleetChargerStatusReducer,
  fleetVehicle: FleetVehiclesReducer,
  fleetVehicleAnalytics: FleetVehiclesAnalyticsReducer,
  fleetToken: FleetTokensReducer,
  companyGroup: CompanyGroupsReducer,
  callSupportTicket: CallSupportTicketReducer,
  form: formReducer
});

export default rootReducer;
