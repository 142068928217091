//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React from "react";
import {createRoot} from "react-dom/client";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import store from "../src/helpers/store";
import LoginContainer from "./redux/container/LoginContainer";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import * as settings from "../src/helpers/globals";
import axios from "axios";
import './assets/fonts/fonts.scss';

const history = createBrowserHistory();
axios.defaults.baseURL = settings.baseURL;

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={LoginContainer} />
    </Router>
  </Provider>,
);

serviceWorker.unregister();
