//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import axios from "axios";

export const LogoutSession = (authToken, dispatch/*, clearStore*/) => {
  axios({
    method: "delete",
    url: "Session.svc/Session/" + authToken,
  })
    .then((response) => {
      dispatch(response);
    })
    .catch((error) => {
      console.log("Error occured signing out of sessions: " + error);
    });

  if (caches) {
    console.log("Clearing cache");
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

export const LoginSession = (postReqUrl, data, fnLoginResponseHandler, fnLoginErrorHandler) => {
  axios({
       method:'post',
       url:postReqUrl,
       data: data
   })
   .then(response => fnLoginResponseHandler(response))
   .catch(err => fnLoginErrorHandler(err));
};

export const GetQRCodeUrl = (authToken,dispatch) => {
  axios({
    method: "get",
    url: "Session.svc/Session/mfaQRcode",
    headers: {
      'Authorization': authToken
    }
  })
  .then((response) => {
    dispatch(response.data);
  })
  .catch((error) => {
    console.log("Error occured retrieving QR Code Url " + error);
  });
};

export const IsOtpSmsSent = (authToken, data, dispatch) => {
  axios({
    method: "post",
    url: "Session.svc/Session/sendSmsOtp",
    headers: {
      'Authorization': authToken
    },
    data: data
  })
  .then((response) => {
    dispatch(response.data);
  })
  .catch((error) => {
    console.log("Error occured sending SMS OTP" + error);
  });
};