import React, { Component } from 'react';
// import oplogo from '../../assets/images/OpConnectLogo.png';
import oplogo from "../../assets/webp/OpConnectLogo.webp";
import { Link} from "react-router-dom";
import {Row, Col} from 'react-bootstrap';
import "./Footer.scss";
import { Trans } from 'react-i18next';

export default class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="d-sm-flex  justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block footerText"><Trans>© 2022</Trans> <a href="https://opconnect-ev.com" target="_blank" rel="noopener noreferrer">OpConnect</a> <Trans> All rights reserved.</Trans></span>
          <div className="d-sm-flex justify-content-evenly footerText">
            {/* <Link className="pr-1 navLink" to="/termsCondition">
            <Trans>About</Trans>
            </Link> */}
            <Link className="pr-1 navLink footerText" to="/contact">
            <Trans className="footerText">Contact</Trans>
            </Link>
            <Link className="pr-1 navLink footerText" to="/termsCondition">
            <Trans>Terms Conditions</Trans>
            </Link>
   
          </div>
          <div className="d-sm-flex justify-content-right">
            <Row>
            <span className="d-sm-flex pr-1 text-muted text-left text-sm-left d-block d-sm-inline-block footerText">
            {(this.props.companyLogo && this.props.companyLogoType)
          ?
            <Row>&nbsp;</Row>
          : 
            <Row className="top30"><Col md={8} ></Col><span>Powered by <img src={oplogo} alt="oplogo" className="logo" /></span></Row>
          }</span>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}
