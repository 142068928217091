//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import groupBy from 'lodash/groupBy';
import moment from "moment";

import * as Location from "../actiontypes/Locations";
import * as Station from "../actiontypes/Station";

// Location and station data which will only need to be updated if add/edit/delete occurs
export function LocationsReducer(
  state = {
    // locations with nested stations list to show association, 
    // TODO: update nested station list to array of station ids or array positions in station array
    locations: [], 
    stations: [], // full list of stations
    locationChargingSchedule: {}
  },
  action
) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Location.UPDATE_LOCATIONS:
        // Appends obj values to hold analytics values after received
        let updatedStations = [];
        if (action.locations && action.locations.length > 0) 
        {
          action.locations.forEach(loc => {
            if (loc.ChargingStations && loc.ChargingStations.length > 0) {
              loc.ChargingStations.forEach(sta => {
                updatedStations.push(sta);
              });
            }
          });

          return { ...state, locations: action.locations, stations: updatedStations};
        } 
        else 
        return state;
      case Location.ADD_LOCATION:
        if (action.add_location) {
          return Object.assign({}, state, {
            locations: state.locations.concat({
          /*     ID: action.add_location.ID,
              Address: action.add_location.Address,
              City: action.add_location.City,
              Description: action.add_location.Description,
              Latitude: action.add_location.Latitude,
              Longitude: action.add_location.Longitude,
              Country: action.add_location.Country,
              State: action.add_location.State,
              Zip: action.add_location.Zip,
              hidden: action.add_location.hidden,
              privateAccess: action.add_location.privateAccess,
              reservable: action.add_location.reservable,
              ChargingStations: [], */
              ...action.add_location,
              ChargingStations: [],
            }),
          });
        } else return state;
      case Location.EDIT_LOCATION:
        var index = state.locations.findIndex((dets) => {
          return dets.ID === action.edit_location.ID;
        });

        if (index >= 0) {
          return {
            ...state,
            locations: [
              ...state.locations.slice(0, index),
              {
                ...state.locations[index],
                ...action.edit_location,
              },
              ...state.locations.slice(index + 1),
            ],
          };
        } else {
          return state;
        }
        case Location.TOGGLE_LOCATION_PRIVATE:
          var index = state.locations.findIndex((dets) => {
            return dets.ID == action.locationID;
          });
  
          if (index >= 0) {
            var privateAccessToSet = !state.locations[index].privateAccess;
            var updatedChargingStations = state.locations[index].ChargingStations.map( (sta) => { return Object.assign({}, sta, { privateAccess: privateAccessToSet})});
            return {
              ...state,
              locations: [
                ...state.locations.slice(0, index),
                {
                  ...state.locations[index],
                  privateAccess: privateAccessToSet,
                  ChargingStations: updatedChargingStations                                      
                },
                ...state.locations.slice(index + 1),
              ],
            };
          } else {
            return state;
          }
      case Station.ADD_STATION:
        if (action.add_station) {
          var indexAdd = state.locations.findIndex((dets) => {
            return dets.ID === action.add_station.stationLocationId;
          });
          if (indexAdd >= 0) {
            return {
              ...state,
              locations: [
                ...state.locations.slice(0, indexAdd),
                {
                  ...state.locations[indexAdd],
                  ChargingStations: [
                    ...state.locations[indexAdd].ChargingStations.concat({
                      ...action.add_station,
                    }),
                  ],
                },
                ...state.locations.slice(indexAdd + 1),
              ],
              stations: state.stations.concat({
                ...action.add_station,
              })
            };
          }
          else
          {
            return {
              ...state,
              stations: state.stations.concat({
                ...action.add_station,
              })
            }
          }
        } 
        else 
          return state;
      case Station.EDIT_STATION:
        if (action.edit_station) {

          // First update full station list
          var indexSta = state.stations.findIndex((sta) => {
            return sta.ID === action.edit_station.ID;
          });
          let updatedStations = [];
          if (indexSta >= 0) {           
            updatedStations = [
                ...state.stations.slice(0, indexSta),
                {
                  ...state.stations[indexSta],
                  ...action.edit_station,
                },
                ...state.stations.slice(indexSta + 1),
              ];            
          } 

          // Then update location array
          var indexEdit = state.locations.findIndex((dets) => {
            return dets.ID === action.edit_station.stationLocationId;
          });

          if (indexEdit >= 0) {
            var indexEdit2 = state.locations[
              indexEdit
            ].ChargingStations.findIndex((sta) => {
              return sta.ID === action.edit_station.ID;
            });

            if (indexEdit2 >= 0) {
              // Verifies station is still assigned to same location it was prior to edit
              return {
                ...state,
                locations: [
                  ...state.locations.slice(0, indexEdit),
                  {
                    ...state.locations[indexEdit],
                    ChargingStations: [
                      ...state.locations[indexEdit].ChargingStations.slice(
                        0,
                        indexEdit2
                      ),
                      {
                        ...state.locations[indexEdit].ChargingStations[
                          indexEdit2
                        ],
                        ...action.edit_station,
                      },
                      ...state.locations[indexEdit].ChargingStations.slice(
                        indexEdit2 + 1
                      ),
                    ],
                  },
                  ...state.locations.slice(indexEdit + 1),
                ],
                stations: updatedStations
              };
            } // Indicates station's location has been updated
            else {
              let locationsUpdated = [];
              let oldLocationArrayIndex = -1;
              let oldStationArrayIndex = -1;
              // Find existing station record, slice station record out and concatenate it to location object at index1
              for (var i = 0; i < state.locations.length; ++i) {
                var thisLocation = state.locations[i];
                var stationFound = false;
                for (var j = 0; j < thisLocation.ChargingStations.length; ++j) {
                  if (
                    thisLocation.ChargingStations[j].ID ===
                    action.edit_station.ID
                  ) {
                    oldLocationArrayIndex = i;
                    oldStationArrayIndex = j;
                    stationFound = true;
                    break;
                  }
                }
                if (stationFound) break;
              }
              // Add edited station to new location
              locationsUpdated = [
                ...state.locations.slice(0, indexEdit),
                {
                  ...state.locations[indexEdit],
                  ChargingStations: [
                    ...state.locations[indexEdit].ChargingStations.concat({
                      ...action.edit_station,
                    }),
                  ],
                },
                ...state.locations.slice(indexEdit + 1),
              ];
              // Remove old pre-edited station from previous location.
              if (oldLocationArrayIndex >= 0) {
                locationsUpdated[oldLocationArrayIndex].ChargingStations.splice(
                  oldStationArrayIndex,
                  1
                );
              }
              return { ...state, locations: locationsUpdated, stations: updatedStations };
            }
          }
        }
        return state;
      case Location.SET_LOCATION_CHARGING_SCHEDULE:
        if (action.locationChargingSchedule)
        {
          return {...state, locationChargingSchedule: action.locationChargingSchedule};
        }
        else
          return state;

      default: {
        return state;
      }
    }
  }
}

//Location data which will need to be refreshed on date range change or if stale
export function LocationsAnalyticsReducer(
  state = {
    analytics: [],
    locationDetails: [],
    locationDetailSummary: [],
    lastUpdatedAnalytics: null,
  },
  action
) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Location.UPDATE_LOCATION_ANALYTICS:
        if (action.locationAnalytics && action.locationAnalytics.length > 0) {
          // Convert to local time zone all analytic date time values
          if (action.periodType === "day") {
            for (var i = 0; i < action.locationAnalytics.length; ++i) {
              action.locationAnalytics[i].date = moment
                .utc(action.locationAnalytics[i].date)
                .local()
                .format("YYYY-MM-DD HH:mm");
            }
          } else if (action.periodType !== "day") {
            //Update date to locale date value before storing.
            for (var i = 0; i < action.locationAnalytics.length; ++i) {
              action.locationAnalytics[i].date = moment
                .utc(action.locationAnalytics[i].date)
                .local()
                .format("YYYY-MM-DD");
            }
          }

          let groupedLoc = groupBy(action.locationAnalytics, (x) => x.id);

          var updatedLocations = [];
          for (var value in groupedLoc) {
            var kWhTotal = groupedLoc[value].reduce((kWhTotal, sa) => {
              return (kWhTotal += Number(sa.totals.kWh));
            }, 0);

            var sessionsTotal = groupedLoc[value].reduce(
              (sessionsTotal, sa) => {
                return (sessionsTotal += Number(sa.totals.chargingSessions));
              },
              0
            );

            var revenueTotal = groupedLoc[value].reduce((revenueTotal, sa) => {
              return (revenueTotal += Number(sa.totals.revenue));
            }, 0);

            var durationTotal = groupedLoc[value].reduce(
              (durationTotal, sa) => {
                if (sa.totals.duration && sa.totals.duration.length > 0) {
                  return (durationTotal += Number(
                    moment.duration(sa.totals.duration).asHours()
                  ));
                } else return 0;
              },
              0
            );

            updatedLocations.push({
              ID: value,
              kWh: kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.0,
              sessions: sessionsTotal,
              revenue: revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.0,
              duration: durationTotal
                ? parseFloat(durationTotal.toFixed(2))
                : 0.0,
              analytics: groupedLoc[value],
            });
          }

          return Object.assign({}, state, {
            analytics: action.locationAnalytics,
            locationDetails: updatedLocations,
            lastUpdatedAnalytics: Date.now(),
          });
        } else
          return Object.assign({}, state, {
            analytics: [],
            locationDetails: [],
            lastUpdatedAnalytics: Date.now(),
          });
          case Location.UPDATE_LOCATION_DETAILS:
            if (action.data) {
                const updatedDetails = {
                    ID: action.data.details.id,
                    chargingNow: action.data.details.totalChargingNow,
                    online: action.data.details.totalOnlineNow,
                    creditcardSessions: action.data.details.totalCreditCardSessions,
                    memberSessions: action.data.details.totalMemberSessions,
                    unknownDrivers: action.data.details.totalUnknownDrivers,
                    registeredDrivers: action.data.details.totalRegisteredDrivers,
                    sessions: action.data.details.totalSessions
                };

                const locationId = +action.data.location; // Convert action.data.location into an integer to compare
                const index = state.locationDetailSummary.findIndex(dets => dets.ID === locationId);

                // Create new object if there are changes
                if (index >= 0) {
                    // Check if the update is different to prevent state updates
                    if (state.locationDetailSummary[index] !== updatedDetails) {
                        const newLocationDetailSummary = [...state.locationDetailSummary];
                        newLocationDetailSummary[index] = updatedDetails;
                        return {
                            ...state,
                            locationDetailSummary: newLocationDetailSummary,
                        };
                    }
                } else {
                    return {
                        ...state,
                        locationDetailSummary: [...state.locationDetailSummary, updatedDetails],
                    };
                }
            }
            return state;

        default:
            return state;     
    }
  }
}
