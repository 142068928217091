//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as SupportIssueTypes from "../actiontypes/supportissues";
// action: the action you're performing to change the state  (type, any other data you want.)
// The current state
const initialState = {
  supportIssues: [],
  supportIssueMessages: [],
  supportIssuesLength: 0,
  updateDateTime: null,
};

export function SupportIssueReducer(state = initialState, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case SupportIssueTypes.LOAD_SUPPORT_ISSUES:
        return Object.assign({}, state, {
          supportIssues: action.supportIssues,
          supportIssuesLength: action.supportIssuesLength,
          updateDateTime: action.updateDateTime,
        });
      case SupportIssueTypes.LOAD_SUPPORT_ISSUE_MESSAGES:
        return Object.assign({}, state, {
          supportIssueMessages: action.supportIssueMessages,
        });
      case SupportIssueTypes.ADD_SUPPORT_ISSUE:
        if (action.add_support_issue) {
          var value = 0;
          value = state.supportIssuesLength + 1;
          for ( let i = state.supportIssues.length - 1; i > state.supportIssues.length - 10; i--) {
            if (state.supportIssues[i].supportlogid == action.add_support_issue.supportlogid) {
              return Object.assign({}, state, {
                supportIssues: state.supportIssues,
                updateDateTime: Date.now(),
                supportIssuesLength: state.supportIssuesLength,
              });
            }
          }
          return Object.assign({}, state, {
            supportIssues: state.supportIssues.concat({
              isOutOfService: action.add_support_issue.isOutOfService,
              datetimeEntered: action.add_support_issue.datetimeEntered,
              description: action.add_support_issue.description,
              hoursticketopen: action.add_support_issue.hoursticketopen,
              issueSource: action.add_support_issue.issueSource,
              issuetypedescription:
                action.add_support_issue.issuetypedescription,
              locationId: action.add_support_issue.locationId,
              rebootrequired: action.add_support_issue.rebootrequired,
              reportedByPhone: action.add_support_issue.reportedByPhone,
              reportedbyname: action.add_support_issue.reportedbyname,
              rootcause: action.add_support_issue.rootcause,
              severity: action.add_support_issue.severity,
              solution: action.add_support_issue.solution,
              stationId: action.add_support_issue.stationId,
              supportType: {
                IssueTypeDescription:
                  action.add_support_issue.IssueTypeDescription,
                StationSupportIssueTypeId:
                  action.add_support_issue.StationSupportIssueTypeId,
              },
              supportlogid: action.add_support_issue.supportlogid,
              ticketstatus: action.add_support_issue.ticketstatus,
              userID: action.add_support_issue.userID,
            }),
            updateDateTime: Date.now(),
            supportIssuesLength: value,
          });
        } else {
          return state;
        }
      case SupportIssueTypes.ADD_SUPPORT_MESSAGE:
        if (action.add_support_message.postTypeId === 1) {
          return Object.assign({}, state, {
            supportIssueMessages: state.supportIssueMessages.concat({
              content: action.add_support_message.content,
              description: action.add_support_message.description,
              createDate: action.add_support_message.createDate,
              dateTimeEntered: action.add_support_message.dateTimeEntered,
              firstName: action.add_support_message.firstName,
              reportedByName: action.add_support_message.reportedByName,
              postTypeId: action.add_support_message.postTypeId,
            }),
          });
        } else if (action.add_support_message.postTypeId === 2) {
          var index = state.supportIssues.findIndex((dets) => {
            return (
              dets.supportlogid ===
              action.add_support_message.stationSupportLogId
            );
          });
          if (index >= 0) {
            return {
              ...state,
              supportIssues: [
                ...state.supportIssues.slice(0, index),
                {
                  ...state.supportIssues[index],
                  ticketstatus: "Closed",
                  rootcause: action.add_support_message.content,
                },
                ...state.supportIssues.slice(index + 1),
              ],
            };
          }
        } else if (action.add_support_message.postTypeId === 3) {
          var indexAdd3 = state.supportIssues.findIndex((dets) => {
            return (
              dets.supportlogid ===
              action.add_support_message.stationSupportLogId
            );
          });
          if (indexAdd3 >= 0) {
            return {
              ...state,
              supportIssues: [
                ...state.supportIssues.slice(0, indexAdd3),
                {
                  ...state.supportIssues[indexAdd3],
                  ticketstatus: "Closed",
                  solution: action.add_support_message.content,
                },
                ...state.supportIssues.slice(indexAdd3 + 1),
              ],
            };
          }
        } else if (action.add_support_message.postTypeId === 4) {
          var indexAdd = state.supportIssues.findIndex((dets) => {
            return (
              dets.supportlogid ===
              action.add_support_message.stationSupportLogId
            );
          });
          if (indexAdd >= 0) {
            return {
              ...state,
              supportIssues: [
                ...state.supportIssues.slice(0, indexAdd),
                {
                  ...state.supportIssues[indexAdd],
                  ticketstatus: "Closed",
                  solution: action.add_support_message.content,
                  rootcause: action.add_support_message.content,
                },
                ...state.supportIssues.slice(indexAdd + 1),
              ],
            };
          }
        }
        break;
      default: {
        return state;
      }
    }
  }
}
