export const SORT_BY_ALPHABET = "SORT_BY_ALPHABET";
export const SORT_BY_ENERGY = "SORT_BY_ENERGY";
export const SORT_BY_REVENUE = "SORT_BY_REVENUE";
export const SORT_BY_SESSIONS = "SORT_BY_SESSIONS";
export const SORT_BY_DURATION = "SORT_BY_DURATION";
export const FILTER_BY_VALUE = "FILTER_BY_VALUE";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const LOAD_FILTER_LIST_ITEMS = 'LOAD_FILTER_LIST_ITEMS';
export const LOAD_FILTER_LIST_ITEMS_WITH_TYPE = 'LOAD_FILTER_LIST_ITEMS_WITH_TYPE';
export const CLEAR_DASHBOARD_FILTERS = 'CLEAR_DASHBOARD_FILTERS';

export const TOGGLE_ANALYTICS_ITEM = 'TOGGLE_ANALYTICS_ITEM';