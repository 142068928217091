//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import moment from "moment";

import * as RateStructureTypes from "../actiontypes/RateStructure";

export function RateStructureReducer(
  state = { rate_structure_list: [], fleet_rate_structure_list: [] },
  action
) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case RateStructureTypes.RATE_STRUCTURE_LIST:
        if (
          action.rate_structure_list.length >= state.rate_structure_list.length
        ) {
          return {
            ...state,
            rate_structure_list: action.rate_structure_list,
          };
        } else {
          return state;
        }
      // case RateStructureTypes.RATE_STRUCTURE_COUNT:
      //   return {
      //     rate_structure_list: action.rate_structure_count,
      //   };
      case RateStructureTypes.ADD_RATE_STRUCTURE:
        if (action.add_rate_structure) {
          return Object.assign({}, state, {
            rate_structure_list: state.rate_structure_list.concat({
              id: action.add_rate_structure.id,
              byChargingTime: action.add_rate_structure.byChargingTime,
              byConnectedTime: action.add_rate_structure.byConnectedTime,
              stationLocationRateStructure: action.add_rate_structure
                .stationLocationRateStructure
                ? action.add_rate_structure.stationLocationRateStructure
                : [],
              createdByUserID: action.add_rate_structure.createdByUserID,
              createdDate: action.add_rate_structure.createdDate,
              creditCardPremium: action.add_rate_structure.creditCardPremium,
              effectiveDateTime: action.add_rate_structure.effectiveDateTime,
              expirationDateTime: action.add_rate_structure.expirationDateTime,
              lastModifiedDate: action.add_rate_structure.lastModifiedDate,
              minimumPurchasableUnits1:
                action.add_rate_structure.minimumPurchasableUnits1,
              minimumPurchasableUnits2:
                action.add_rate_structure.minimumPurchasableUnits2,
              name: action.add_rate_structure.name,
              notes: action.add_rate_structure.notes,
              partialUnitCharge1: action.add_rate_structure.partialUnitCharge1,
              partialUnitCharge2: action.add_rate_structure.partialUnitCharge2,
              rateFactor: action.add_rate_structure.rateFactor,
              realTimeOverride: action.add_rate_structure.realTimeOverride,
              timeOfDayTiers: [
                {
                  dayOfWeekId: action.add_rate_structure.timeOfDayTiers
                    .dayOfWeekId
                    ? action.add_rate_structure.timeOfDayTiers.dayOfWeekId
                    : null,
                  price: action.add_rate_structure.timeOfDayTiers.price
                    ? action.add_rate_structure.timeOfDayTiers.price
                    : null,
                  start: action.add_rate_structure.timeOfDayTiers.start
                    ? action.add_rate_structure.timeOfDayTiers.start
                    : null,
                  stop: action.add_rate_structure.timeOfDayTiers.stop
                    ? action.add_rate_structure.timeOfDayTiers.stop
                    : null,
                  unitId: action.add_rate_structure.timeOfDayTiers.unitId
                    ? action.add_rate_structure.timeOfDayTiers.unitId
                    : null,
                },
              ],
              timeSpanTiers: [
                {
                  id: action.add_rate_structure.timeSpanTiers.id
                    ? action.add_rate_structure.timeSpanTiers.id
                    : null,
                  order: action.add_rate_structure.timeSpanTiers.order
                    ? action.add_rate_structure.timeSpanTiers.order
                    : null,
                  price: action.add_rate_structure.timeSpanTiers.price
                    ? action.add_rate_structure.timeSpanTiers.price
                    : null,
                  unitId: action.add_rate_structure.timeSpanTiers.unitId
                    ? action.add_rate_structure.timeSpanTiers.unitId
                    : null,
                  valueLength: action.add_rate_structure.timeSpanTiers
                    .valueLength
                    ? action.add_rate_structure.timeSpanTiers.valueLength
                    : null,
                },
              ],
              unitId1: action.add_rate_structure.unitId1,
              unitId2: action.add_rate_structure.unitId2,
              idleFeeSet: action.add_rate_structure.idleFeeSet,
              idleFee: action.add_rate_structure.idleFee,
              idleGracePeriodMins:
                action.add_rate_structure.idleGracePeriodMins,
              idleFeePeriodStart: action.add_rate_structure.idleFeePeriodStart,
              idleFeePeriodStop: action.add_rate_structure.idleFeePeriodStop,
              idleFeeUnitId: action.add_rate_structure.idleFeeUnitId,
              maxParkingUnitId: action.add_rate_structure.maxParkingUnitId,
              maxParkingLimit: action.add_rate_structure.maxParkingLimit,
              maxParkingTime: action.add_rate_structure.maxParkingTime,
              maxParkingTimePenalty:
                action.add_rate_structure.maxParkingTimePenalty,
              maxParkingPeriodStart:
                action.add_rate_structure.maxParkingPeriodStart,
              maxParkingPeriodStop:
                action.add_rate_structure.maxParkingPeriodStop,
              taxLabel: action.add_rate_structure.taxLabel,
              taxPercentageRate: action.add_rate_structure.taxPercentageRate
            }),
          });
        } else return state;
      case RateStructureTypes.UPDATE_RATE_STRUCTURE:
        if (action.update_rate_structure) {
          let newStructureList = state.rate_structure_list;

          if (action.update_rate_structure.updatedRateStructure) {
            newStructureList = state.rate_structure_list.concat({
              id: action.update_rate_structure.updatedRateStructure.id,
              byChargingTime:
                action.update_rate_structure.updatedRateStructure
                  .byChargingTime,
              byConnectedTime:
                action.update_rate_structure.updatedRateStructure
                  .byConnectedTime,
              stationLocationRateStructure: action.update_rate_structure
                .updatedRateStructure.stationLocationRateStructure
                ? action.update_rate_structure.updatedRateStructure
                    .stationLocationRateStructure
                : [],
              createdByUserID:
                action.update_rate_structure.updatedRateStructure
                  .createdByUserID,
              createdDate:
                action.update_rate_structure.updatedRateStructure.createdDate,
              creditCardPremium:
                action.update_rate_structure.updatedRateStructure
                  .creditCardPremium,
              effectiveDateTime:
                action.update_rate_structure.updatedRateStructure
                  .effectiveDateTime,
              expirationDateTime:
                action.update_rate_structure.updatedRateStructure
                  .expirationDateTime,
              lastModifiedDate:
                action.update_rate_structure.updatedRateStructure
                  .lastModifiedDate,
              minimumPurchasableUnits1:
                action.update_rate_structure.updatedRateStructure
                  .minimumPurchasableUnits1,
              minimumPurchasableUnits2:
                action.update_rate_structure.updatedRateStructure
                  .minimumPurchasableUnits2,
              name: action.update_rate_structure.updatedRateStructure.name,
              notes: action.update_rate_structure.updatedRateStructure.notes,
              partialUnitCharge1:
                action.update_rate_structure.updatedRateStructure
                  .partialUnitCharge1,
              partialUnitCharge2:
                action.update_rate_structure.updatedRateStructure
                  .partialUnitCharge2,
              rateFactor:
                action.update_rate_structure.updatedRateStructure.rateFactor,
              realTimeOverride:
                action.update_rate_structure.updatedRateStructure
                  .realTimeOverride,
              timeOfDayTiers: [
                {
                  dayOfWeekId: action.update_rate_structure.updatedRateStructure
                    .timeOfDayTiers.dayOfWeekId
                    ? action.update_rate_structure.updatedRateStructure
                        .timeOfDayTiers.dayOfWeekId
                    : null,
                  price: action.update_rate_structure.updatedRateStructure
                    .timeOfDayTiers.price
                    ? action.update_rate_structure.updatedRateStructure
                        .timeOfDayTiers.price
                    : null,
                  start: action.update_rate_structure.updatedRateStructure
                    .timeOfDayTiers.start
                    ? action.update_rate_structure.updatedRateStructure
                        .timeOfDayTiers.start
                    : null,
                  stop: action.update_rate_structure.updatedRateStructure
                    .timeOfDayTiers.stop
                    ? action.update_rate_structure.updatedRateStructure
                        .timeOfDayTiers.stop
                    : null,
                  unitId: action.update_rate_structure.updatedRateStructure
                    .timeOfDayTiers.unitId
                    ? action.update_rate_structure.updatedRateStructure
                        .timeOfDayTiers.unitId
                    : null,
                },
              ],
              timeSpanTiers: [
                {
                  id: action.update_rate_structure.updatedRateStructure
                    .timeSpanTiers.id
                    ? action.update_rate_structure.updatedRateStructure
                        .timeSpanTiers.id
                    : null,
                  order: action.update_rate_structure.updatedRateStructure
                    .timeSpanTiers.order
                    ? action.update_rate_structure.updatedRateStructure
                        .timeSpanTiers.order
                    : null,
                  price: action.update_rate_structure.updatedRateStructure
                    .timeSpanTiers.price
                    ? action.update_rate_structure.updatedRateStructure
                        .timeSpanTiers.price
                    : null,
                  unitId: action.update_rate_structure.updatedRateStructure
                    .timeSpanTiers.unitId
                    ? action.update_rate_structure.updatedRateStructure
                        .timeSpanTiers.unitId
                    : null,
                  valueLength: action.update_rate_structure.updatedRateStructure
                    .timeSpanTiers.valueLength
                    ? action.update_rate_structure.updatedRateStructure
                        .timeSpanTiers.valueLength
                    : null,
                },
              ],
              unitId1:
                action.update_rate_structure.updatedRateStructure.unitId1,
              unitId2:
                action.update_rate_structure.updatedRateStructure.unitId2,
              idlePenalty:
                action.update_rate_structure.updatedRateStructure.idlePenalty,
              idleFee:
                action.update_rate_structure.updatedRateStructure.idleFee,
              idleGracePeriodMins:
                action.update_rate_structure.updatedRateStructure
                  .idleGracePeriodMins,
              idleFeeUnitId:
                action.update_rate_structure.updatedRateStructure
                  .idleFeeUnitId,
              idleFeePeriodStart:
                action.update_rate_structure.updatedRateStructure
                  .idleFeePeriodStart,
              idleFeePeriodStop:
                action.update_rate_structure.updatedRateStructure
                  .idleFeePeriodStop,
              maxParkingLimit:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingLimit,
              maxParkingTime:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingTime,
              maxParkingTimePenalty:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingTimePenalty,
                  maxParkingUnitId:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingUnitId,
              maxParkingPeriodStart:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingPeriodStart,
              maxParkingPeriodStop:
                action.update_rate_structure.updatedRateStructure
                  .maxParkingPeriodStop,
              taxLabel:
                action.update_rate_structure.updatedRateStructure.taxLabel,
              taxPercentageRate:
                action.update_rate_structure.updatedRateStructure.taxPercentageRate,
            });
          }
          var index = newStructureList.findIndex((dets) => {
            return (
              dets.id === action.update_rate_structure.replacedRateStructure.id
            );
          });
          if (index >= 0) {
            newStructureList = [
              ...newStructureList.slice(0, index),
              {
                ...newStructureList[index],
                expirationDateTime: moment
                  .utc(
                    action.update_rate_structure.replacedRateStructure
                      .expirationDateTime
                  )
                  .local(),
              },
              ...newStructureList.slice(index + 1),
            ];
          }
          return { ...state, rate_structure_list: newStructureList };
        } else {
          return state;
        }
        case RateStructureTypes.FLEET_RATE_STRUCTURE_LIST:
        if (
          action.fleet_rate_structure_list && 
          action.fleet_rate_structure_list.length > 0
        ) {
          return {
            ...state,
            fleet_rate_structure_list: action.fleet_rate_structure_list,
          };
        } else {
          return state;
        }
      case RateStructureTypes.FLEET_ADD_RATE_STRUCTURE:
        if (action.add_rate_structure) {
          return { ...state, 
            fleet_rate_structure_list: state.rate_structure_list.concat({
              ...action.add_rate_structure
            })
          };
        } else return state;
      case RateStructureTypes.FLEET_UPDATE_RATE_STRUCTURE:
        if (action.update_rate_structure && action.update_rate_structure.updatedRateStructure) {
          let newStructureList = state.fleet_rate_structure_list;
          var index = newStructureList.findIndex((dets) => {
            return (
              dets.id === action.update_rate_structure.replacedRateStructure.id
            );
          });
          if (index >= 0) {
            newStructureList = [
              ...newStructureList.slice(0, index),
              {
                ...newStructureList[index],

                expirationDateTime: moment
                  .utc(
                    action.update_rate_structure.replacedRateStructure
                      .expirationDateTime
                  )
                  .local(),
              },
              ...newStructureList.slice(index + 1),
            ];
          }
          return {
            ...state, fleet_rate_structure_list: newStructureList.concat({
              ...action.update_rate_structure.updatedRateStructure
            })
          };

        } else {
          return state;
        }
      default:
        return state;
    }
  }
}
