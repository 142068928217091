//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//


import * as CompanyGroupsTypes from "../actiontypes/CompanyGroups";

// action: the action you're performing to change the state  (type, any other data you want.)
// The current state
const initialState = {
  companyGroups: [],
  companyGroupsLength: 0,
  updateDateTime: null,
};

export function CompanyGroupsReducer(state = initialState, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case CompanyGroupsTypes.LOAD_COMPANY_GROUPS:
        return Object.assign({}, state, {
          companyGroups: action.companyGroups,
          companyGroupsLength: action.companyGroupsLength,
          updateDateTime: action.updateDateTime,
        });
      case CompanyGroupsTypes.ADD_COMPANY_GROUP:
        if (action.add_company_group) {
          // var value = 0;
          // value = state.supportIssuesLength + 1;
          // for ( let i = state.supportIssues.length - 1; i > state.supportIssues.length - 10; i--) {
          //   if (state.supportIssues[i].supportlogid == action.add_support_issue.supportlogid) {
          //     return Object.assign({}, state, {
          //       supportIssues: state.supportIssues,
          //       updateDateTime: Date.now(),
          //       supportIssuesLength: state.supportIssuesLength,
          //     });
          //   }
          // }
          // return Object.assign({}, state, {
          //   supportIssues: state.supportIssues.concat({
          //     isOutOfService: action.add_support_issue.isOutOfService,
          //     datetimeEntered: action.add_support_issue.datetimeEntered,
          //     description: action.add_support_issue.description,
          //     hoursticketopen: action.add_support_issue.hoursticketopen,
          //     issueSource: action.add_support_issue.issueSource,
          //     issuetypedescription:
          //       action.add_support_issue.issuetypedescription,
          //     locationId: action.add_support_issue.locationId,
          //     rebootrequired: action.add_support_issue.rebootrequired,
          //     reportedByPhone: action.add_support_issue.reportedByPhone,
          //     reportedbyname: action.add_support_issue.reportedbyname,
          //     rootcause: action.add_support_issue.rootcause,
          //     severity: action.add_support_issue.severity,
          //     solution: action.add_support_issue.solution,
          //     stationId: action.add_support_issue.stationId,
          //     supportType: {
          //       IssueTypeDescription:
          //         action.add_support_issue.IssueTypeDescription,
          //       StationSupportIssueTypeId:
          //         action.add_support_issue.StationSupportIssueTypeId,
          //     },
          //     supportlogid: action.add_support_issue.supportlogid,
          //     ticketstatus: action.add_support_issue.ticketstatus,
          //     userID: action.add_support_issue.userID,
          //   }),
          //   updateDateTime: Date.now(),
          //   supportIssuesLength: value,
          // });
          return state;
        }
        else {
          return state;
        }
      default: {
        return state;
      }
    }
  }
}