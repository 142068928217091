   
//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Dropdown, Navbar } from "react-bootstrap";
import { Trans } from "react-i18next";
//UI Components
import { KasiModalConfirmDanger } from './Kasi/KasiModal'
//Utilities
import { FetchLocations } from "../../services/Locations";
import { isAdminMgr, isOpConnectValidRole, isSupportCallOperator } from "../utilities/ValidateUserRoles";
//Styles
import "./Navbar.scss";
import styles from "./Navbar.module.scss";
import sharedStyles from "../pages/Dashboard/Components/Topbar/Topbar.module.scss";
//Assets
import oplogo from "../../assets/webp/OpConnectLogo.webp";

class NavbarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModal: false,
      authToken: this.props.authToken,
      navbarExpanded: false,
    };
    this.handleLoggedOff  = this.handleLoggedOff.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem('userSetting-defaultToKasi') === "true" && !sessionStorage.getItem('kasiBetaVisited')) {
      sessionStorage.setItem('kasiBetaVisited', true);
      this.props.togglekasibeta()
    }
    FetchLocations(
      this.props.siteId,
      this.props.authToken,
      this.props.setLocations
    );
    //Default the navbar burger to expanded if not on mobile device
    if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      this.setState({ navbarExpanded: true });
    }
  }
  
  handleLoggedOff = () => {
    this.setState({ loginModal: false });
    //LogoutSession(this.props.authToken, this.props.onLogOutClick, this.props.onLogOutStoreClear);
    this.props.history.push("/logout" )    
  }

  toggleFleetModeCall() {
    if (!this.props.fleetMode) {
      this.props.toggleFleetMode(true)
    }
    if (localStorage.getItem('userSetting-DisableModeRedirect') !== "true") {
      this.props.history.push("/")
    }
  }

  toggleStandardModeCall() {
    if (this.props.fleetMode) {
      this.props.toggleFleetMode(false)
    }
    if (localStorage.getItem('userSetting-DisableModeRedirect') !== "true") {
      this.props.history.push("/")
    }
  }

  render() {
    return (
      <>
          <div className="MainNavbar">
          {/* <Nav.Item className="pt-2">
            {isOpConnectValidRole(this.props.userRole) ? (
              <Nav.Link href="/companies" id="description">
                <Trans>Companies</Trans>
              </Nav.Link>
            ) : (
              this.props.companyType == "Network Operator"
            )}
          </Nav.Item> */}
          <Navbar 
            expand="lg" 
            expanded={this.state.navbarExpanded} 
            onToggle={()=>{this.setState({ navbarExpanded: !this.state.navbarExpanded })}} 
            className={`${styles.navbar} bg-body-tertiary`}
          >
            {/*------------------------------[ Brand Logo ]------------------------------*/}
            <Navbar.Brand className={styles.logoContainer}>
              <Link to ="/">
                {(this.props.companyLogo && this.props.companyLogoType)
                ? <img className={styles.logo} alt="companyLogo" src={'data:' + this.props.companyLogoType + ';base64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(this.props.companyLogo)))}/>
                : <img className={styles.logo} alt="oplogo" src={oplogo} />
                }
              </Link>
            </Navbar.Brand>
            {/*------------------------------[ Fleet Mode Toggle]------------------------------*/}
            {(isOpConnectValidRole(this.props.userRole) || this.props.companyType === "Network Operator")
            ?
              <span className={styles.modeToggleContainer}>
                <span className={styles.modeToggle} onClick={() => { this.toggleFleetModeCall() }}>
                  <input
                    type="radio"
                    name="fltModeToggle"
                    checked={this.props.fleetMode}
                  />
                  <b className={styles.toggleName}> Fleet Mode</b>
                </span>
                <span className={styles.modeToggle} onClick={() => { this.toggleStandardModeCall() }}>
                  <input
                    type="radio"
                    name="fltModeToggle"
                    checked={!this.props.fleetMode}
                  />
                  <b className={styles.toggleName}> Standard Mode</b>
                </span>
              </span>
            : ""
            }
            <Navbar.Toggle className={sharedStyles.iconMinimizedExpanded} aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className={styles.navbarCollapse}>
              <nav className={styles.navbarNav}>
                {/*------------------------------[ Fleet Management ]------------------------------*/}
                {(this.props.fleetMode) 
                ? 
                  <Dropdown>
                    <Dropdown.Toggle variant="btn" className={styles.navbarDropdown}>Fleet Management</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/companygroups" >Manage Divisions/Departments</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/fleettokens">Manage Fleet Tokens</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/fleetvehicles">Manage Fleet Vehicles</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                : ""
                }
                {/*------------------------------[ Call Support ]------------------------------*/}
                {(isOpConnectValidRole(this.props.userRole))
                ?
                  <Dropdown>
                    <Dropdown.Toggle variant="btn" className={styles.navbarDropdown}>Call Support</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/CallSupport">
                        <Trans>Call Support Information</Trans>
                      </Dropdown.Item >
                      <Dropdown.Item as={Link} to="/OperatorCallSupport">
                        <Trans>Call Support Wizard</Trans>
                      </Dropdown.Item>               
                      {/*<Dropdown.Item>
                            <Link className="pt-3 navLink" to="/CallSchedule" >
                              <Trans>Call Support Schedule</Trans>
                            </Link>
                          </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                : "" 
                }
                {/*------------------------------[ Kasi Toggle]------------------------------*/}
                {(isOpConnectValidRole(this.props.userRole))
                ? <Button onClick={()=>{this.props.togglekasibeta()}} variant="outline-primary" className={styles.navbarButton}>Kasi Beta</Button>
                : ""
                }
                {/*------------------------------[ Support Issues ]------------------------------*/}
                {isSupportCallOperator(this.props.userRole)
                ? ""
                :
                  <Link className={styles.navbarLink} to="/support" >
                    <Trans>Support Issues</Trans>
                  </Link>
                }
                {/*------------------------------[ Management Portal Users ]------------------------------*/}
                {isAdminMgr(this.props.userRole) 
                ? 
                  <>
                    <Link className={styles.navbarLink} to="/managementportalusers" >
                      <Trans>Management Portal Users</Trans>
                    </Link>
                    {/*------------------------------[ Ev Driver Accounts ]------------------------------*/}
                    {( (this.props.companyType === "Network Operator") || (isOpConnectValidRole(this.props.userRole) && !this.props.fleetMode)) 
                    ? 
                      <Dropdown>
                        <Dropdown.Toggle variant="btn" className={styles.navbarDropdown}>
                          EV Driver Accts
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to="/evdriveraccount0">
                              <Trans>All Drivers</Trans>
                          </Dropdown.Item >
                          <Dropdown.Item as={Link} to="/evdriveraccount1">
                              <Trans>Last Name A-F</Trans>
                          </Dropdown.Item >
                          <Dropdown.Item as={Link} to="/evdriveraccount2">
                              <Trans>Last Name G-L</Trans>
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/evdriveraccount3">
                              <Trans>Last Name M-S</Trans>
                          </Dropdown.Item >
                          <Dropdown.Item as={Link} to="/evdriveraccount4">
                              <Trans>Last Name T-Z (or missing last name)</Trans>
                          </Dropdown.Item>        
                        </Dropdown.Menu>
                      </Dropdown>
                    : ""
                    }
                  </>
                : ""
                }
                {/*------------------------------[ Companies ]------------------------------*/}
                {(isOpConnectValidRole(this.props.userRole) || this.props.companyType == "Network Operator") 
                ? 
                  <Link className={styles.navbarLink} to="/companies" >
                    <Trans>Companies</Trans>
                  </Link>
                : ""
                }
                {/*--------------------[ Report Manager, My Account, Sign Out ]--------------------*/}
                {isSupportCallOperator(this.props.userRole)
                ? ""
                :
                  <>
                    <Link className={styles.navbarLink} to="/reports" >
                      <Trans>Report Manager</Trans>
                    </Link>
                    <Link className={styles.navbarLink} to="/myaccount" >
                      {" "} My Account
                    </Link>
                  </>
                }
                  <Button
                    variant="outline-primary"
                    onClick={()=> {this.setState({ loginModal: true })}}
                    className={styles.navbarButton}
                  >
                    Sign Out
                  </Button>
              </nav>
            </Navbar.Collapse>
          </Navbar>
            {/*-------------------------------------------------- */}
            {/* <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item language-dropdown">
                <Dropdown alignRight>
                  <Dropdown.Toggle className=" btn btn-light text-primary mb-3">
                    <Trans>English</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-dropdown">
                    <Dropdown.Item
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <i className="flag-icon flag-icon-fr mr-3"></i>
                      <Trans>French</Trans>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <i className="flag-icon flag-icon-es mr-3"></i>
                      <Trans>Spanish</Trans>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul> */}
            {/*-----------------------[ Log off Modal]-----------------------*/}
          <KasiModalConfirmDanger 
            show={this.state.loginModal}
            header="Sign Out"
            confirm={()=>{this.handleLoggedOff()}}
            cancel={()=>{this.setState({ loginModal: false })}}
            onHide={()=>{this.setState({ loginModal: false })}}
          >
          <p>
            Please confirm you would like to sign out by clicking "Confirm" button below.<br/><br/>
            If would you would like to continue using application click "Cancel" button.
          </p>
          </KasiModalConfirmDanger>
        </div>
        <span className={styles.navbarHr} />
      </>
    );
  }
}

export default withRouter(NavbarMenu)