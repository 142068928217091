//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as LocationActions from "../actiontypes/Locations";

export const updateLocations = (locations) => {
    return {
      type: LocationActions.UPDATE_LOCATIONS,
      locations: locations
    }
}

export const updateLocationAnalytics = (locationAnalytics, periodType) => {
  return {
    type: LocationActions.UPDATE_LOCATION_ANALYTICS,
    locationAnalytics,
    periodType
  }
}

export const updateLocationDetails = (details) => {
  return {
    type: LocationActions.UPDATE_LOCATION_DETAILS,
    data: details
  }
}

export const Edit_Location = (edit_location) => {
  return {
    type: LocationActions.EDIT_LOCATION,
    edit_location: edit_location
  }
}

export const Add_New_Location = (add_location) => {
  return {
    type: LocationActions.ADD_LOCATION,
    add_location: add_location
  }
}

export const Toggle_Location_Private = (locationID) => {
  return {
    type: LocationActions.TOGGLE_LOCATION_PRIVATE,
    locationID
  }
}

export const SetLocationChargingSchedule = (locationChargingSchedule) => {
  return {
    type: LocationActions.SET_LOCATION_CHARGING_SCHEDULE,
    locationChargingSchedule
  }
}


