//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2024 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import React from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap';
import KasiButton from '../KasiButton/KasiButton';
import FormatTextWithLineBreaks from './FormatStringsWithLineBreaks';

// This is the Kasi Danger Confirmation modal, it also supports legacy MyOpConnect
// This is a copy of KasiModalConfirm with a red theme for dangerous actions such as a Delete.
//----------------------------------------------[ Example ] ----------------------------------------------

// import { KasiModalConfirmDanger } from '../../../shared/Kasi/KasiModal'
// <KasiModalConfirmDanger 
//  show={showDeactivateModal}
//  kasibeta={props.kasibeta}
//  header="Confirm Deactivation"
//  confirm={handleDeactiveConfirmation}
//  cancel={()=>{setShowDeactivateModal(false)}}
//  onHide={()=>{setShowDeactivateModal(false)}}
// >
//  Are you sure you want to deactivate station:<br/> <b>{targetDescription}</b>?
// </KasiModalConfirmDanger>

//----------------------------------------------[ Code ] ----------------------------------------------
const KasiModalConfirmDanger = ({
    kasibeta=false,             // Pass kasiBeta in for Kasi style
    header="Error",             // Pass in a Header
    body=                       // Pass in a Body to replace default message (Can handle \n line breaks)
    `Please confirm this action.`,  
    footer="",                  // Pass in a Footer (Loading doesnt use one)
    children,                   // Allows JSX children to be passed for the body
    className="",               // Custom className
    size="",                    // Custom Bootstrap Modal size
    centered=true,              // Pass in to center the modal (Loading defaults on)
    show=false,                 // Pass in a State to display Modal
    onHide=()=>{},              // Pass in a function to handle on hide
    confirm=()=>{},             // Pass in a function to handle closing the modal
    cancel=()=>{},              // Pass in a function to handle closing the modal
    confirmLabel="Confirm",     // Pass in a label for the confirm button
    cancelLabel="Cancel",       // Pass in a label for the cancel button
}) => {
    return (
        <Modal
            show={show}
            size={size}
            aria-labelledby="contained-modal-title"
            centered={centered}
            className={`${kasibeta ? "KASIBootstrapModalRed" : ""} ${className}`}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title">{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children ? children : FormatTextWithLineBreaks(body)}
                <br /><br />
            </Modal.Body>
            {footer 
            ?   // A footer was passed in
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            :   // No Footer to display
                <Modal.Footer>
                    {kasibeta 
                    ? 
                        <>
                            <KasiButton label={cancelLabel} onClick={cancel} buttonStyle="green"/>
                            <KasiButton label={confirmLabel} onClick={confirm} buttonStyle="red"/>
                        </>
                    : 
                        <>
                            <Button variant="outline-danger" onClick={confirm}>{confirmLabel}</Button>
                            <Button variant="outline-primary" onClick={cancel}>{cancelLabel}</Button>
                        </>
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}
    
export default KasiModalConfirmDanger;