//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();

const getConnectionString = (baseURL) => {
  // figure out connectionString based on the baseURL
  if (baseURL === 'https://www.opconnect.com/API/') {
    return process.env.REACT_APP_APPINSIGHT_KEY;
  } else {
    return process.env.REACT_APP_APPINSIGHT_KEY;
  } 
};


var ai = new ApplicationInsights({
    config: {
        connectionString: getConnectionString(process.env.REACT_APP_BASE_URL),
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
ai.loadAppInsights();

const withAITrackingWrapper = (Component) => withAITracking(reactPlugin, Component);
export default withAITrackingWrapper;
export const appInsights = ai.appInsights;