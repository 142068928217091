//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React from "react";
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const DynamicTagBreadcrumb = ({ match }) => (
    <>{'Tag Id#' + match.params.tagid}</>
);

const DynamicLocationBreadcrumb = ({ match }) => (
    <>{'Location Id#' + match.params.lid}</>
);

const DynamicStationBreadcrumb = ({ match }) => (
    <>{'Station Id#' + match.params.sid}</>
);

const DynamicDepotBreadcrumb = ({ match }) => (
  <>{'Depot Id#' + match.params.lid}</>
);

const DynamicEVBreadcrumb = ({ match }) => (
  <>{'EV Id#' + match.params.evid}</>
);

const DynamicChargerBreadcrumb = ({ match }) => (
  <>{'Charger Id#' + match.params.sid}</>
);

const DynamicDriverBreadcrumb = ({ match }) => (
    <>{'Driver Id#' + match.params.drvid}</>
);

// define custom breadcrumbs for certain routes.
// breadcumbs can be components or strings.
const routes = [
 
    { path: '/myaccount', breadcrumb: 'My Account' },
    { path: '/managementportalusers', breadcrumb: 'Management Portal Users' },
    { path: '/managementportalusers/createmanagementportaluser', breadcrumb: 'Create Management Portal User' },
    { path: '/managementportalusers/editmanagementportaluser:uid', breadcrumb: 'Edit Management Portal User' },
    { path: '/users/editMyAccount:uid', breadcrumb: 'Edit My Account' },

    { path: '/evdriveraccount', breadcrumb: 'EV Driver Accts' },
    { path: '/evdriveraccount:alphaId', breadcrumb: 'EV Driver Accts' },
    { path: '/evdriveraccount/create', breadcrumb: 'Create EV Driver Acct' },
    { path: '/evdriveraccount/editdriver:did', breadcrumb: 'Edit EV Driver Acct' },

    { path: '/locations', breadcrumb: 'Locations' },
    { path: '/locations/:lid/sessions', breadcrumb: 'Sessions' },
    { path: '/locations/:lid/opensessions', breadcrumb: 'Open Sessions' },
    { path: '/locations/:lid/stations', breadcrumb: 'Stations' },
    { path: '/locations/:lid/stations/addstation', breadcrumb: 'Create Station' },
    { path: '/locations/:lid/drivers', breadcrumb: 'Drivers' },
    { path: '/locations/:lid/drivers/:drvid', breadcrumb: DynamicDriverBreadcrumb },
    { path: '/locations/:lid/drivers/:drvid/sessions', breadcrumb: 'Sessions' },
    { path: '/locations/:lid/stations/:sid', breadcrumb: DynamicStationBreadcrumb },
    { path: '/locations/:lid/stations/:sid/editstation', breadcrumb: 'Edit Station' },
    { path: '/locations/:lid/stations/:sid/sessions', breadcrumb: 'Sessions' },
    { path: '/locations/:lid/stations/:sid/drivers', breadcrumb: 'Drivers' },
    { path: '/locations/:lid/stations/:sid/drivers/:drvid', breadcrumb: DynamicDriverBreadcrumb },
    { path: '/locations/:lid/stations/:sid/drivers/:drvid/sessions', breadcrumb: 'Sessions' },
    { path: '/locations/:lid/support', breadcrumb: 'Support' },
    { path: '/locations/:lid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/locations/:lid/stations/:sid/support', breadcrumb: 'Support' },
    { path: '/locations/:lid/stations/:sid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/locations/:lid/chargingschedule', breadcrumb: 'Charging Schedule' },
    { path: '/locations/:lid/privateschedule', breadcrumb: 'Private Schedule' },
    { path: '/locations/:lid', breadcrumb: DynamicLocationBreadcrumb },

    { path: '/stations', breadcrumb: 'Stations' },
    { path: '/stations/addstation', breadcrumb: 'Create Station' },
    { path: '/stations/addconfiguration', breadcrumb: 'Add Client Kit Configuration' },
    { path: '/stations/:sid/editconfiguration', breadcrumb: 'Edit Client Kit Configuration' },
    { path: '/stations/:sid/cloneconfiguration', breadcrumb: 'Clone Client Kit Configuration' },
    { path: '/stations/:sid/viewpushedvalues', breadcrumb: 'View Values Pushed from Client Kit Configuration' },
    { path: '/stations/manageconfigurations', breadcrumb: 'Manage Client Kit Configurations' },   
    { path: '/stations/inactiveconfigurations', breadcrumb: 'Inactive Client Kit Configurations' },  
    { path: '/stations/builtconfigurations', breadcrumb: 'Built Client Kit Configurations' },  
    { path: '/stations/inactivebeaglebones', breadcrumb: 'Inactive Client Kit BeagleBones' },  
    { path: '/stations/builtbeaglebones', breadcrumb: 'Built Client Kit BeagleBones' },  
    { path: '/stations/:sid/editstation', breadcrumb: 'Edit Station' },
    { path: '/stations/:sid/sessions', breadcrumb: 'Sessions' },
    { path: '/stations/:sid/drivers', breadcrumb: 'Drivers' },
    { path: '/stations/:sid/drivers/:drvid', breadcrumb: DynamicDriverBreadcrumb },
    { path: '/stations/:sid/drivers/:drvid/sessions', breadcrumb: 'Sessions' },
    { path: '/stations/:sid/support', breadcrumb: 'Support' },
    { path: '/stations/:sid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/stations/:sid', breadcrumb: DynamicStationBreadcrumb },

    /*----------------------START: Fleet mode specific paths -------------------------*/
    { path: '/depots', breadcrumb: 'Depots' },
    { path: '/depots/:lid/sessions', breadcrumb: 'Sessions' },
    { path: '/depots/:lid/chargers', breadcrumb: 'Chargers' },
    { path: '/depots/:lid/chargers/:sid', breadcrumb: DynamicChargerBreadcrumb },
    { path: '/depots/:lid/chargers/:sid/editcharger', breadcrumb: 'Edit Charger' },
    { path: '/depots/:lid/chargers/:sid/sessions', breadcrumb: 'Sessions' },
    { path: '/depots/:lid/support', breadcrumb: 'Support' },
    { path: '/depots/:lid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/depots/:lid/chargers/:sid/support', breadcrumb: 'Support' },
    { path: '/depots/:lid/chargers/:sid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/depots/:lid/chargingschedule', breadcrumb: 'Charging Schedule' },
    { path: '/depots/:lid/privateschedule', breadcrumb: 'Private Schedule' },
    { path: '/depots/:lid', breadcrumb: DynamicDepotBreadcrumb },

    { path: '/chargers', breadcrumb: 'Chargers' },
    { path: '/chargers/addcharger', breadcrumb: 'Create Charger' },
    { path: '/chargers/:sid/editcharger', breadcrumb: 'Edit Charger' },
    { path: '/chargers/:sid/sessions', breadcrumb: 'Sessions' },
    { path: '/chargers/:sid/support', breadcrumb: 'Support' },
    { path: '/chargers/:sid/support/:supportid', breadcrumb: 'Ticket details' },
    { path: '/chargers/:sid', breadcrumb: DynamicChargerBreadcrumb },
    
    {path: '/evsconnected', breadcrumb: 'EVs Connected'},
    {path: '/evscharging', breadcrumb: 'EVs Charging'},

    { path: '/coststructures', breadcrumb: 'Cost of kWh Structures'},
    { path: '/coststructures/addfleetrate', breadcrumb: 'Add Cost Structure'},	

    /*-----------------------END: Fleet mode specific paths -----------------------------*/

    { path: '/support', breadcrumb: 'Support' },
    { path: '/support/createTicket', breadcrumb: 'Create Ticket' },
    { path: '/support/:supportid', breadcrumb: 'Ticket details' },

    { path: '/companygroups', breadcrumb: 'Divisions/Departments' },
    { path: '/fleettokens', breadcrumb: 'Fleet Tokens' },
    { path: '/fleetvehicles', breadcrumb: 'Fleet Vehicles' },
    { path: '/fleetvehicles/addvehicle', breadcrumb: 'Create Vehicle' },
    { path: '/fleetvehicles/:vehicleId', breadcrumb: 'Vehicle details' },

    { path: '/evs', breadcrumb: 'EVs' },
    { path: '/evs/:evid', breadcrumb: DynamicEVBreadcrumb },

    { path: '/sessions', breadcrumb: 'Sessions' },
    { path: '/openSessions', breadcrumb: 'Open Sessions' },

    { path: '/drivers', breadcrumb: 'Driver' },
    { path: '/drivers/:drvid/sessions', breadcrumb: 'Sessions' },
    { path: '/drivers/:drvid', breadcrumb: DynamicDriverBreadcrumb },

    { path: '/companies', breadcrumb: 'Companies' },
    { path: '/companies/create', breadcrumb: 'Create Company' },
    { path: '/companies/:cid', breadcrumb: 'Edit Company' },

    { path: '/OperatorCallSupport/', breadcrumb: 'Operator Call Support' },

    { path: '/ratestructures', breadcrumb: 'Rate Plan Structures' },
    { path: '/ratestructures/createrateplan', breadcrumb: 'Create Rate Plan' },
    { path: '/ratestructures/:rateid', breadcrumb: 'Edit Rate Plan Structure' },

    { path: '/tags', breadcrumb: 'Tags' },
    { path: '/tags/sessions-tagid-:tagid', breadcrumb: 'Sessions' },
    { path: '/tags/:tagid', breadcrumb: DynamicTagBreadcrumb },
    
    { path: '/', breadcrumb: "Dashboard"},
    { path: '/kasi', breadcrumb: "KASI Beta"}
];

export const CustomBreadcrumbs = (props) => {
    const breadcrumbs = useBreadcrumbs(routes);
  
    //Toggle between legacy or KASI beta breadcrumbs
    //TODO: In KASI, Changing back to dashboard using the breadcrumb needs to reset the sidenavbar to dashboard
    return (
      props.kasibeta 
      ? //KASI Breadcrumbs
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb bg-blue breadcrumb-kasi">
            {breadcrumbs.map(({
              match,
              breadcrumb
            }) => (
              <li className="breadcrumb-item breadcrumb-item-kasi" key={match.url} >
                <NavLink className="breadcrumb-link-kasi" to={match.url} onClick={props.clearAllFilters}>{breadcrumb}</NavLink>
              </li>
            ))}
          </ol>
        </nav>
      : //MyOpConnect Breadcrumbs
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb bg-blue">
            {breadcrumbs.map(({
              match,
              breadcrumb
            }) => (
            <li className="breadcrumb-item" key={match.url} >
              <NavLink to={match.url} onClick={props.clearAllFilters}>{breadcrumb}</NavLink>
            </li>
            ))}
          </ol>
        </nav>
    );
  };

