//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";


let countdownInterval;
let timeout;

const LogOff24Hrs = ({ isAuthenticated }) => {
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        try {
            clearInterval(countdownInterval);
        } catch (e) {
            console.error(e);
        }
    };

    const clearSession = () => {
        try {
            clearSessionInterval();
            clearSessionTimeout();
        } catch (e) {
            console.error(e);
        }
    }


    const handleLogout = async (isTimedOut = false) => {
        try {
            clearSession();
            // localStorage.clear();
            sessionStorage.clear();
            return  window.location.href = '/';
          } catch (e) {
            console.error(e);
          }
    };

    //On idle the the dialog modal will appear and the "countDown" time of 30 seconds will start counting down with onIdle function
    const onIdle = () => {
        handleLogout(true);
    };

    //Set the timeout in minutes
    const timeOutSeconds = 59;
    const timeOutMinutes = 59;
    const timeOutHrs = 23;

    return (
        <>
            <IdleTimer
                ref={idleTimer}
                onIdle={onIdle}
                //Timeout set the time interval before warning appears in seconds
                timeout={1000 * timeOutSeconds * timeOutMinutes * timeOutHrs}
            />
        </>
    );
}
export default LogOff24Hrs;