//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2023 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React, { Suspense, lazy } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// Styles
import styles from './KasiButton.module.scss';
// Assets
const DropdownIcon = lazy(() => import('./KasiButtonIcons/KasiButtonDropdownIcon'));
const DownloadIcon = lazy(() => import('./KasiButtonIcons/KasiButtonDownloadIcon'));
const SaveIcon = lazy(() => import('./KasiButtonIcons/KasiButtonSaveIcon'));
const DeleteIcon = lazy(() => import('./KasiButtonIcons/KasiButtonDeleteIcon'));


//This is the custom KASI style button. See example useage below:
//----------------------------------------------[ Example ] ----------------------------------------------

// import KasiButton from './components/shared/Kasi/KasiButton/KasiButton';

// Single Line Example
// <KasiButton id="" className="" labelPrefix="+ " label="Kasi Button" labelSuffix="save delete download dropdown" tooltip="Click to add a new station" buttonStyle="blue green red" size="short small null(for default)" onClick={() => console.log("Button clicked")} type="submit null(for default)" disabled={false} />

// Multi Line Example
// <KasiButton
//   id=""
//   className=""
//   labelPrefix="+ "
//   label="Kasi Button"
//   labelSuffix="save delete download dropdown"
//   tooltip="This is a tooltip"
//   buttonStyle="blue green red"
//   size="short small null(for default)"
//   onClick={() => console.log("Button clicked")}
//   type="submit null(for default)"
//   disabled={false}
// >
//  JSX here overrides label
// </KasiButton>

//----------------------------------------------[ Code ] ----------------------------------------------
const KasiButton = ({ 
  id=null,                        // Pass an id to the button
  className=null,                 // Pass a className to the button

  labelPrefix=null,               // Pass a String to display on the left side (Intended to be used with "+" and "-")
  label="KASI Button",            // Pass a String to label the button
  labelSuffix=null,               // Pass a SVG to display on the right side (Built in options: "save", "delete", "download", "dropdown")
  tooltip=null,                   // Pass a tooltip to the button
  tooltipPlacement="top",          // Pass a tooltipPlacement to the button (top, right, bottom, left)

  buttonStyle="blue",             // Pass buttonStyle="blue red green" to change the button color
  size=null,                      // "short" will remove top/bottom margin and padding, "small" will shrink the overall button size

  onClick=null,                   // Pass an onClick function to the button (Will perform default button behavior if nothing is passed)
  type=null,                      // Pass type="submit" to the button to handle forms, or pass type="button"
  disabled=false,                 // Pass disabled={true} to disable the button
  children,                       // Allows JSX children to be passed in (Will overwrite `label` if both are passed)
}) => {

  //This function allows the button to be passed an onclick function (Or default to 'default' behavior). 
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault();
      if (!disabled) {
        onClick(e);
      }
    }
  };

  //Pass the string "blue red green" into the buttonStyle parameter to access the 3 different colors
  let buttonStyleClass = "KasiButton" + buttonStyle

  //This function returns the relevant SVG for the labelSuffix parameter
  const getLabelSuffix = (labelSuffix) => {
    switch (labelSuffix) {
      case 'save':
        return (
          <Suspense fallback={<></>}>
            <span className={styles.KasiLabelSuffixSave}>
              <SaveIcon />
            </span>
          </Suspense>
        );
      case 'download':
        return (
          <Suspense fallback={<></>}>
            <span className={styles.KasiLabelSuffixDownload}>
              <DownloadIcon />
            </span>
          </Suspense>
        );
      case 'delete':
        return (
          <Suspense fallback={<></>}>
            <span className={styles.KasiLabelSuffixDelete}>
              <DeleteIcon />
            </span>
          </Suspense>
        );
      case 'dropdown':
        return (
          <Suspense fallback={<></>}>
            <span className={styles.KasiLabelSuffixDropdown}>
              <DropdownIcon />
            </span>
          </Suspense>
        );
      default:
        return labelSuffix;
    }
  };

  //This function returns the relevant size class for the button
  const getSizeClassName = (size) => {
    switch (size) {
      case 'short':
        return styles.KasiButtonShort;
      case 'small':
        return styles.KasiButtonSmall;
      default:
        return styles.KasiButtonMedium;
    }
  };


  return (
    <>
      {tooltip 
      ? // A tooltip was provided
        <OverlayTrigger
          placement={tooltipPlacement}
          overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
        >
          {({ ref: overlayRef, ...triggerHandler }) => (
          <button
            ref={overlayRef}
            id={id}
            className={`
              ${getSizeClassName(size)}
              ${styles[buttonStyleClass]} 
              ${className} 
              ${labelSuffix ? styles.KasiLabelSuffixRight : ''} 
              ${disabled ? styles.disabled : ''}
            `}
            onClick={handleClick}
            type={type}
            {...triggerHandler}
          >
            {labelPrefix && <b>{labelPrefix}</b>}
            {children ? children : label}
            {labelSuffix && getLabelSuffix(labelSuffix)}
          </button>
          )}
        </OverlayTrigger>
      : // A tooltip was not provided
        <button
          id={id}
          className={`
            ${getSizeClassName(size)}
            ${styles[buttonStyleClass]} 
            ${className} 
            ${labelSuffix ? styles.KasiLabelSuffixRight : ''} 
            ${disabled ? styles.disabled : ''}
          `}
          onClick={handleClick}
          type={type}
        >
          {labelPrefix && <b>{labelPrefix}</b>}
          {children ? children : label}
          {labelSuffix && getLabelSuffix(labelSuffix)}
        </button>
      }
    </>
  );
};

export default KasiButton;