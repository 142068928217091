//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import * as StationTypes from "../actiontypes/Station";
import groupBy from 'lodash/groupBy';
import moment from 'moment';

export function StationsAnalyticsReducer(state={
  analytics: [], analyticsSummary: [], stationDetails: [], stationDetailsSummary: [], stationStatus: [], lastUpdatedAnalytics: null}, action) {
  // eslint-disable-next-line    
  if(action.type != undefined){
      switch(action.type){
        case StationTypes.UPDATE_STATION_ANALYTICS:
          if(action.stationAnalytics && action.stationAnalytics.length > 0)
          {
            if(action.periodType === "day")  
            {
              //Update date to locale date value before storing.
              for(var i = 0; i < action.stationAnalytics.length; ++i)
              {
                action.stationAnalytics[i].date = moment.utc(action.stationAnalytics[i].date).local().format("YYYY-MM-DD HH:mm");
              }
            }
            else if (action.periodType !== "day") 
            {
              //Update date to locale date value before storing.
              for (var i = 0; i < action.stationAnalytics.length; ++i) {
                action.stationAnalytics[i].date = moment.utc(action.stationAnalytics[i].date).local().format("YYYY-MM-DD");
              }
            }

          
                let groupedStat = groupBy(action.stationAnalytics, x => x.id);
                var updatedStations = [];
                for(var value in groupedStat)
                {
                     var kWhTotal = groupedStat[value].reduce((kWhTotal,sa)=>{
                      return kWhTotal += Number(sa.totals.kWh);
                    },0);

                    var sessionsTotal = groupedStat[value].reduce((sessionsTotal,sa)=>{
                      return sessionsTotal += Number(sa.totals.chargingSessions);
                    },0);

                    var revenueTotal = groupedStat[value].reduce((revenueTotal,sa)=>{
                      return revenueTotal += Number(sa.totals.revenue);
                    },0);


                    var durationTotal = groupedStat[value].reduce((durationTotal, sa) =>{
                      if(sa.totals.duration && sa.totals.duration.length > 0)
                        {  
                          return durationTotal += Number(moment.duration(sa.totals.duration).asHours());
                        }
                        else
                          return 0;
                    },0);

                    updatedStations.push({ ID: value, kWh:kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.00,
                    sessions:sessionsTotal,revenue:revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.00 , 
                    duration: durationTotal ? parseFloat(durationTotal.toFixed(1)) : 0.0, analytics: groupedStat[value]}); 
                }

              return Object.assign({}, state, {
                analytics: action.stationAnalytics, 
                stationDetails:  updatedStations,                
                lastUpdatedAnalytics: Date.now()
              });
          }
          else
            return Object.assign({}, state, {
              analytics: [], 
              stationDetails: [],                
              lastUpdatedAnalytics: Date.now()
            });

          case StationTypes.UPDATE_STATION_DETAILS:
            if (action.data)
            {
                if ( action.data.details === ''){
                  return Object.assign({}, state, {
                    stationDetailUpdateError: true});
                }
                var index = state.stationDetailsSummary.findIndex((dets) => { return dets.ID === action.data.station; });
                if (index >= 0)
                {
                    return {...state, stationDetailsSummary: [
                    ...state.stationDetailsSummary.slice(0, index),
                    {...state.stationDetailsSummary[index],  
                      "status": action.data.details.status,
                      "creditcardSessions":action.data.details.totalCreditCardSessions,
                      "memberSessions":action.data.details.totalMemberSessions,
                      "unknownDrivers":action.data.details.totalUnknownDrivers,
                      "registeredDrivers":action.data.details.totalRegisteredDrivers,
                      "sessions" : action.data.details.totalSessions,
                      "lastChargeDateTimeUTC": action.data.details.lastChargeDateTimeUTC,
                      "lastCheckInDateTimeUTC": action.data.details.lastCheckInDateTimeUTC},
                    ...state.stationDetailsSummary.slice(index + 1)
                    ],
                    stationDetailUpdateError: false
                  };
                  
                }
                else
                {
                  return  Object.assign({}, state, {
                    stationDetailsSummary : state.stationDetailsSummary.concat({
                    "ID":  action.data.station,
                    "status": action.data.details.status,
                    "creditcardSessions":action.data.details.totalCreditCardSessions,
                    "memberSessions":action.data.details.totalMemberSessions,
                    "unknownDrivers":action.data.details.totalUnknownDrivers,
                    "registeredDrivers":action.data.details.totalRegisteredDrivers,
                    "sessions" : action.data.details.totalSessions,
                    "lastChargeDateTimeUTC": action.data.details.lastChargeDateTimeUTC,
                    "lastCheckInDateTimeUTC": action.data.details.lastCheckInDateTimeUTC
                  })}, {stationDetailUpdateError: false });
                }            
            }
            else
              return Object.assign({}, state, {
                stationDetailUpdateError: true});

            case StationTypes.UPDATE_STATION_STATUS:
            if (action.statusList)
            {
              return Object.assign({}, state, {
                stationStatus: action.statusList
                });              
            }
            else
              return state;

            case StationTypes.UPDATE_STATION_DECOM:
                const indexUpdateStationStatus = state.stationStatus.findIndex((station) => {
                  return station.id === action.update_ticket.id;
              });
              if (indexUpdateStationStatus >= 0){
                  return {
                      ...state,
                      stationList : [
                          ...state.stationList.slice(0, indexUpdateStationStatus),
                          {
                              ...state.stationList[indexUpdateStationStatus],
                              ...action.stationStatus
                          },
                          ...state.stationList.slice(indexUpdateStationStatus + 1),
                      ],
                  };
              }else return state; 
          default: {
            return state;
          }
      }
    }
}