export const UPDATE_DRIVERS_TOTALS = 'UPDATE_DRIVERS_TOTALS';
export const UPDATE_DRIVERS_ANALYTICS = 'UPDATE_DRIVERS_ANALYTICS';
export const UPDATE_DRIVERS_DETAILS =  'UPDATE_DRIVERS_DETAILS';
export const GET_DRIVERS_HOME_CHARGER_PROPS = 'GET_DRIVERS_HOME_CHARGER_PROPS';
export const GET_DRIVERS_LIST = 'GET_DRIVERS_LIST';
export const ADD_DRIVER = 'ADD_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';

export const GET_OPEN_TOKENS_LIST = 'GET_OPEN_TOKENS_LIST';
export const DELETE_OPEN_TOKEN = 'DELETE_OPEN_TOKEN';

export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const REFRESH_ALL_DATES = 'REFRESH_ALL_DATES';

export const UPDATE_COMPANY_ANALYTICS = 'UPDATE_COMPANY_ANALYTICS';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_ACCT_INFO = "GET_ACCT_INFO";
export const UPDATE_ACCT_INFO = "UPDATE_ACCT_INFO";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_USER_ASSIGNED_TYPE_ID = "SET_USER_ASSIGNED_TYPE_ID";
export const GET_ASSIGNED_USER_LIST = "GET_ASSIGNED_USER_LIST";
export const GET_ACTIVE_DRIVER_LIST = "GET_ACTIVE_DRIVER_LIST";
export const GET_ASSIGNED_LOCATION_DRIVER_LIST = "GET_ASSIGNED_LOCATION_DRIVER_LIST";
export const ASSIGNED_USER_LIST = "ASSIGNED_USER_LIST";
export const DELETE_ASSIGNED_USER_LIST = "DELETE_ASSIGNED_USER_LIST";
export const ADD_REPORT = "ADD_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const REPORT_LIST = "REPORT_LIST";
export const USER_LIST = "USER_LIST";
export const USAGE_DATA = 'USAGE_DATA';
export const USAGE_DATA_LIST = 'USAGE_DATA_LIST';
export const GET_RATE_PLAN = 'GET_RATE_PLAN';
export const GET_LOCATION_USER_DISCOUNT_LIST = "GET_LOCATION_USER_DISCOUNT_LIST";
export const DELETE_LOCATION_USER_DISCOUNT = "DELETE_LOCATION_USER_DISCOUNT";
export const ADD_LOCATION_USER_DISCOUNT = "ADD_LOCATION_USER_DISCOUNT";