//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import styled from '@mui/material/styles/styled';
import red from "@mui/material/colors/red";


const StyledButton = styled(Button)(() => ({
  borderRadius: 5,
  textTransform: "none",
}));

const SpanCountdown = styled("span")(() => ({
  color: red[700],
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onContinue }) => {

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <b>Session Timeout</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          The current session is about to expire in{" "}
          <SpanCountdown>
            <b>{countdown}</b>
          </SpanCountdown>{" "}
          seconds.
        </Typography>
        <Typography variant="body1">{`Would you like to continue the session?`}</Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onContinue} color="primary" variant="contained">
          Continue Session
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;