//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as Session from "../actiontypes/Session";

export function SessionListReducer(
  state = { session_list: [], activeSessions: [], activeSessionLastUpdated: null },
  action
) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Session.SESSION_LIST:
        return Object.assign({}, state, {
          session_list: action.session_list,
        });
      case Session.UPDATE_SESSION:
        if (action.update_session) {
          var index = state.session_list.findIndex((dets) => {
            return dets.ID === action.update_session.ID;
          });
          if (index >= 0) {
            return {
              ...state,
              session_list: [
                ...state.session_list.slice(0, index),
                {
                  ...state.session_list[index],
                  costitem: action.update_session.AmountBilled,
                  totalProcessingFee: action.update_session.ProcessingFee,
                  totalAuthorizingFee: action.update_session.AuthorizationFee,
                },
                ...state.session_list.slice(index + 1),
              ],
            };
          }
        } else return state;
        break;
      case Session.STATION_ACTIVE_SESSIONS:
        if (action.activeSessions) {
          return Object.assign({}, state, {
            activeSessions: action.activeSessions,
            activeSessionLastUpdated: Date.now()
          });
        }
        break;
      default:
        return state;
    }
  }
}

export function SessionGraphReducer(state = { session_graph: [], startDate: null }, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Session.SESSION_GRAPHS:
        return {
          ...state,
          session_graph: action.session_graph,
          startDate: action.startDate
        };
      default:
        return state;
    }
  }
}

export function OpenSessionReducer(state = { open_session: [] }, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case Session.OPEN_SESSION:
        return {
          ...state,
          open_session: action.open_session,
        };
      default:
        return state;
    }
  }
}
