//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOAD_STORED_STATE = "LOAD_STORED_STATE";
export const UPDATE_STORED_SESSION_VIEW_PROPS = "UPDATE_STORED_SESSION_VIEW_PROPS";
export const TOGGLE_STORED_FLEET_MODE_PROPS = "UPDATE_STORED_FLEET_MODE_PROPS";
export const CODE_VERIFY_SUCCESS = "CODE_VERIFY_SUCCESS";
