//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router-dom";
import "../App.scss";
import AppRoutes from "./Routes/AppRoutes";
// import AnswerForceAppRoutes from "./Routes/AnswerForceAppRoutes";
import { LogoutSession } from "../../src/services/LoginLogout";
import SessionTimeout from '../components/shared/Logout/SessionTimeOut';
import LogOff24hrs from "../components/shared/Logout/LogoffSession24hrs";
import Navbar from "../redux/container/navbarprovider";
import Footer from "./shared/Footer";
import {isSupportCallOperator} from "./utilities/ValidateUserRoles";
import {CustomBreadcrumbs} from "./utilities/CustomBreadCrumbs";
import { withTranslation } from "react-i18next";
import withAppInsights from './AppInsights';
import 'bootstrap/dist/css/bootstrap.css';  //TODO: This is is potentially the source of some of our CSS conflicts since it contains changes to global classes.
import 'bootstrap/dist/css/bootstrap.min.css';
// import DashboardPage from  "../redux/container/DashboardPageProviderKasi";
// import KASIUpdateNotes from "./pages/Dashboard/KASIUpdateNotes";
// const KASIUpdateNotes = lazy(() => import ("./pages/Dashboard/KASIUpdateNotes"));
const DashboardPage = lazy(() => import ("../redux/container/DashboardPageProviderKasi"));
const AnswerForceAppRoutes = lazy(() => import ("./Routes/AnswerForceAppRoutes"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      authToken: this.props.authToken,
      kasibeta: false,
      kasiStyleDebug: false
    };
    this.handleLogOff = this.handleLogOff.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.togglekasibeta = this.togglekasibeta.bind(this);
  }

  //This gets the KASI Update Notes, but is currently unsused. Can be used later for alerts
  // async getKASIUpdateNotes() {
  //   const KasiPatchNotes = await import("./pages/Dashboard/KASIUpdateNotes");
  //   alert(KasiPatchNotes.default);
  // }

  //This is the Toggle for the KASI Beta
  togglekasibeta = () => {
    this.setState({ kasibeta: !this.state.kasibeta });
    this.state.kasibeta ? console.log("Loading MyOpConnect Style") : console.log("Loading Kasi Style")
    //Disabling the Kasi Update Notes for now, this code can be reused later for alerts.
    // this.state.kasibeta ? console.log("Returning to old dashboard") : this.getKASIUpdateNotes()
  }

  togglekasiStyleDebug = () => {
    this.setState({ kasiStyleDebug: !this.state.kasiStyleDebug });
  }

  handleLogOff = () => {
    this.setState({ show: false });
    LogoutSession(
      this.props.authToken,
      this.props.onLogOutClick
    );
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
    clearTimeout(this.timeout);
  }

  handleUnload = () => {
    try{
      // localStorage.clear();
      sessionStorage.clear();
    }
    catch(e){
      console.log (e);
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     this.onRouteChanged();
  //   }
  // }

  // onRouteChanged() {
  //   console.log("ROUTE CHANGED");
  //   window.scrollTo(0, 0);
  //   const { i18n } = this.props;
  //   if (this.props.location.pathname === "/rtl") {
  //     document.querySelector("body").classList.add("rtl");
  //     i18n.changeLanguage("ar");
  //   } else {
  //     document.querySelector("body").classList.remove("rtl");
  //     i18n.changeLanguage("en");
  //   }
  //   const fullPageLayoutRoutes = [
  //     "/login",
  //     "/logout",
  //     "/error-pages/error-404",
  //     "/error-pages/error-500",
  //   ];
  //   for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
  //     if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
  //       this.setState({
  //         isFullPageLayout: true,
  //       });
  //       document
  //         .querySelector(".page-body-wrapper")
  //         .classList.add("full-page-wrapper");
  //       break;
  //     } else {
  //       this.setState({
  //         isFullPageLayout: false,
  //       });
  //       document
  //         .querySelector(".page-body-wrapper")
  //         .classList.remove("full-page-wrapper");
  //     }
  //   }
  // }
  render() {
    //The KASI Beta toggle is passed into the Navbar of the live dashboard here
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar togglekasibeta={this.togglekasibeta}/> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    let breadCrumbComponent = !this.state.isFullPageLayout ? <CustomBreadcrumbs clearAllFilters={this.props.clearAllFilters} /> : "";

    //Conditionally renders the old dashboard's wrappers, etc. or the new KASI beta.
    return (
      <>
        {this.state.kasibeta 
        ? 
          <>
            <Suspense fallback={<></>}>
              <SessionTimeout isAuthenticated = { this.handleLogOff}/>
              <LogOff24hrs isAuthenticated = {this.handleLogOff} /> 
              <DashboardPage togglekasibeta={this.togglekasibeta} kasibeta={this.state.kasibeta} togglekasiStyleDebug={this.togglekasiStyleDebug} kasiStyleDebug={this.state.kasiStyleDebug}/>
            </Suspense>  
          </>
        :
          <div className="container-fluid">
          <SessionTimeout isAuthenticated = { this.handleLogOff}/>
          <LogOff24hrs isAuthenticated = {this.handleLogOff} /> 
          { navbarComponent }       
            <div className="main-panel"> 
              { !isSupportCallOperator(this.props.userRole)
              ? 
                <>
                  {breadCrumbComponent}
                  <div className={`content-wrapper ${this.state.kasiStyleDebug ? 'kasiStyleDebug' : ''}`}>
                    <AppRoutes kasibeta={this.state.kasibeta}/>
                  </div>
                </>
              : 
                <div className="content-wrapper">
                  <Suspense fallback={<></>}>
                    <AnswerForceAppRoutes/>
                  </Suspense>  
                </div>
              }
              { footerComponent }
            </div>
          </div>
        }
      </>
    );
  }
}

export default withAppInsights(withTranslation()(withRouter(App)));
