//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import groupBy from 'lodash/groupBy';
import moment from "moment";

import * as FleetVehiclesTypes from "../actiontypes/FleetVehicles";

// action: the action you're performing to change the state  (type, any other data you want.)
// The current state
const initialState = {
  fleetVehicles: [],
  fleetVehiclesLength: 0,
  updateDateTime: null,
};

export function FleetVehiclesReducer(state = initialState, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case FleetVehiclesTypes.LOAD_FLEET_VEHICLES:
        return Object.assign({}, state, {
          fleetVehicles: action.fleetVehicles,
          fleetVehiclesLength: action.fleetVehiclesLength,
          updateDateTime: action.updateDateTime,
        });
      case FleetVehiclesTypes.LOAD_FLEET_VEHICLE_MAKES:
        return Object.assign({}, state, {
          fleetVehicleMakes: action.fleetVehicleMakes,
          fleetVehicleMakesLength: action.fleetVehicleMakesLength,
          updateDateTime: action.updateDateTime,
        });
      case FleetVehiclesTypes.LOAD_FLEET_VEHICLE_MODELS:
        return Object.assign({}, state, {
          fleetVehicleModels: action.fleetVehicleModels,
          fleetVehicleModelsLength: action.fleetVehicleModelsLength,
          updateDateTime: action.updateDateTime,
        });
      case FleetVehiclesTypes.ADD_FLEET_VEHICLE:
        if (action.add_fleet_vehicle) {
          var value = 0;
          value = state.fleetVehiclesLength + 1;
          
          return Object.assign({}, state, {
            fleetVehicles: state.fleetVehicles.concat({
              vehicleId: action.add_fleet_vehicle.ID,
              VIN: action.add_fleet_vehicle.VIN,
              Color: action.add_fleet_vehicle.Color,
              CompanyGroupID: action.add_fleet_vehicle.CompanyGroupID,
              CompanyGroupName: action.add_fleet_vehicle.CompanyGroupName,
              CompanyID:action.add_fleet_vehicle.CompanyID,
              CompanyName:action.add_fleet_vehicle.CompanyName,
              Description:action.add_fleet_vehicle.Description,
              EVBattery:action.add_fleet_vehicle.EVBattery,
              EVRange:action.add_fleet_vehicle.EVRange,
              FleetTokenID:action.add_fleet_vehicle.FleetTokenID != null ? action.add_fleet_vehicle.FleetTokenID : -1,
              FleetTokenIDValue:action.add_fleet_vehicle.FleetTokenIDValue,
              LicenseName:action.add_fleet_vehicle.LicenseName,
              ProgramType:action.add_fleet_vehicle.ProgramType,
              RangeUnit:action.add_fleet_vehicle.RangeUnit,
              RouteRange:action.add_fleet_vehicle.RouteRange,
              vehicleMake:action.add_fleet_vehicle.vehicleMake,
              vehicleModel:action.add_fleet_vehicle.vehicleModel,
              vehicleType:action.add_fleet_vehicle.Model.VehicleType,
              year:action.add_fleet_vehicle.Year,

            }),
            updateDateTime: Date.now(),
            fleetVehiclesLength: value,
          });
        }
        else {
          return state;
        }
      default: {
        return state;
      }
    }
  }
}

//Fleet Vehicle data which will need to be refreshed on date range change or if stale
export function FleetVehiclesAnalyticsReducer(
  state = {
    analytics: [],
    fleetVehicleDetails: [],
    lastUpdatedAnalytics: null,
  }, action
)
{
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case FleetVehiclesTypes.UPDATE_FLEETVEHICLE_ANALYTICS:
        if (action.fleetVehicleAnalytics && action.fleetVehicleAnalytics.length > 0) {
          // Convert to local time zone all analytic date time values
          if (action.periodType === "day") {
            for (var i = 0; i < action.fleetVehicleAnalytics.length; ++i) {
              action.fleetVehicleAnalytics[i].date = moment
                .utc(action.fleetVehicleAnalytics[i].date)
                .local()
                .format("YYYY-MM-DD HH:mm");
            }
          } else if (action.periodType !== "day") {
            //Update date to locale date value before storing.
            for (var i = 0; i < action.fleetVehicleAnalytics.length; ++i) {
              action.fleetVehicleAnalytics[i].date = moment
                .utc(action.fleetVehicleAnalytics[i].date)
                .local()
                .format("YYYY-MM-DD");
            }
          }

          let groupedFVehicle = groupBy(action.fleetVehicleAnalytics, (x) => x.id);

          var updatedFleetVehicles = [];
          for (var value in groupedFVehicle) {
            var kWhTotal = groupedFVehicle[value].reduce((kWhTotal, sa) => {
              return (kWhTotal += Number(sa.totals.kWh));
            }, 0);

            var sessionsTotal = groupedFVehicle[value].reduce(
              (sessionsTotal, sa) => {
                return (sessionsTotal += Number(sa.totals.chargingSessions));
              },
              0
            );

            var revenueTotal = groupedFVehicle[value].reduce((revenueTotal, sa) => {
              return (revenueTotal += Number(sa.totals.revenue));
            }, 0);

            var durationTotal = groupedFVehicle[value].reduce(
              (durationTotal, sa) => {
                if (sa.totals.duration && sa.totals.duration.length > 0) {
                  return (durationTotal += Number(
                    moment.duration(sa.totals.duration).asHours()
                  ));
                } else return 0;
              },
              0
            );

            updatedFleetVehicles.push({
              ID: value,
              kWh: kWhTotal ? parseFloat(kWhTotal.toFixed(2)) : 0.0,
              sessions: sessionsTotal,
              revenue: revenueTotal ? parseFloat(revenueTotal.toFixed(2)) : 0.0,
              duration: durationTotal
                ? parseFloat(durationTotal.toFixed(2))
                : 0.0,
              analytics: groupedFVehicle[value],
            });
          }

          return Object.assign({}, state, {
            analytics: action.fleetVehicleAnalytics,
            fleetVehicleDetails: updatedFleetVehicles,
            lastUpdatedAnalytics: Date.now(),
          });
        } 
        else
          return Object.assign({}, state, {
            analytics: [],
            fleetVehicleDetails: [],
            lastUpdatedAnalytics: Date.now(),
          });
      case FleetVehiclesTypes.UPDATE_FLEETVEHICLE_DETAILS:
        if (action.data) {
          var index = state.fleetVehicleDetails.findIndex((dets) => {
            return dets.ID === action.data.fleetVehicle;
          });
          if (index >= 0) {
            return {
              ...state,
              fleetVehicleDetails: [
                ...state.fleetVehicleDetails.slice(0, index),
                {
                  ...state.fleetVehicleDetails[index],
                  inChargingStationId: action.data.details.inChargingStationIDNow,
                },
                ...state.fleetVehicleDetails.slice(index + 1),
              ],
            };
          } else {
            return Object.assign({}, state, {
              fleetVehicleDetails: state.fleetVehicleDetails.concat({
                inChargingStationId: action.data.details.inChargingStationIDNow,
                //TODO
                // chargingNow: action.data.details.totalChargingNow,
                // online: action.data.details.totalOnlineNow,
                // creditcardSessions: action.data.details.totalCreditCardSessions,
                // memberSessions: action.data.details.totalMemberSessions,
                // unknownDrivers: action.data.details.totalUnknownDrivers,
                // registeredDrivers: action.data.details.totalRegisteredDrivers,
                // sessions: action.data.details.totalSessions,
              }),
            });
          }
        } else return state;
      default: {
        return state;
      }
    }
  }
}