//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as Account from "../actiontypes/Account";

export function AccountReducer(
  state = {
    scheduledReports: [],
    accountInfo: {},
    userList: [],
    lastUpdatedReports: null,
    lastUpdatedUsers: null,
  },
  action
) {
  switch (action.type) {
    case Account.REPORT_LIST:
      if (action.reportList && action.reportList.length > 0) {
        return Object.assign({}, state, {
          scheduledReports: action.reportList,
          lastUpdatedReports: Date.now(),
        });
      } else return state;

    case Account.ADD_REPORT:
      if (action.report) {
        return Object.assign({}, state, {
          scheduledReports: state.scheduledReports.concat(action.report),
          lastUpdatedReports: Date.now(),
        });
      } else return state;

    case Account.UPDATE_REPORT:
      if (action.report) {
        var index = state.scheduledReports.findIndex((report) => {
          return report.ReportRequestID === action.report.ReportRequestID;
        });
        if (index >= 0) {
          return {
            ...state,
            scheduledReports: [
              ...state.scheduledReports.slice(0, index),
              {
                ...state.scheduledReports[index],
                ...action.report,
              },
              ...state.scheduledReports.slice(index + 1),
            ],
            lastUpdatedReports: Date.now(),
          };
        } else return state;
      } else return state;
    case Account.DELETE_REPORT:
      if (action.reportId) {
        var index = state.scheduledReports.findIndex((report) => {
          return report.ReportRequestID === action.reportId;
        });
        if (index >= 0) {
          return {
            ...state,
            scheduledReports: [
              ...state.scheduledReports.slice(0, index), 
              ...state.scheduledReports.slice(index+1)
            ],
            lastUpdatedReports: Date.now(),
          };
        } else return state;
      } else return state;
    case Account.GET_USERS_LIST:
      if (action.userList && action.userList.length > 0) {
        return Object.assign({}, state, {
          userList: action.userList,
          lastUpdatedUsers: Date.now(),
        });
      } else return state;
    case Account.GET_ACCT_INFO:
      if (action.accountInfo) {
        return Object.assign({}, state, {
          accountInfo: action.accountInfo,
        });
      } else return state;
    case Account.ADD_USER:
      if (action.userInfo) {
        return Object.assign({}, state, {
          userList: state.userList.concat(action.userInfo),
          lastUpdatedUsers: Date.now(),
        });
      } else return state;
    case Account.UPDATE_USER:
      if (action.userInfo) {
        var indexUpdateUser = state.userList.findIndex((user) => {
          return user.id === action.userInfo.id;
        });
        if (indexUpdateUser >= 0) {
          return {
            ...state,
            userList: [
              ...state.userList.slice(0, indexUpdateUser),
              {
                ...state.userList[indexUpdateUser],
                ...action.userInfo,
              },
              ...state.userList.slice(indexUpdateUser + 1),
            ],
            lastUpdatedUsers: Date.now(),
          };
        } else return state;
      } else return state;
    case Account.GET_ACTIVE_DRIVER_LIST:   // To retrieve valid users 
      if (action.activeDriverList) {
        return Object.assign({}, state, {
          activeDriverList: action.activeDriverList,
          lastUpdatedUsers: Date.now(),
        });
      } else return state;
    case Account.GET_ASSIGNED_USER_LIST:   // To retrieve users already assigned as location authorized users
      if (action.userAssignedList) {
        return Object.assign({}, state, {
          userAssignedTypeId: -1,
          userAssignedList: action.userAssignedList,
          lastUpdatedUsers: Date.now(),
        });
      } else return state;
    // case Account.GET_ASSIGNED_LOCATION_DRIVER_LIST:
    //   if (action.userAssignedDriverList) {
    //     return Object.assign({}, state, {
    //       userAssignedDriverList: action.userAssignedDriverList,
    //       lastUpdatedUsers: Date.now(),
    //     });
    //   } else return state;
    case Account.ASSIGNED_USER_LIST:  //Add user
      if(action.UserList){
        var updatedUserAssignedList = [];
        if(state.userAssignedList && state.userAssignedList.length > 0 && action.UserList.ChargingStationID === null) //When adding location wide user
        {
          for(let i in state.userAssignedList)
          {
            if( state.userAssignedList[i].StationLocationID === action.UserList.StationLocationID 
                && state.userAssignedList[i].ChargingStationID != null
                && state.userAssignedList[i].id === action.UserList.id)
            {
              continue; 
            }
            else
            {
              updatedUserAssignedList.push(state.userAssignedList[i]);
            }
          }
        }
        else {
          updatedUserAssignedList = state.userAssignedList;
        }
        return Object.assign({}, state, {
          userAssignedList: updatedUserAssignedList.concat(
            action.UserList
          ),
          lastUpdatedUsers: Date.now(),
        });
        
      } else return state;
    case Account.DELETE_ASSIGNED_USER_LIST:
      if (action.UserList) {
        // eslint-disable-next-line
        const newState = Object.assign([], state);
        var indexDeleteUser = -1;
        var indexDeleteStationUser = -1;
        if(action.UserList.ChargingStationID == null)
        {
          indexDeleteUser = state.userAssignedList.findIndex(
            (user) => {
              return user.id === action.UserList.id && user.ChargingStationID === null;
            }
          );
          state.userAssignedList.splice(indexDeleteUser, 1);
        }
        else {
          indexDeleteUser = state.userAssignedList.findIndex(
            (user) => {
              return user.id === action.UserList.id && user.ChargingStationID === action.UserList.ChargingStationID;
            }
          );
          state.userAssignedList.splice(indexDeleteUser, 1);
        }

        if (indexDeleteUser >= 0) {
          return Object.assign({}, state, {
            userAssignedList: state.userAssignedList,
            lastUpdatedUsers: Date.now(),
          });
        } else return state;
      } else return state;
      case Account.GET_LOCATION_USER_DISCOUNT_LIST:
        if (action.locationUserDiscountList) {
          return Object.assign({}, state, {
            locationUserDiscountList: action.locationUserDiscountList,
          });
        } else return state;
      case Account.DELETE_LOCATION_USER_DISCOUNT:
        if (action.userGroupId) {
          const newState = Object.assign([], state);
          var indexDeleteUserDiscount = state.locationUserDiscountList.findIndex(
            (userDiscount) => {
              return userDiscount.RateStructureUserGroupId === action.userGroupId;
            }
          );
          if (indexDeleteUserDiscount >= 0) {
            return {
              ...state,
              locationUserDiscountList: [...state.locationUserDiscountList.slice(0, indexDeleteUserDiscount),
                ...state.locationUserDiscountList.slice(indexDeleteUserDiscount + 1)],
              lastUpdatedUserDiscount: Date.now(),
            };
          } else return state;
        } else return state;
      case Account.ADD_LOCATION_USER_DISCOUNT:
        if (action.userGroup) {
          return Object.assign([], state, {
            locationUserDiscountList: state.locationUserDiscountList.concat(action.userGroup),
            lastUpdatedUserDiscount: Date.now(),
          });
        } else return state;
    default:
      return state;
  }
}
