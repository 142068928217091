//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import {LOAD_FLEET_CHARGER_STATUS_LIST  } from "../actiontypes/FleetChargerStatus";

const initialState = {
 fleetChargerStatusList:[]
};

export function FleetChargerStatusReducer (state = initialState, action) {
    switch (action.type) {
        case LOAD_FLEET_CHARGER_STATUS_LIST:
            return {...state, 
                fleetChargerStatusList:action.chargerStatusList}
        default:
            return state;

    }
}