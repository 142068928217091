//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

const baseURLs = {
    production: 'https://www.opconnect.com/API/',
    test: 'https://www.opconnecthawaii.com/API/',
    local: 'http://localhost:3201/'
  };
  
  const ENVIRONMENT = process.env.REACT_APP_ENV || 'local';
  const baseURL = baseURLs[ENVIRONMENT] || 'http://localhost:3201/';
  
  module.exports = {
    baseURL
  };
