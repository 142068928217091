//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

export const LOAD_CALLSUPPORTTICKETLIST = "LOAD_CALLSUPPORT_TICKET";
export const LOAD_CALLSUPPORT_TICKETNO = "LOAD_CALLSUPPORT_TICKETNO";
export const ADD_CALLSUPPORT_TICKET = "ADD_CALLSUPPORT_TICKET";
export const UPDATE_CALLSUPPORT_TICKET = "UPDATE_CALLSUPPORT_TICKET";
export const CALLSUPPORT_COUNT = "CALLSUPPORT_COUNT";
export const FILTER_BY_ISSUETYPE = "FILTER_BY_ISSUETYPE";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";