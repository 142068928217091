//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import { connect } from "react-redux";
import ValidateLogin from "../../components/utilities/ValidateLogin";
import { Login, LoadLogin, Verify } from "../actions/login";

const mapStateToProps = (state) => {
  return {
    siteId: state.session.siteId,
    authToken: state.session.authToken,
    userRole: state.session.userRole,
    userId: state.session.userId,
    companyType: state.session.companyType,
    companyLogoType: state.session.companyLogoType,
    requireMfa: state.session.requireMfa,
    mfaEnabled: state.session.mfaEnabled,
    mfaOptionId: state.session.mfaOptionId,
    smsPhone: state.session.smsPhone,
    countryCode: state.session.countryCode,
    loginDateTime: state.session.loginDateTime
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginClick: (
      siteId,
      authToken,
      userRole,
      userId,
      companyType,
      companyLogo,
      companyLogoType,
      requireMfa,
      mfaEnabled,
      mfaOptionId,
      smsPhone,
      countryCode,
    ) => {
      dispatch(
        Login(
          siteId,
          authToken,
          userRole,
          userId,
          companyType,
          companyLogo,
          companyLogoType,
          requireMfa,
          mfaEnabled,
          mfaOptionId,
          smsPhone,
          countryCode
        )
      );
    },
    loadLogin: (data) => {
      dispatch(LoadLogin(data));
    },
    onVerifyClick: (requireMfa) => {
      dispatch(Verify(requireMfa));
    },
  };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateLogin);

export default LoginContainer;
