//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, FormControl, Button, Card, Modal } from "react-bootstrap";
import { RequestLogInHelper } from "../../services/RequestHelper";
// import oplogo from "../../assets/images/OpConnectLogo.png";
import oplogo from "../../assets/webp/OpConnectLogo.webp";
import { Trans } from "react-i18next";
import "./LoginPage.scss";

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.loginClick = this.loginClick.bind(this);
    this.state = {
      fieldState: null,
      fieldStateHelp: "",
      fieldStateHelpPassword: "",
      emailValue: "",
      passwordValue: "",
      showModal: false,
      modalMessage: "",
    };
  }

  componentDidMount() {
    if (!this.props.authToken) { 
      this.props.history.push('/');      
    }
}

  handleEmailChange = (e) => {
    this.setState({
      emailValue: e.target.value,
      fieldState: null,
      fieldStateHelp: "",
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      passwordValue: e.target.value,
      fieldState: null,
      fieldStateHelpPassword: "",
    });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
    //this.props.history.push('/forgotpassword');
  };

  handleModalShow = (message) => {
    this.setState({ showModal: true, modalMessage: message });
  };

  loginClick = function () {
    //do login request here, push state change back to App.
    
    if (
      this.state.emailValue.trim().length === 0 ||
      this.state.passwordValue.trim().length === 0
    ) {
      let emailHelp = "";
      let passwordHelp = "";

      if (this.state.emailValue.trim().length === 0) emailHelp = " Required";

      if (this.state.passwordValue.trim().length === 0)
        passwordHelp = " Required";

      this.setState({
        fieldState: "error",
        fieldStateHelp: emailHelp,
        fieldStateHelpPassword: passwordHelp,
      });
    } else {
      var requestUrl = "Session.svc/Session/portal";
      RequestLogInHelper(
        requestUrl,
        {
          email: this.state.emailValue,
          password: this.state.passwordValue,
          persist: false,
        },
        (r) => {
          this.props.login(
            r.data.companyId,
            r.data.authToken,
            r.data.role,
            r.data.ID,
            r.data.companyType,
            r.data.companyLogo,
            r.data.companyLogoType,
            r.data.requireMfa, // require mfa (show mfa page or setting page)
            r.data.mfaEnabled, // false: show qr code
            r.data.mfaOptionId, // mfa method
            r.data.smsPhone, // user's sms phone number
            r.data.countryCode,
          );
        },
        (err) => {
          if (err.response && err.response.status === 401) {
            this.setState({
              fieldState: "error",
              fieldStateHelp:
                " Failed to validate either email or password...try again or contact support.",
            });
          } else if (err.response && err.response.status === 404) {
            this.setState({
              fieldState: "error",
              fieldStateHelp:
                "One more attempt before locking account...try again or press 'Forgot your password?' below.",
            });
          } else if (err.response && err.response.status === 403) {
            this.handleModalShow("You are locked out. To get access you must reset your password.  Press 'Forgot your password?' below");
          }
          
        }
      );
      this.props.history.push("/");
    }
  };

  render() {
    return (
      <div className="overallLoginPage">
            <Card className="col-lg-3 loginInCard " style={{boxShadow: "0 4px 8px 0 rgba(11, 138, 22, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} >
              <div className="auth-form-dark text-left py-4 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src={oplogo} id="logo" alt="oplogo" className="logoRowItem" />
                </div>
                <h4>
                  <Trans>Hello! let's get started</Trans>
                </h4>
                <h6 className="font-weight-light">
                  <Trans>Please sign in with your email.</Trans>
                </h6>
                <Form>
                  <FormGroup controlId="formInlineEmail" validationstate={this.state.fieldState}>
                    <FormControl type="text" placeholder="johndoe@email.com" onChange={this.handleEmailChange} onKeyPress={event => {
                      if (event.key === "Enter") {
                        this.loginClick()
                      }
                    }}/>
                    {this.state.fieldState && <span className="text-danger">{this.state.fieldStateHelp}</span>}
                  </FormGroup>
                  <FormGroup controlId="formInlinePassword" validationstate={this.state.fieldState}>
                    <FormControl type="password" placeholder="password" onChange={this.handlePasswordChange} onKeyPress={event => {
                      if (event.key === "Enter") {
                        this.loginClick()
                      }
                    }}/>
                    {this.state.fieldState && <span className="text-danger">{this.state.fieldStateHelpPassword}</span>}
                  </FormGroup>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Button type="button" onClick={this.loginClick}>
                      <Trans>Login</Trans>
                    </Button>
                    <Link to="/forgotpassword" className="auth-link text-muted">
                      <p>
                      <Trans>Forgot your password?</Trans>
                      </p>
                    </Link>
                  </div>
                </Form>
              </div>
            </Card>
            <Modal show={this.state.showModal} 
                    onHide={this.handleModalClose} 
                    backdrop="static"
                    className="my-2 d-flex justify-content-between align-items-center"
            >
              <Modal.Header >
                <Modal.Title>Too many unsuccessful login attempts</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalMessage}</Modal.Body>
              {this.state.modalMessage.includes("locked out") && (
                <Modal.Footer>
                  <Link to="/forgotpassword">Forgot your password?</Link>
                </Modal.Footer>
              )}
            </Modal>
      </div>
    );
  }
}

export default LoginPage;
