//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import moment from 'moment';

import * as DateRangeTypes from '../actiontypes/DateRange';


const initialState = {
  periodType: 'day',  
  start:  moment().subtract(6,'d').hours(0).minutes(0).seconds(0).milliseconds(0).format(),  // Used by components which make API svc calls
  end:  moment().hours(23).minutes(59).seconds(59).milliseconds(59).format(), // " ISO 8601 with utc offset (Would we want to default to current date and time)
  startDate:  moment().subtract(6,'d').toJSON(), // Used by date picker component, moment date object with no formatting.
  endDate: moment().toJSON(), 
  updateTime: Date.now(), 
  // ranges: {
	// 			'Previous Week': [now.subtract(6, 'days').toJSON(), nowSerialized],
	// 			'Previous Month': [now.subtract(1, 'month').startOf('month').toJSON(), now.subtract(1, 'month').endOf('month').toJSON()],
  //       'Previous Year': [now.subtract(1, 'year').startOf('year').toJSON(), now.subtract(1, 'year').endOf('year').toJSON()],
	// 			'Month-to-Date': [now.startOf('month').toJSON(), nowSerialized],
	// 			'Year-to-Date': [now.startOf('year').toJSON(), nowSerialized],
	// },
}

export function DateRangeReducer(state=initialState, action){


  if(action.type !== undefined) {
    switch(action.type){
      case DateRangeTypes.UPDATE_DATE_RANGE:
        let updatedStart = moment(action.startDate);
        let updatedEnd =  moment(action.endDate);
        let period = "";

        if (updatedEnd.diff(updatedStart, 'year',true) > 1)  // Hourly precision graph data points, multiple years with bi monthly axis points
        {
          period = 'month';
        }
        else if (updatedEnd.diff(updatedStart, 'month',true) > 1)  // Hourly precision graph data points, 1 year long axis with bi-monthly axis points
        {
          period = 'month';
        }
        else if (updatedEnd.diff(updatedStart, 'week',true) > 1)  // Hourly precision graph data points, month long axis with weekly Monday axis points
        {
          period = 'week';
        }
        else  // Hourly precision graph data points, week long axis with 7-day axis point
        {
          period = 'day';
        }

        return  Object.assign({}, state, {
          periodType: period,
          startDate: updatedStart.toJSON(),
          endDate: updatedEnd.toJSON(),
          start: updatedStart.hours(0).minutes(0).seconds(0).milliseconds(0).format(),
          end: updatedEnd.hours(23).minutes(59).seconds(59).milliseconds(59).format(),
          updateTime: action.updateTime
        });
      // case DateRangeTypes.REFRESH_ALL_DATES:
      //   var refreshNow = moment();
      //   var refreshNowSerialized = now.toJSON();
      //   return Object.assign({}, state,  {ranges: {
      //     'Previous Week': [refreshNow.subtract(6, 'days').toJSON(), refreshNowSerialized],
      //     'Previous Month': [refreshNow.subtract(1, 'month').startOf('month').toJSON(), moment().subtract(1, 'month').endOf('month').toJSON()],
      //     'Previous Year': [refreshNow.subtract(1, 'year').startOf('year').toJSON(), moment().subtract(1, 'year').endOf('year').toJSON()],
      //     'Month-to-Date': [refreshNow.startOf('month').toJSON(), refreshNowSerialized],
      //     'Year-to-Date': [refreshNow.startOf('year').toJSON(), refreshNowSerialized],
      //   }});
      default:
          return state;
    }
  }
}