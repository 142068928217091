//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

export const UPDATE_FLEET_DASHBOARD_OBJ_TOTALS = 'UPDATE_FLEET_DASHBOARD_OBJ_TOTALS';
export const UPDATE_FLEET_DASHBOARD_EVENT_TOTALS = 'UPDATE_FLEET_DASHBOARD_EVENT_TOTALS';

export const UPDATE_REG_DASHBOARD_OBJ_TOTALS = 'UPDATE_REG_DASHBOARD_OBJ_TOTALS';
export const UPDATE_REG_DASHBOARD_EVENT_TOTALS = 'UPDATE_REG_DASHBOARD_EVENT_TOTALS';