//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import axios from "axios";

export const RequestLogInHelper = (
  postReqUrl,
  data,
  fnLoginResponseHandler,
  fnLoginErrorHandler
) => {
  axios({
    method: "post",
    url: postReqUrl,
    data: data,
  })
    .then((response) => {
      fnLoginResponseHandler(response)})
    .catch((err) => fnLoginErrorHandler(err));
};

export const RequestPostHelper = (
  postReqUrl,
  data,
  authToken,
  fnResponseHandler
) => {
  axios({
    method: "post",
    url: postReqUrl,
    data: data,
    headers: {
      Authorization: authToken,
    },
  }).then((response) => fnResponseHandler(response));
};

export const RequestGetHelper = (
  getReqUrl,
  authToken,
  fnResponseHandler,
  fnGetErrorHandler
) => {
  axios({
    method: "get",
    url: getReqUrl,
    headers: {
      Authorization: authToken,
    },
  })
    .then((response) => fnResponseHandler(response))
    .catch((err) => fnGetErrorHandler(err));
};

export const RequestCodeVerifyHelper = (
  postReqUrl,
  data,
  authToken,
  fnCodeVerifyResponseHandler,
  fnCodeVerifyErrorHandler
) => {
  axios({
    method: "post",
    url: postReqUrl,
    data: data,
    headers: {
      Authorization: authToken,
    },
  })
    .then((response) => {
      fnCodeVerifyResponseHandler(response)})
    .catch((err) => fnCodeVerifyErrorHandler(err));
};
