//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as GraphTypes from "../actiontypes/EnergyGraphs";

export function EnergyGraphReducer(state = { energy_graph: [], startDate: null, cO2SavedData : {} }, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case GraphTypes.ENERGY_GRAPHS:
        return Object.assign({}, state, {
          energy_graph: action.energy_graph,
          startDate: action.startDate
        });
      case GraphTypes.CO2SAVED_GRAPH:
        if (action.cO2SavedData && action.cO2SavedData.data) {
          return Object.assign({}, state, {
            cO2SavedData: action.cO2SavedData.data
          });
        }
        else
          return state;
      default:
        return state;
    }
  }
}
