//-------------------------------------------------------------------------// 
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------// 

import * as Login from '../actiontypes/login';
import * as Logout from '../actiontypes/logout';
import * as Mfa from '../actiontypes/mfa';
import { isFleetCompany } from '../../components/utilities/CompanyTypeCheck';


const initialState = {
   siteId: null,  
   userId:null,
   authToken: null,
   userRole: null,
   companyType: "",
   companyLogo: null,
   companyLogoType: "",
   showDriverValue: false,
   showTokenValue: false,
   fleetMode: false,
   requireMfa: false,
   qrCodeUrl: null,
   smsPhone: null,
   countryCode: null,
   loginDateTime: null
}

export function WebSessionReducer(state=initialState, action){
  // eslint-disable-next-line
  if(action.type != undefined) {
    switch(action.type){
      case Login.LOGIN_SUCCESS:
        return { ...state,
          siteId: action.siteId,
          userId: action.userId,
          authToken: action.authToken,
          userRole: action.userRole,
          companyType: action.companyType != null ? action.companyType : "",
          companyLogo: action.companyLogo,
          companyLogoType: action.companyLogoType,
          fleetMode: action.companyType != null ? isFleetCompany(action.companyType) : false,
          requireMfa: action.requireMfa,
          mfaEnabled: action.mfaEnabled,
          mfaOptionId: action.mfaOptionId,
          smsPhone: action.smsPhone,
          countryCode: action.countryCode,
          loginDateTime: Date.now()
        }
      case Login.LOAD_STORED_STATE:
        return {
          siteId: action.storedState.siteId,
          userId: action.storedState.userId,
          authToken: action.storedState.authToken,
          userRole: action.storedState.userRole,
          companyType: action.storedState.companyType,
          companyLogo: action.storedState.companyLogo,
          companyLogoType: action.storedState.companyLogoType,
          showTokenValue: action.storedState.showTokenValue,
          showDriverValue: action.storedState.showDriverValue,
          fleetMode: action.storedState.fleetMode ? action.storedState.fleetMode : 
          (action.storedState.companyType != null ? isFleetCompany(action.companyType) : false),
          requireMfa: action.storedState.requireMfa,
          qrCodeUrl: action.storedState.qrCodeUrl,
          mfaEnabled: action.storedState.mfaEnabled,
          mfaOptionId: action.storedState.mfaOptionId,
          smsPhone: action.storedState.smsPhone,
          countryCode: action.storedState.countryCode,
          loginDateTime: action.storedState.loginDateTime
        }
      case Login.UPDATE_STORED_SESSION_VIEW_PROPS:
        return {
          ...state,
          showTokenValue: action.showDriverValue,  
          showDriverValue: action.showTokenValue
        }
      case Login.TOGGLE_STORED_FLEET_MODE_PROPS:
          return {
            ...state,
            fleetMode: action.fleetModeProp
          }
      case Logout.LOGOUT:
        return {
          ...state,
          siteId: null ,
          authToken: null,
          userRole: "",
          userId: null,
          companyType: "",
          companyLogo: null,
          companyLogoType: "",
          qrCodeUrl: null,
          requireMfa: false,
          mfaEnabled: false,
          mfaOptionId: null,
          smsPhone: null,
          countryCode: null,
          loginDateTime: null,
        }
      case Mfa.LOAD_QR_CODE_URL:
        return Object.assign({}, state, {
          qrCodeUrl: action.qrCodeUrl,
        });
      case Login.CODE_VERIFY_SUCCESS:
        return Object.assign({}, state, {
          requireMfa: false,
          qrCodeUrl: null
        });
      case Mfa.LOAD_IS_OTP_SMS_SENT:
        return Object.assign({}, state, {
          isOtpSmsSent: action.isOtpSmsSent,
        });
      default:
        return state;
    }
  }
}
