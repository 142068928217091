//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

const isFleetCompany = (companyType) => {
    if (!companyType)
        return false;

    if (companyType.trim() === "Fleet Operator") 
    {
        return true;
    } 
    else 
    {
        return false;
    }
};


const isNetworkOperatorCompany = (companyType) => {

    if (!companyType)
    return false;

    if (companyType.trim() === "Network Operator") 
    {
        return true;
    } 
    else 
    {
        return false;
    }
};

module.exports = {
    isFleetCompany,
    isNetworkOperatorCompany
}