//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//
import * as SortFilterAction from "../actiontypes/SortFilter";

export const sortByAlphabet = (payload) => ({
  type: SortFilterAction.SORT_BY_ALPHABET,
  payload,
});
export const sortByEnergy = (payload) => ({
  type: SortFilterAction.SORT_BY_ENERGY,
  payload,
});
export const sortByRevenue = (payload) => ({
  type: SortFilterAction.SORT_BY_REVENUE,
  payload,
});
export const sortBySessions = (payload) => ({
  type: SortFilterAction.SORT_BY_SESSIONS,
  payload,
});
export const sortByDuration = (payload) => ({
  type: SortFilterAction.SORT_BY_DURATION,
  payload,
});
export const filterByValue = (payload) => ({
  type: SortFilterAction.FILTER_BY_VALUE,
  payload,
});
// Clears both analytics and list filters
export const clearAllFilters = () => {
  return {
    type: SortFilterAction.CLEAR_ALL_FILTERS,
  };
};

export const toggleAnalyticsItem = (id, filterTypeId) => {
  return {
    type: SortFilterAction.TOGGLE_ANALYTICS_ITEM,
    id,
    filterTypeId,
  };
};

export const loadListFilterItems = (ids) => {
  return {
    type: SortFilterAction.LOAD_FILTER_LIST_ITEMS,
    ids
  }
} 

export const loadListFilterItemsAndType = (ids, filterTypeId, selectIndex) => {
  return {
    type: SortFilterAction.LOAD_FILTER_LIST_ITEMS_WITH_TYPE,
    ids,
    filterTypeId,
    selectIndex
  }
}