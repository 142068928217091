//-------------------------------------------------------------------------//
//                OpConnect, Inc. Confidential & Proprietary               //
//                                                                         //
// This work contains valuable confidential and proprietary information.   //
// Disclosure, use or reproduction outside of OpConnect, Inc.              //
// is prohibited except as authorized in writing.  This unpublished        //
// work is protected by the laws of the United States and other countries. //
// In the event of publication, the following notice shall apply:          //
//                                                                         //
//                  Copyright 2021 OpConnect, Inc.                         //
//                          All Rights Reserved.                           //
//-------------------------------------------------------------------------//

import * as UsageReport from "../actiontypes/UsageReport";

const initialState = {
  usage_data: [],
  usage_data_list: [],
};

export function UsageReportReducer(state = initialState, action) {
  // eslint-disable-next-line
  if (action.type != undefined) {
    switch (action.type) {
      case UsageReport.USAGE_DATA:
        return Object.assign({}, state, {
          usage_data: action.usage_data,
        });
      case UsageReport.USAGE_DATA_LIST:
        return Object.assign({}, state, {
          usage_data_list: action.usage_data_list,
        });
      default:
        return state;
    }
  }
}
